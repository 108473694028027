const state = {
    crops: [],
    lastCropUpdate: null,
    irrigation: {},
    lastIrrigationUpdate: null
}

// getters
const getters = {
    crops: (state, _, rootState) => {
        const crops = state.crops.map((crop) => ({
            name: crop.name,
            label: crop.locales[rootState.user.language] || crop.locales.es,
            varieties: crop.varieties || [],
            kc: crop.kc
        }))
        crops.sort((a, b) => a.label < b.label ? -1 : 1)
        return crops
    },
    lastCropUpdate: (state) => state.lastCropUpdate,
    irrigation: (state) => state.irrigation,
    lastIrrigationUpdate: (state) => state.lastIrrigationUpdate
}

// actions
const actions = {
    async loadCrops({ commit }, { vm }) {
        const response = await vm.$api.constants().getCrops()
        commit('setCrops', response.body.data)
        commit('setLastCropUpdate', Date.now())
        return response.body.data
    },
    async loadIrrigation({ commit }, { vm }) {
        const response = await vm.$api.constants().getIrrigation()
        commit('setIrrigation', response.body.data)
        commit('setLastIrrigationUpdate', Date.now())
        return response.body.data
    }
}

// mutations
const mutations = {
    setCrops(state, crops) {
        state.crops = crops
    },
    setIrrigation(state, irrigation) {
        state.irrigation = irrigation
    },
    setLastCropUpdate(state, date) {
        state.lastCropUpdate = date
    },
    setLastIrrigationUpdate(state, date) {
        state.lastIrrigationUpdate = date
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
