import { ulid } from 'ulid'

const state = {
    useSimulator: false,
    analyses: [],
    selected: {},
    selectedSample: {},
    newAnalysis: false,
    editableAnalysis: false,
    takingSamples: false,
    noteQueue: { readyToSave: false, notes: [] },
    analysisDialog: false,
    conditioning: null,
    conditioningTimer: null,
    calibration: null,
    simulateConditioning: null,
    simulateConditioningTimer: null,
    simulateCalibration: null
}

// getters
const getters = {
    useSimulator: (state) => state.useSimulator,
    analyses: (state) => state.analyses,
    selected: (state) => state.selected,
    selectedSample: (state) => state.selectedSample,
    newAnalysis: (state) => state.newAnalysis,
    editableAnalysis: (state) => state.editableAnalysis,
    takingSamples: (state) => state.takingSamples,
    noteQueue: (state) => state.noteQueue,
    analysisDialog: (state) => state.analysisDialog,
    conditioning: (state) => {
        if (state.useSimulator) return state.simulateConditioning
        return state.conditioning
    },
    conditioningTimer: (state) => {
        if (state.useSimulator) return state.simulateConditioningTimer
        return state.conditioningTimer
    },
    calibration: (state) => {
        if (state.useSimulator) return state.simulateCalibration
        return state.calibration
    }
}

// actions
const actions = {
    async loadAnalyses({ commit, getters, rootGetters }, { vm }) {
        if (!getters.analyses.length) {
            const responseAnalyses = await vm.$api
                .laboratory()
                .getFromProject(rootGetters['projects/selectedProject']._id)
            commit('setAnalyses', responseAnalyses.body.data)
            return responseAnalyses.body.data
        }
        return getters.analyses
    },
    async loadById({ commit, rootGetters }, { vm, analysisId }) {
        const response = await vm.$api.laboratory().getById(analysisId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', response.body.data)
        return response.body.data
    },
    async create({ commit, rootGetters }, { vm, analysisToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const createResponse = await vm.$api.laboratory().create(analysisToSave)
            commit('addAnalysis', createResponse.body.data)
            return createResponse.body.data
        }
        const analysisCreated = { ...analysisToSave }
        analysisCreated._id = ulid()
        analysisCreated.waypoints = analysisCreated.waypoints.map((obj) => {
            obj._id = ulid()
            return obj
        })
        commit('addAnalysis', analysisCreated)
        return analysisCreated
    },
    async update({ commit, rootGetters }, { vm, analysisToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const updateResponse = await vm.$api
                .laboratory()
                .update(analysisToSave._id, rootGetters['projects/selectedProject']._id, analysisToSave)
            commit('updateAnalysis', updateResponse.body.data)
            return updateResponse.body.data
        }
        const analysisUpdated = { ...analysisToSave }
        analysisUpdated.waypoints = analysisUpdated.waypoints.map((obj) => {
            obj._id = obj._id ? obj._id : ulid()
            return obj
        })
        commit('updateAnalysis', analysisUpdated)
        return analysisUpdated
    },
    async updateSample({ commit, getters, rootGetters }, { vm, analysisId, sampleId }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const updateResponse = await vm.$api
                .laboratory()
                .updateSample(analysisId, rootGetters['projects/selectedProject']._id, sampleId)
            commit('updateAnalysis', updateResponse.body.data)
            commit('setSelected', updateResponse.body.data)
            return updateResponse.body.data
        }
        const analysisUpdated = { ...getters.selected }
        const index = analysisUpdated.waypoints.findIndex((item) => item._id == sampleId)
        analysisUpdated.waypoints[index].sample = true
        const count = analysisUpdated.waypoints.reduce((accum, item) => (item.sample ? accum + 1 : accum), 0)
        const indexSplice = count > 0 ? count - 1 : 0
        analysisUpdated.waypoints.splice(indexSplice, 0, analysisUpdated.waypoints[index])
        analysisUpdated.waypoints.splice(index + 1, 1)
        analysisUpdated.waypoints.forEach((waypoint, i) => (waypoint.index = i))
        if (count == analysisUpdated.waypoints.length) {
            analysisUpdated.status = 'analysisPending'
        } else if (analysisUpdated.status === 'planned') {
            analysisUpdated.status = 'samples'
        }
        commit('updateAnalysis', analysisUpdated)
        commit('setSelected', analysisUpdated)
        return analysisUpdated
    },
    async saveMeasure({ commit, getters, rootGetters }, { vm, analysisId, sample }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const response = await vm.$api
                .laboratory()
                .updateMeasure(analysisId, rootGetters['projects/selectedProject']._id, sample._id, sample.measurement)
            commit('updateAnalysis', response.body.data)
            commit('setSelected', response.body.data)
        } else {
            const analysisUpdated = { ...getters.selected }
            const index = analysisUpdated.waypoints.findIndex((item) => item._id == sample._id)
            analysisUpdated.waypoints[index].measurement = sample.measurement
            if (!analysisUpdated.waypoints.find((waypoint) => !waypoint.measurement)) {
                analysisUpdated.status = 'executed'
            } else if (analysisUpdated.status !== 'analyzing') {
                analysisUpdated.status = 'analyzing'
            }
            commit('updateAnalysis', analysisUpdated)
            commit('setSelected', analysisUpdated)
        }
    },
    async delete({ commit, rootGetters }, { vm, analysis }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const deleteResponse = await vm.$api
                .laboratory()
                .delete(analysis._id, rootGetters['projects/selectedProject']._id)
            commit('deleteAnalysis', analysis)
            return deleteResponse.body.message
        }
        commit('deleteAnalysis', analysis)
    }
}

// mutations
const mutations = {
    setUseSimulator(state, value) {
        state.useSimulator = value
    },
    addAnalysis(state, analysis) {
        state.analyses.unshift(analysis)
    },
    updateAnalysis(state, analysisToSave) {
        state.analyses = state.analyses.map((item) => (item._id == analysisToSave._id ? analysisToSave : item))
    },
    deleteAnalysis(state, analysis) {
        const analysisIndex = state.analyses.findIndex((item) => item._id == analysis._id)
        state.analyses.splice(analysisIndex, 1)
    },
    setAnalyses(state, analyses) {
        state.analyses = analyses
    },
    setSelected(state, analysis) {
        state.selected = analysis
    },
    setSelectedSample(state, sample) {
        state.selectedSample = sample
    },
    setNewAnalysis(state, value) {
        state.newAnalysis = value
    },
    setEditableAnalysis(state, value) {
        state.editableAnalysis = value
    },
    setTakingSamples(state, value) {
        state.takingSamples = value
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setAnalysisDialog(state, value) {
        state.analysisDialog = value
    },
    setConditioning(state, value) {
        if (state.useSimulator) state.simulateConditioning = value
        else state.conditioning = value
    },
    setConditioningTimer(state, value) {
        if (state.useSimulator) state.simulateConditioningTimer = value
        else state.conditioningTimer = value
    },
    setCalibration(state, value) {
        if (state.useSimulator) state.simulateCalibration = value
        else state.calibration = value
    },
    clearAnalysis(state) {
        state.selected = {}
        state.selectedSample = {}
        state.newAnalysis = false
        state.editableAnalysis = false
        state.takingSamples = false
        state.noteQueue = { readyToSave: false, notes: [] }
        state.analysisDialog = false
    },
    clearData(state) {
        state.useSimulator = false
        state.analyses = []
        state.selected = {}
        state.selectedSample = {}
        state.newAnalysis = false
        state.editableAnalysis = false
        state.takingSamples = false
        state.noteQueue = { readyToSave: false, notes: [] }
        state.analysisDialog = false
        state.simulateConditioning = null
        state.simulateConditioningTimer = null
        state.simulateCalibration = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
