const state = {
    data: {},
    pack: {},
    subscription: {},
    token: '',
    location: {
        lng: '',
        lat: ''
    },
    deviceInfo: {},
    config: {
        seasonFilter: true,
        subProjectFilter: false
    },
    nextCheck: Date.now(),
    integrations: []
}

// getters
const getters = {
    data: (state) => state.data,
    pack: (state) => state.pack,
    subscription: (state) => state.subscription,
    token: (state) => state.token,
    project: (state) => state.project,
    location: (state) => state.location,
    deviceInfo: (state) => state.deviceInfo,
    config: (state) => state.config,
    nextCheck: (state) => state.nextCheck,
    integrations: (state) => state.integrations
}

// actions
const actions = {
    async login({ commit }, { vm, data }) {
        const loginResponse = await vm.$api.user().login(data)
        const user = loginResponse.body.data
        if (user.toursComplete) {
            commit('tour/setCompleteAllTours', {}, { root: true })
        }
        vm.$i18n.locale = user.language || navigator.language.split('-')[0]
        vm.$dayjs.locale(vm.$i18n.locale)
        vm.$vuetify.lang.current = vm.$i18n.locale
        user.language = vm.$i18n.locale
        commit('setData', user)
        amplitude.getInstance().logEvent('LOGIN')
        return loginResponse.body.message
    },
    async loginGoogle({ commit }, { vm, data }) {
        const loginResponse = await vm.$api.user().loginGoogle(data)
        const user = loginResponse.body.data
        if (user.toursComplete) {
            commit('tour/setCompleteAllTours', {}, { root: true })
        }
        vm.$i18n.locale = user.language || navigator.language.split('-')[0]
        vm.$dayjs.locale(vm.$i18n.locale)
        vm.$vuetify.lang.current = vm.$i18n.locale
        user.language = vm.$i18n.locale
        commit('setData', user)
        if(loginResponse.status === 201) {
            amplitude.getInstance().logEvent('REGISTER_CONFIRMED')
        }
        amplitude.getInstance().logEvent('LOGIN')
        return loginResponse.body.message
    },
    async loginApple({ commit }, { vm, data }) {
        const loginResponse = await vm.$api.user().loginApple(data)
        const user = loginResponse.body.data
        if (user.toursComplete) {
            commit('tour/setCompleteAllTours', {}, { root: true })
        }
        vm.$i18n.locale = user.language || navigator.language.split('-')[0]
        vm.$dayjs.locale(vm.$i18n.locale)
        vm.$vuetify.lang.current = vm.$i18n.locale
        user.language = vm.$i18n.locale
        commit('setData', user)
        if(loginResponse.status === 201) {
            amplitude.getInstance().logEvent('REGISTER_CONFIRMED')
        }
        amplitude.getInstance().logEvent('LOGIN')
        return loginResponse.body.message
    },
    async loginDevice({ commit }, { vm, data }) {
        const loginResponse = await vm.$api.user().loginDevice(data)
        const user = loginResponse.body.data
        if (user.toursComplete) {
            commit('tour/setCompleteAllTours', {}, { root: true })
        }
        vm.$i18n.locale = user.language || navigator.language.split('-')[0]
        vm.$dayjs.locale(vm.$i18n.locale)
        vm.$vuetify.lang.current = vm.$i18n.locale
        user.language = vm.$i18n.locale
        commit('setData', user)
        amplitude.getInstance().logEvent('LOGIN')
        return loginResponse.body.message
    },
    async register({ commit }, { vm, data }) {
        const registerResponse = await vm.$api.register().createUser({ jwt: data })
        const user = registerResponse.body.data
        commit('setData', user)
        amplitude.getInstance().logEvent('REGISTER_CONFIRMED')
        return registerResponse.body.message
    },
    async loadData({ commit, getters }, { vm }) {
        if (Date.now() > getters.nextCheck) {
            const responseUser = await vm.$api.user().get()
            const user = responseUser.body.data
            if (user.toursComplete) {
                commit('tour/setCompleteAllTours', {}, { root: true })
            }
            vm.$i18n.locale = user.language || navigator.language.split('-')[0]
            vm.$dayjs.locale(vm.$i18n.locale)
            vm.$vuetify.lang.current = vm.$i18n.locale
            user.language = vm.$i18n.locale
            commit('setData', user)
            return responseUser.body.message
        }
    },
    async updateTour({ commit, rootGetters }, { vm, userToUpdate }) {
        const updateResponse = await vm.$api.user().updateTour(rootGetters['user/data']._id, userToUpdate)
        commit('setData', updateResponse.body.data)
        return updateResponse.body.message
    },
    async loadSubscription({ commit }, { vm }) {
        const responseSubscription = await vm.$api.subscription().getActive()
        commit('setSubscription', responseSubscription.body.data)
        return responseSubscription.body.data
    },
    async sendMessage(_, { vm, formData }) {
        const sendResponse = await vm.$api.user().send(formData)
        return sendResponse.body.message
    },
    async loadIntegrations({ commit, rootGetters }, { vm }) {
        const responseIntegrations = await vm.$api.externalIntegration().getAll(rootGetters['user/data']._id)
        commit('setIntegrations', responseIntegrations.body.data)
        return responseIntegrations.body.data
    }
}

// mutations
const mutations = {
    setData(state, data) {
        state.data = data
        if (data && data.email) {
            amplitude.getInstance().setUserId(data.email)
            if (amplitude.getInstance().options) {
                amplitude.getInstance().options.platform = window.Capacitor.isNative ? 'Mobile' : 'Web'
            }
            amplitude.getInstance().regenerateDeviceId()
        }
        if (data && data.pack) {
            state.pack = data.pack
        }
        state.nextCheck = Date.now() + 60000
    },
    setPack(state, pack) {
        state.pack = pack
    },
    setSubscription(state, subscription) {
        state.subscription = subscription
    },
    setToken(state, token) {
        state.token = token
    },
    setLocation(state, location) {
        state.location = location
    },
    setDeviceInfo(state, deviceInfo) {
        state.deviceInfo = deviceInfo
    },
    setConfig(state, config) {
        state.config = config
    },
    setIntegrations(state, integrations) {
        state.integrations = integrations
    },
    clearData(state) {
        state.data = {}
        state.pack = {}
        state.subscription = {}
        state.token = ''
        state.location = {
            lng: '',
            lat: ''
        }
        state.config = {
            seasonFilter: true,
            subProjectFilter: false
        },
        state.integrations = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
