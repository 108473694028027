import localforage from 'localforage'
import { ulid } from 'ulid'

const state = {
    active: false,
    syncing: false,
    projectToSync: []
}

// getters
const getters = {
    active: (state) => state.active,
    syncing: (state) => state.syncing,
    projectToSync: (state) => state.projectToSync
}

// actions
const actions = {
    async downloadStage({ commit, rootGetters }, { vm, stage, progress }) {
        const stageResponse = await vm.$api.offline()
            .getStage(stage._id, rootGetters['projects/selectedProject']._id)
        const downloadedStage = stageResponse.body.data

        let bounds = null
        let tileUrls = []
        const zoomLevels = [12, 13, 14, 15, 16, 17, 18]
        const layerUrl = vm.$tiles.url().fromStage(downloadedStage)
        const layer = L.tileLayer.offline(layerUrl, null, downloadedStage.mosaic.layer.options)
        const boundsArray = downloadedStage.project.bbox.split(',')
        const latlngBounds = L.latLngBounds([boundsArray[1], boundsArray[0]], [boundsArray[3], boundsArray[2]])

        for (let i = 0; i < zoomLevels.length; i++) {
            bounds = L.bounds(
                vm.$root.map.project(latlngBounds.getNorthWest(), zoomLevels[i]),
                vm.$root.map.project(latlngBounds.getSouthEast(), zoomLevels[i])
            )
            tileUrls = tileUrls.concat(layer.getTileUrls(bounds, zoomLevels[i]))
        }

        const tilesDb = localforage.createInstance({ name: downloadedStage._id })
        const downloadedTiles = await tilesDb.keys()

        let downloaded = downloadedTiles.length
        if (typeof progress === 'function') progress(Math.round((downloaded / tileUrls.length) * 100))

        const tiles = tileUrls.filter((url) => !downloadedTiles.some((tile) => tile == url.key))

        for (let i = 0; i < tiles.length; i += 30) {
            // eslint-disable-next-line no-loop-func
            const promises = tiles.slice(i, i + 30).map((tile) =>
                vm.$http.get(tile.url, { responseType: 'arraybuffer' })
                    .then((tileResponse) => {
                        downloaded += 1
                        if (typeof progress === 'function') {
                            progress(Math.round((downloaded / tileUrls.length) * 100))
                        }
                        return tilesDb.setItem(tile.key, tileResponse.body)
                    })
                    .catch((err) => {
                        if (err.status !== 404) {
                            throw err
                        }
                    })
            )
            // eslint-disable-next-line no-await-in-loop
            await Promise.all(promises)
        }

        downloadedStage.offline = true
        commit('stages/updateStage', downloadedStage, { root: true })
        return downloadedStage
    },
    async deleteStage({ commit, rootGetters }, { stage }) {
        localforage.dropInstance({ name: stage._id })
        const deletedStage = rootGetters['stages/stages'].find((item) => item._id == stage._id)
        delete deletedStage.offline
        commit('stages/updateStage', deletedStage, { root: true })
        return deletedStage
    },
    async syncProject({ commit, rootGetters }, { vm, project }) {
        async function sendFile(data, comment, section) {
            try {
                const fileRead = await vm.$fileSystem.readFile(comment.filePath)
                const byteCharacters = window.atob(fileRead.data)
                // Creo el array de bytes
                const byteNumbers = new Array(byteCharacters.length)
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i)
                }
                // Se crea el Int8Array
                const byteArray = new Uint8Array(byteNumbers)
                const formatFile = comment.filePath.substring(comment.filePath.lastIndexOf('.') + 1)
                const blob = new Blob([byteArray], { type: `${comment.noteType}/${formatFile}` })
                // Se crea el archivo con el blob
                const file = new File([blob], `${ulid()}.${formatFile}`)
                const formData = new FormData()
                formData.append('file', file)
                await vm.$fileSystem.deleteFile(comment.filePath)
                if (section == 'note') {
                    return vm.$api.note().upload(data.userId, data.projectId, formData)
                }
                return vm.$api.formAnswer().upload(data.userId, data.projectId, formData)
            } catch (error) {
                console.error(error)
            }
        }

        async function syncTable(data, section) {
            let dataToSendFile = []
            section == 'task' ? (dataToSendFile = [...data.forms]) : (dataToSendFile = [...data])
            for (let i = 0; i < dataToSendFile.length; i++) {
                if ((dataToSendFile[i].new || dataToSendFile[i].update) && dataToSendFile[i].notes) {
                    // Recorro los comentarios
                    for (let j = 0; j < dataToSendFile[i].notes.length; j++) {
                        // Chequeo si el comentario es distinto a texto
                        if (
                            dataToSendFile[i].notes[j].noteType &&
                            dataToSendFile[i].notes[j].noteType != 'comment' &&
                            !dataToSendFile[i].notes[j].comment.includes('agrodreams-data')
                        ) {
                            // eslint-disable-next-line no-await-in-loop
                            const uploadResponse = await sendFile(
                                dataToSendFile[i],
                                dataToSendFile[i].notes[j],
                                section
                            )
                            dataToSendFile[i].notes[j].comment = uploadResponse.body.data
                            if (section == 'task') {
                                delete data.forms[i].notes[j].filePath
                            } else {
                                delete data[i].notes[j].filePath
                            }
                        }
                    }
                }
            }
            try {
                let updateResponse = {}
                if (section == 'note') {
                    updateResponse = await vm.$api.offline()
                        .updateNotes(rootGetters['user/data']._id, project._id, data)
                    for (let i = 0; i < data.length; i++) {
                        commit('notes/deleteNote', data[i], { root: true })
                    }
                    if (updateResponse.body.data) {
                        for (let i = 0; i < updateResponse.body.data.length; i++) {
                            commit('notes/addNote', updateResponse.body.data[i], { root: true })
                        }
                    }
                    return true

                } else if (section == 'activities') {
                    updateResponse = await vm.$api.offline()
                        .updateFormAnswer(rootGetters['user/data']._id, project._id, data)
                    for (let i = 0; i < data.length; i++) {
                        commit('forms/deleteFormAnswer', data[i], { root: true })
                    }

                    if (updateResponse.body.data) {
                        for (let i = 0; i < updateResponse.body.data.length; i++) {
                            commit('forms/addAnswer', updateResponse.body.data[i], { root: true })
                        }
                    }
                    return true
                } else if (section == 'task') {
                    updateResponse = await vm.$api.offline()
                        .updateTask(rootGetters['user/data']._id, project._id, data)
                    for (let i = 0; i < data.tasks.length; i++) {
                        commit('tasks/deleteTask', data.tasks[i], { root: true })
                    }
                    if (updateResponse.body.data.tasks) {
                        for (let i = 0; i < updateResponse.body.data.tasks.length; i++) {
                            commit('tasks/addTask', updateResponse.body.data.tasks[i], { root: true })
                        }
                    }
                    for (let i = 0; i < data.forms.length; i++) {
                        commit('tasks/deleteMonitoring', data.forms[i], { root: true })
                    }
                    if (updateResponse.body.data.forms) {
                        for (let i = 0; i < updateResponse.body.data.forms.length; i++) {
                            commit('tasks/addMonitoring', updateResponse.body.data.forms[i], { root: true })
                        }
                    }
                    return true
                } else if (section == 'monitoring') {
                    updateResponse = await vm.$api.offline()
                        .updateFormAnswer(rootGetters['user/data']._id, project._id, data)
                    for (let i = 0; i < data.length; i++) {
                        commit('tasks/deleteMonitoring', data[i], { root: true })
                    }

                    if (updateResponse.body.data) {
                        for (let i = 0; i < updateResponse.body.data.length; i++) {
                            commit('tasks/addMonitoring', updateResponse.body.data[i], { root: true })
                        }
                    }
                    return true
                }
            } catch (error) {
                return false
            }
        }

        if (project.update && !project.demo) {
            let noteResult = false
            let activitiesResult = false
            let taskResult = false
            let monitoringResult = false

            const offlineNotes = rootGetters['notes/notes']
                .filter((note) => note.projectId == project._id && (note.new || note.update))
            if (offlineNotes?.length) {
                noteResult = syncTable(offlineNotes, 'note')
            } else {
                noteResult = true
            }

            const activitiesForm = rootGetters['forms/answers']
                .filter((form) => form.projectId == project._id && (form.new || form.update))
            if (activitiesForm?.length) {
                activitiesResult = syncTable(activitiesForm, 'activities')
            } else {
                activitiesResult = true
            }

            const offlineTasks = rootGetters['tasks/tasks']
                .filter((task) => task.projectId == project._id && (task.new || task.update))
            let monitoringForm = rootGetters['tasks/monitoringForms']
                .filter((form) => form.projectId == project._id && form.taskId && form.new)
                .filter((form) => offlineTasks.some((task) => task._id == form.taskId))
            if (offlineTasks?.length) {
                taskResult = syncTable({ tasks: offlineTasks, forms: monitoringForm }, 'task')
            } else {
                taskResult = true
            }
            monitoringForm = []
            monitoringForm = rootGetters['tasks/monitoringForms']
                .filter((form) => form.projectId == project._id && !form.taskId && form.new)
            if (monitoringForm?.length) {
                monitoringResult = syncTable(monitoringForm , 'monitoring')
            } else {
                monitoringResult = true
            }

            if(noteResult && activitiesResult && taskResult && monitoringResult) {
                commit('deleteProjectToSync', project)
            }
        }
    }
}

// mutations
const mutations = {
    setActive(state, active) {
        state.active = active
    },
    setSyncing(state, syncing) {
        state.syncing = syncing
    },
    addProjectToSync(state, project) {
        if (!state.projectToSync.some((p) => p._id == project._id)) {
            project.update = true
            state.projectToSync.unshift(project)
        }
    },
    deleteProjectToSync(state, project) {
        const projectIndex = state.projectToSync.findIndex((item) => item._id == project._id)
        state.projectToSync.splice(projectIndex, 1)
    },
    clearProjectToSync(state) {
        state.projectToSync = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
