import { ulid } from 'ulid'
import dayjs from 'dayjs'
const state = {
    answers: [],
    userTemplates: [],
    projectTemplates: [],
    selected: {},
    editAnswer: false,
    newAnswer: false,
    replicateAnswer: false,
    editingTemplate: {},
    noteQueue: { readyToSave: false, notes: [] },
    activitySelected: '',
    saveButtonEnabled: false
}

// getters
const getters = {
    selected: (state) => state.selected,
    answers: (state) => state.answers,
    projectAnswers: (state, _, rootState) => state.answers
        .filter((item) => item.projectId === rootState.projects.selectedProject._id),
    userTemplates: (state) =>
        state.userTemplates.reduce((acc, template) => {
            if (template.adminId !== 'agroAdmin' ||
                !acc.some(
                    (item) =>
                        (item.title.es == template.title.es ||
                        item.title.en == template.title.en) &&
                        (item.category == template.category) &&
                        item._id != template._id
                )
            ) {
                acc.push(template)
            }
            return acc
        }, []),
    projectTemplates: (state) =>
        state.projectTemplates.reduce((acc, template) => {
            if (template.adminId !== 'agroAdmin' ||
                !acc.some(
                    (item) =>
                        (item.title.es == template.title.es ||
                        item.title.en == template.title.en) &&
                        (item.category == template.category) &&
                        item._id != template._id
                )
            ) {
                acc.push(template)
            }
            return acc
        }, []),
    templates: (_, getters, rootState, rootGetters) => {
        const templates = !rootState.projects.selectedProject?.userId ||
            rootState.projects.selectedProject?.userId === rootState.user.data?._id
            ? getters.userTemplates
            : getters.projectTemplates

        const projectCrops = rootGetters['projects/selectedProjectCrops']
            .filter((item) => !item.seasonId || item.seasonId === rootState.seasons.selected?._id)
            .map((item) => item.crop)
            .concat(['default'])

        return templates
            .filter(
                (template) =>
                    !template.projectsIds ||
                    template.projectsIds.includes('default') ||
                    template.projectsIds.includes(rootState.projects.selectedProject?._id)
            )
            .filter((item) => item.crop.some((el) => projectCrops.includes(el)))
    },
    editAnswer: (state) => state.editAnswer,
    newAnswer: (state) => state.newAnswer,
    replicateAnswer: (state) => state.replicateAnswer,
    editingTemplate: (state) => state.editingTemplate,
    noteQueue: (state) => state.noteQueue,
    activitySelected: (state) => state.activitySelected,
    saveButtonEnabled: (state) => state.saveButtonEnabled
}

// actions
const actions = {
    async loadAnswers({ commit, getters, rootGetters }, { vm }) {
        if (rootGetters['offline/active']) {
            return getters.projectAnswers
        }
        const responseFormsAnswer = await vm.$api.formAnswer()
            .getByProject(rootGetters['projects/selectedProject']._id)
        commit('setProjectAnswers', responseFormsAnswer.body.data)
        return responseFormsAnswer.body.data
    },
    async loadAnswerById({ commit, getters, rootGetters }, { vm, formId }) {
        if (rootGetters['offline/active']) {
            const answer = getters.answers.find((item) => item._id === formId) || {}
            commit('setSelected', answer)
            return answer
        }
        const responseFormsAnswer = await vm.$api.formAnswer()
            .getById(formId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', responseFormsAnswer.body.data)
        return responseFormsAnswer.body.data
    },
    async selectAnswerById({ getters, rootGetters }, { vm, formId }) {
        if (rootGetters['offline/active']) {
            return getters.answers.find((item) => item._id === formId)
        }
        const responseFormsAnswer = await vm.$api.formAnswer()
            .getById(formId, rootGetters['projects/selectedProject']._id)
        return responseFormsAnswer.body.data
    },
    async createAnswer({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const newForm = {
                ...form,
                _id: ulid(),
                createdAt: dayjs().valueOf(),
                updatedAt: dayjs().valueOf(),
                new: true,
                formType: 'activity'
            }
            commit('addAnswer', newForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return { message: vm.$t('activities.created') }
        }
        const createResponse = await vm.$api.formAnswer().create(form)
        commit('setSelected', createResponse.body.data)
        commit('addAnswer', createResponse.body.data)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('ACTIVITY_CREATE', { category })
        return createResponse.body
    },
    async updateAnswer({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const updatedForm = { ...form, updatedAt: dayjs().valueOf(), update: true }
            commit('updateAnswer', updatedForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedForm
        }
        const createResponse = await vm.$api
            .formAnswer()
            .update(form._id, rootGetters['projects/selectedProject']._id, form)
        commit('updateAnswer', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('ACTIVITY_UPDATE', { category })
        return createResponse.body.message
    },
    async updateNotes({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const updatedForm = { ...form, update: true }
            commit('updateAnswer', updatedForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedForm
        }
        const createResponse = await vm.$api
            .formAnswer()
            .update(form._id, rootGetters['projects/selectedProject']._id, form)
        commit('updateAnswer', createResponse.body.data)
        commit('setSelectedNotes', createResponse.body.data)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('ACTIVITY_UPDATE', { category })
        return createResponse.body.message
    },
    async deleteAnswer({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            commit('deleteFormAnswer', form)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return vm.$t('activities.deleted')
        }
        const createResponse = await vm.$api.formAnswer()
            .delete(form._id, rootGetters['projects/selectedProject']._id)
        commit('deleteFormAnswer', form)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('ACTIVITY_DELETE', { category })
        return createResponse.body.message
    },
    async loadUserTemplates({ commit }, { vm }) {
        const response = await vm.$api.formTemplate().getAll()
        commit('setUserTemplates', response.body.data)
        commit('tasks/setMonitoringTemplates', response.body.data, { root: true })
        return response.body.data
    },
    async loadProjectTemplates({ commit, rootGetters }, { vm }) {
        const response = await vm.$api.formTemplate().getByProject(
            rootGetters['projects/selectedProject']._id,
            rootGetters['projects/selectedProject'].userId
        )
        commit('setProjectTemplates', response.body.data)
        return response.body.data
    },
    async createTemplate({ commit }, { vm, template }) {
        const createResponse = await vm.$api.formTemplate().create(template)
        commit('addTemplate', createResponse.body.data)
        return createResponse.body
    },
    async updateTemplate({ commit, rootGetters }, { vm, template }) {
        const updateResponse = await vm.$api.formTemplate()
            .update(template._id, rootGetters['user/data']._id, template)
        commit('updateTemplate', updateResponse.body.data)
        return updateResponse.body
    },
    async deleteTemplate({ commit, rootGetters }, { vm, template }) {
        const updateResponse = await vm.$api.formTemplate()
            .delete(template._id, rootGetters['user/data']._id)
        commit('deleteTemplate', updateResponse.body.data)
        return updateResponse.body
    },
    async assignTemplateUsers({ commit, rootGetters }, { vm, template }) {
        const updateResponse = await vm.$api.formTemplate()
            .assignUsers(template._id, rootGetters['user/data']._id, template)
        commit('updateTemplate', updateResponse.body.data)
        return updateResponse.body
    },
    async assignTemplateProjects({ commit, rootGetters }, { vm, template }) {
        const updateResponse = await vm.$api.formTemplate()
            .assignProjects(template._id, rootGetters['user/data']._id, template)
        commit('updateTemplate', updateResponse.body.data)
        return updateResponse.body
    },
    async assignTemplateCrops({ commit, rootGetters }, { vm, template }) {
        const updateResponse = await vm.$api.formTemplate()
            .assignCrops(template._id, rootGetters['user/data']._id, template)
        commit('updateTemplate', updateResponse.body.data)
        return updateResponse.body
    }
}

// mutations
const mutations = {
    setAnswers(state, answers) {
        state.answers = answers
    },
    setProjectAnswers(state, answers) {
        const projectId = answers.length > 0 ? answers[0].projectId : ''
        state.answers = answers.concat(state.answers.filter((answer) => answer.projectId !== projectId))
    },
    addAnswer(state, formAnswerToSave) {
        state.answers.unshift(formAnswerToSave)
    },
    updateAnswer(state, form) {
        state.answers = state.answers.map((item) => (item._id == form._id ? form : item))
    },
    deleteFormAnswer(state, formToDelete) {
        const answerIndex = state.answers.findIndex((item) => item._id == formToDelete._id)
        state.answers.splice(answerIndex, 1)
    },
    setUserTemplates(state, templates) {
        state.userTemplates = templates
    },
    setProjectTemplates(state, templates) {
        state.projectTemplates = templates
    },
    addTemplate(state, formTemplate) {
        state.userTemplates.unshift(formTemplate)
    },
    updateTemplate(state, form) {
        state.userTemplates = state.userTemplates.map((item) => (item._id == form._id ? form : item))
    },
    deleteTemplate(state, formToDelete) {
        const templateIndex = state.userTemplates.findIndex((item) => item._id == formToDelete._id)
        state.userTemplates.splice(templateIndex, 1)
    },
    setSelected(state, form) {
        const template =
            state.userTemplates.find((item) => item._id == form.formTemplateId) ||
            state.projectTemplates.find((item) => item._id == form.formTemplateId) ||
            state.userTemplates
                .concat(state.projectTemplates)
                .find(
                    (item) =>
                        (item.title?.es == form.title?.es || item.title?.en == form.title?.en) &&
                    item.category == form.category
                ) || {}
        state.selected = form || {}
        state.selected.formTemplateId = template._id
        state.selected.fields = Object.assign([], template.fields)
    },
    setSelectedNotes(state, form) {
        state.selected.notes = form.notes || []
    },
    setEditAnswer(state, value) {
        state.editAnswer = value
    },
    setNewAnswer(state, value) {
        state.newAnswer = value
    },
    setReplicateAnswer(state, value) {
        state.replicateAnswer = value
    },
    setEditingTemplate(state, value) {
        state.editingTemplate = value
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setActivitySelected(state, value) {
        state.activitySelected = value
    },
    setButtonStatus(state, status) {
        state.saveButtonEnabled = status
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.answers = []
            state.projectTemplates = []
        }
        state.selected = {}
        state.editAnswer = false
        state.newAnswer = false
        state.replicateAnswer = false
        state.editingTemplate = {}
        state.activitySelected = ''
        state.noteQueue = { readyToSave: false, notes: [] }
        state.saveButtonEnabled = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
