<template>
    <v-navigation-drawer
        v-model="isOpen"
        absolute
        temporary
        class="app-menu"
        width="280"
    >
        <div id="tourMenu" class="app-menu__content">
            <header
                class="app-menu__header"
                :style="{ 'background-color': colors[0] }"
            >
                <img
                    v-if="cobrandingLogo"
                    :src="cobrandingLogo"
                    alt=""
                    class="app-menu__logo safe-area-top safe-area-left"
                >
                <img
                    v-else
                    :src="require('@/assets/img/logo-menu.svg')"
                    alt=""
                    class="app-menu__logo safe-area-top safe-area-left"
                >
                <span class="app-menu__close-icon safe-area-top" @click="closeMenu()">
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <!-- eslint-disable-next-line max-len -->
                        <path d="M6.54267 5.01094L9.69365 1.85996C10.1313 1.42232 10.1313 0.722101 9.69365 0.328228C9.25602 -0.109409 8.5558 -0.109409 8.16193 0.328228L5.01094 3.47921L1.85996 0.328228C1.42232 -0.109409 0.722101 -0.109409 0.328228 0.328228C-0.109409 0.765864 -0.109409 1.46608 0.328228 1.85996L3.47921 5.01094L0.328228 8.16193C-0.109409 8.59956 -0.109409 9.29978 0.328228 9.69365C0.547046 9.91247 0.809628 10 1.11597 10C1.42232 10 1.6849 9.91247 1.90372 9.69365L5.0547 6.54267L8.20569 9.69365C8.42451 9.91247 8.68709 10 8.99343 10C9.29978 10 9.56236 9.91247 9.78118 9.69365C10.2188 9.25602 10.2188 8.5558 9.78118 8.16193L6.54267 5.01094Z" fill="#FFFFFF" fill-opacity="1" />
                    </svg>
                </span>
            </header>
            <div class="app-menu__subheader">
                <div
                    class="app-menu__hectares"
                    :style="{ 'background-color': colors[0] }"
                >
                    <div class="app-menu__hectares--content">
                        <div class="app-menu__hectares--info">
                            <span
                                class="app-menu__hectares--label safe-area-left"
                            >
                                {{ Math.round((user.maxArea||0) / 10000) -
                                    Math.round((user.activeArea||0) / 10000) }} {{ $t('mainMenu.hectaresAvailable') }}
                            </span>
                            <span
                                class="app-menu__hectares--subtitle safe-area-left"
                            >
                                {{ usedHectares }}% {{ $t('mainMenu.used') }} ({{
                                    Math.round((user.activeArea||0) / 10000) }} / {{
                                    Math.round((user.maxArea||0) / 10000) }} ha)
                            </span>
                        </div>
                        <v-btn
                            v-if="showUpgradeButton"
                            ripple
                            depressed
                            :color="colors[3]"
                            small
                            rounded
                            width="24"
                            height="24"
                            class="app-menu__hectares--add"
                            @click="dialogSendMessageSubject = $t('profile.subjects.hectares');
                                    dialogSendMessage = true"
                        >
                            <v-icon size="16" color="titleColor">aicon-mas</v-icon>
                        </v-btn>
                    </div>
                    <v-progress-linear
                        class="app-menu__hectares--progress safe-area-left"
                        :color="progressColor"
                        height="3"
                        :value="usedHectares"
                    />
                </div>
                <div
                    class="app-menu__plan"
                    :style="{ 'background-color': colors[0] }"
                >
                    <div class="app-menu__plan--data safe-area-left">
                        <p class="plan">{{ pack.name }}</p>
                        <p>{{ $t('mainMenu.yourPlan') }}</p>
                    </div>
                    <v-btn
                        v-if="showUpgradeButton"
                        :dark="isTooDark(colors[4])"
                        rounded
                        depressed
                        :color="colors[4]"
                        @click="dialogSendMessageSubject = $t('profile.subjects.plan');dialogSendMessage = true"
                    >
                        {{ $t('mainMenu.upgrade') }}
                    </v-btn>
                </div>

                <div
                    v-if="cobrandingLogo"
                    class="app-menu__powered-by"
                    :style="{ 'background-color': colors[0] }"
                >
                    <img
                        :src="require('@/assets/img/powered-by-white.svg')"
                        alt="Powered by Agrodreams"
                    >
                </div>
            </div>
            <v-list
                dense
                class="app-menu__list"
                :style="{ 'background-color': colors[1] }"
            >
                <v-list-item-group
                    v-for="(category, i) in appMenu"
                    :key="i"
                    class="app-menu__list--group safe-area-left"
                >
                    <v-list-item
                        v-for="(item, j) in category"
                        :key="j"
                        :class="{'app-menu__active-link': item.name === $router.currentRoute.name ||
                            item.name === 'Projects' && $router.currentRoute.name &&
                            $router.currentRoute.name.match(/Project/)}"
                        :style="item.name === $router.currentRoute.name ||
                            item.name === 'Projects' && $router.currentRoute.name &&
                            $router.currentRoute.name.match(/Project/) ? { 'background': colors[2] } : { }"
                        :disabled="offline&&!item.offline"
                        @click="changeView(item)"
                    >
                        <v-list-item-title
                            :style="item.name === $router.currentRoute.name ||
                                item.name === 'Projects' && $router.currentRoute.name &&
                                $router.currentRoute.name.match(/Project/) ?
                                    isTooDark(colors[2]) ? { 'color': 'white' } : { } :
                                    isTooDark(colors[1]) ? { 'color': 'white' } : { }"
                        >
                            <v-icon
                                :color="(item.name === $router.currentRoute.name ||
                                    item.name === 'Projects' && $router.currentRoute.name &&
                                    $router.currentRoute.name.match(/Project/)) ?
                                    (isTooDark(colors[2]) ? 'white' : 'titleColor') :
                                    (isTooDark(colors[1]) ? 'white' : 'titleColor')"
                                class="app-menu__icon"
                            >
                                {{ item.icon }}
                            </v-icon>
                            {{ $t('mainMenu.'+item.label) }}
                        </v-list-item-title>
                        <v-list-item-icon>
                            <v-icon
                                size="20"
                                :color="(item.name === $router.currentRoute.name ||
                                    item.name === 'Projects' && $router.currentRoute.name &&
                                    $router.currentRoute.name.match(/Project/)) ?
                                    (isTooDark(colors[2]) ? 'white' : colors[1]) :
                                    (isTooDark(colors[1]) ? 'white' : colors[2])"
                            >
                                aicon-arrow-right
                            </v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <div
                class="logout-box"
                :style="{ 'background-color': colors[1] }"
            >
                <v-spacer />
                <v-btn
                    depressed
                    text
                    rounded
                    :color="isTooDark(colors[1]) ? 'white' : 'primary'"
                    class="uppercase-btn safe-area-bottom safe-area-left"
                    @click="signout()"
                >
                    {{ $t('common.exit') }}
                </v-btn>
            </div>
            <dialog-contact
                class="profile-modal"
                :active.sync="dialogSendMessage"
                :subject="dialogSendMessageSubject"
                :title="$t('profile.contactUs')"
                :cancel-text="$t('common.cancel')"
                :confirm-text="$t('common.send')"
            />
        </div>
    </v-navigation-drawer>
</template>
<script>
import { mapState } from 'vuex'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import DialogContact from '@/components/Dialogs/DialogContact'
import stylesColorsJson from '@/assets/json/stylesColors.json'
export default {
    name: 'MainMenu',
    components: {
        'dialog-contact': DialogContact
    },
    data() {
        return {
            showUpgradeButton: true,
            dialogSendMessage: false,
            dialogSendMessageSubject: '',
            cobrandingLogo: process.env.VUE_APP_COBRANDING_MENU_LOGO,
            colors: stylesColorsJson[process.env.VUE_APP_COBRANDING_COLORS || 'default']
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.data,
            pack: state => state.user.pack,
            sections: state => state.mainMenu.sections,
            offline: state => state.offline.active,
            deviceInfo: (state) => state.user.deviceInfo
        }),
        isOpen: {
            get() {
                return this.$store.getters['mainMenu/isOpen']
            },
            set(newValue) {
                this.$store.commit('mainMenu/setIsOpen', newValue)
            }
        },
        menuSelected: {
            get() {
                return this.$store.getters['mainMenu/selected']
            },
            set(newValue) {
                this.$store.commit('mainMenu/changeSelected', newValue)
            }
        },
        appMenu() {
            return this.pack && this.pack.features && this.sections
                .filter(this.checkPermissions)
                .filter(item => item.show)
                .reduce((sections, menu) => {
                    if (!sections[menu.cat]) {
                        sections[menu.cat] = []
                    }

                    sections[menu.cat].push(menu)
                    return sections
                }, {})
        },
        usedHectares() {
            // Valor en porcentaje 0 - 100%
            return Math.round((this.user.activeArea || 0) * 100 / (this.user.maxArea || Infinity))
        },
        progressColor() {
            return this.usedHectares >= 80 ? 'error'
                : this.usedHectares > 50 ? 'warning' : this.colors[5]
        }
    },
    watch: {
        '$route.name'(newValue) {
            if(newValue) {
                const section = this.sections.find(sections => sections.name === newValue)
                if(section) this.menuSelected = section
            }
        }
    },
    async beforeMount(){
        this.menuSelected = this.sections.find( item => item.name === this.$route.name)
        const info = await Device.getInfo()
        this.showUpgradeButton = info.platform !== 'ios' && info.operatingSystem != 'ios'
    },
    methods: {
        changeView(item){
            this.drawer = false
            if( item.name.match(/(http|https)/)) {
                window.open(item.name, '_blank')
            } else if( item.name !== this.$router.currentRoute.name){
                this.$router.push({ name: item.name })
            }
        },
        closeMenu(){
            this.$store.commit('mainMenu/setIsOpen', false)
        },
        signout() {
            this.$store.dispatch('clearUserData')
            window.setTimeout(() => {
                this.$router.push({name: 'Login'})
            }, 500)
        },
        checkPermissions(item) {
            if(item.name === 'Analytics' && (!this.$store.getters['user/pack'].features.analytics ||
                !this.$store.getters['user/data'].hasAnalytics ||
                this.deviceInfo.operatingSystem == 'ios')) {
                return false
            }
            if(item.name === 'KnowledgeBase' && !this.pack.features.wiki && !Capacitor.isNative) {
                return false
            }
            if(item.name === 'AdminTemplates' && Capacitor.isNative) {
                return false
            }
            // TOFIX
            if(item.name === 'GlobalWeather' && Capacitor.isNative) {
                return false
            }
            return true
        },
        isTooDark(hexcolor) {
            const r = parseInt(hexcolor.substr(1,2),16)
            const g = parseInt(hexcolor.substr(3,2),16)
            const b = parseInt(hexcolor.substr(4,2),16)
            const yiq = ((r*299)+(g*587)+(b*114))/1000
            return (yiq < 155)
        }
    }
}
</script>
<style lang="scss" scoped>
    @import 'MainMenu';
</style>