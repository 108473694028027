import { ulid } from 'ulid'
import dayjs from 'dayjs'
const state = {
    tasks: [],
    monitoringForms: [],
    selectedMonitoringGroups: [],
    templates: [],
    noteQueueMonitoring: { readyToSave: false, notes: [] },
    noteQueueTask: { readyToSave: false, notes: [] },
    selected: {},
    selectedMonitoring: {},
    editTask: false,
    editMonitoring: false,
    newMonitoring: false,
    clickInForm: false,
    selectedFormDate: undefined,
    monitoringFolderSelected: '',
    saveButtonEnabled: false
}

// getters
const getters = {
    selected: (state) => state.selected,
    selectedMonitoring: (state) => state.selectedMonitoring,
    tasks: (state) => state.tasks,
    projectTasks: (state, _, rootState) => state.tasks
        .filter((item) => item.projectId === rootState.projects.selectedProject._id),
    monitoringForms: (state) => state.monitoringForms,
    projectMonitoringForms: (state, _, rootState) => state.monitoringForms
        .filter((item) => item.projectId === rootState.projects.selectedProject._id),
    editTask: (state) => state.editTask,
    editMonitoring: (state) => state.editMonitoring,
    newMonitoring: (state) => state.newMonitoring,
    noteQueueMonitoring: (state) => state.noteQueueMonitoring,
    noteQueueTask: (state) => state.noteQueueTask,
    clickInForm: (state) => state.clickInForm,
    selectedFormDate: (state) => state.selectedFormDate,
    monitoringFolderSelected: (state) => state.monitoringFolderSelected,
    selectedMonitoringGroups: (state) => state.selectedMonitoringGroups,
    saveButtonEnabled: (state) => state.saveButtonEnabled,
    templates: (state) => state.templates
}

// actions
const actions = {
    async loadTask({ commit, rootGetters }, { vm }) {
        if (rootGetters['offline/active']) {
            return getters.projectTasks
        }
        const res = await vm.$api.task().getByProject(rootGetters['projects/selectedProject']._id)
        commit('setTask', res.body.data)
        return res.body.data
    },
    async loadTaskById({ commit, rootGetters }, { vm, taskId }) {
        if (rootGetters['offline/active']) {
            const task = getters.projectTasks.find((item) => item._id === taskId) || {}
            commit('setSelected', task)
            return task
        }
        const res = await vm.$api.task().getById(taskId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', res.body.data)
        return res.body.data
    },
    async create({ commit, rootGetters }, { vm, task }) {
        if (!rootGetters['offline/active']) {
            const res = await vm.$api.task().create(task)
            commit('addTask', res.body.data)
            commit('setSelected', res.body.data)
            amplitude.getInstance().logEvent('MONITORING_CREATE', { category: task.category })
            return res.body.message
        }
    },
    async update({ commit, rootGetters }, { vm, task }) {
        if (rootGetters['offline/active']) {
            const updatedTask = { ...task, updatedAt: dayjs().valueOf(), update: true }
            commit('updateTask', updatedTask)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedTask
        }
        const res = await vm.$api.task().update(task._id, rootGetters['projects/selectedProject']._id, task)
        commit('updateTask', res.body.data)
        amplitude.getInstance().logEvent('MONITORING_UPDATE', { category: task.category })
        return res.body
    },
    async addForm({ commit, rootGetters }, { vm, task, form }) {
        const data = { task, form }
        if (rootGetters['offline/active']) {
            const newForm = {
                _id: ulid(),
                projectId: form.projectId,
                userId: form.userId,
                formTemplateId: form.formTemplateId,
                title: form.title,
                category: form.category,
                crop: form.crop,
                answers: form.answers,
                seasonId: form.seasonId,
                notes: form.notes,
                location: form.location,
                waypoints: form.waypoints ? form.waypoints : [],
                subProjectId: form.subProjectId,
                plotId: form.plotId,
                createdAt: dayjs().valueOf(),
                updatedAt: dayjs().valueOf(),
                new: true,
                formType: 'monitoring'
            }
            if (vm.newMonitoring && !task.new && !task.update) {
                task._id = ulid()
                task.formsAnswerId = [...task.formsAnswerId, newForm._id]
                task.date = dayjs(task.date).valueOf()
                const newTask = {
                    ...task,
                    new: true,
                    createdAt: dayjs().valueOf(),
                    updatedAt: dayjs().valueOf()
                }
                commit('addTask', newTask)
                // eslint-disable-next-line no-param-reassign
                task = newTask
            } else {
                if (!task.new) task.update = true
                task.date = dayjs(task.date).valueOf()
                task.updatedAt = dayjs().valueOf()
                task.formsAnswerId = [...task.formsAnswerId, newForm._id],
                commit('updateTask', task)
            }
            newForm.taskId = task._id
            commit('addMonitoring', newForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            commit('setSelected', task)
            return { task: task, form: newForm, message: vm.$t('monitoring.created') }
        }
        const res = await vm.$api.task().addForm(task._id, rootGetters['projects/selectedProject']._id, data)
        commit('addMonitoring', { ...res.body.data.form })
        commit('updateTask', res.body.data.task)
        commit('setSelected', res.body.data.task)
        amplitude.getInstance().logEvent('MONITORING_CREATE_FORM', { category: task.category })
        return res.body
    },
    async delete({ commit, rootGetters }, { vm, task }) {
        const res = await vm.$api
            .task()
            .delete(task._id, rootGetters['projects/selectedProject']._id, task.formsAnswerId)
        commit('deleteTask', task)
        task.formsAnswerId.forEach(formId => {
            commit('deleteMonitoring', { _id: formId })
        })
        amplitude.getInstance().logEvent('MONITORING_DELETE', { category: task.category })
        return res.body.message
    },
    async deleteForm({ commit, rootGetters }, { vm, task, form }) {
        const res = await vm.$api
            .task()
            .deleteForm(form._id, rootGetters['projects/selectedProject']._id)

        commit('deleteMonitoring', form)

        if(task?._id) {
            commit('updateTask', task)
            amplitude.getInstance().logEvent('MONITORING_DELETE_FORM', { category: task.category })
        }
        return res.body.message
    },
    async loadMonitoringForms({ commit, getters, rootGetters }, { vm, queryParams={} }) {
        if (rootGetters['offline/active']) {
            return getters.projectMonitoringForms
        }
        const responseFormsAnswer = await vm.$api.task()
            .getMonitoringByProject(rootGetters['projects/selectedProject']._id, queryParams)
        commit('setProjectMonitoringForms', responseFormsAnswer.body.data)
        return responseFormsAnswer.body.data
    },
    async createMonitoring({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const newForm = {
                ...form,
                _id: ulid(),
                createdAt: dayjs().valueOf(),
                updatedAt: dayjs().valueOf(),
                new: true,
                formType: 'monitoring'
            }
            commit('addMonitoring', newForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return { message: vm.$t('monitoring.created') }
        }
        const createResponse = await vm.$api.task().createMonitoring(form, rootGetters['projects/selectedProject']._id)
        commit('addMonitoring', createResponse.body.data )
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('MONITORING_CREATE', { category })
        return createResponse.body
    },
    async updateAnswer({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const updatedForm = { ...form, updatedAt: dayjs().valueOf(), update: true }
            commit('updateMonitoring', updatedForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedForm
        }
        const createResponse = await vm.$api
            .formAnswer()
            .update(form._id, rootGetters['projects/selectedProject']._id, form)
        commit('updateMonitoring', createResponse.body.data)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('MONITORING_UPDATE', { category })
        return createResponse.body.message
    },
    async updateNotes({ commit, rootGetters }, { vm, form }) {
        if (rootGetters['offline/active']) {
            const updatedForm = { ...form, updatedAt: dayjs().valueOf(), update: true }
            commit('updateMonitoring', updatedForm)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedForm
        }
        const createResponse = await vm.$api
            .formAnswer()
            .update(form._id, rootGetters['projects/selectedProject']._id, form)
        commit('updateMonitoring', createResponse.body.data)
        commit('setSelectedNotes', createResponse.body.data)
        const { category } = createResponse.body.data
        amplitude.getInstance().logEvent('MONITORING_UPDATE', { category })
        return createResponse.body.message
    }
}

// mutations
const mutations = {
    setTask(state, tasks) {
        state.tasks = tasks
    },
    addTask(state, taskToSave) {
        state.tasks.unshift(taskToSave)
    },
    addMonitoring(state, form) {
        state.monitoringForms.unshift(form)
        if (state.selectedMonitoringGroups?.forms) {
            state.selectedMonitoringGroups.forms.unshift(form)
        }
    },
    updateTask(state, task) {
        state.tasks = state.tasks.map((item) => (item._id == task._id ? task : item))
    },
    deleteTask(state, taskToDelete) {
        const taskIndex = state.tasks.findIndex((item) => item._id == taskToDelete._id)
        state.tasks.splice(taskIndex, 1)
    },
    updateMonitoring(state, form) {
        state.monitoringForms = state.monitoringForms.map((item) => (item._id == form._id ? form : item))
        state.selectedMonitoringGroups.forms = state.selectedMonitoringGroups.forms
            .map((item) => (item._id == form._id ? form : item))
    },
    deleteMonitoring(state, form) {
        const formIndex = state.monitoringForms.findIndex((item) => item._id == form._id)
        state.monitoringForms.splice(formIndex, 1)
        if(state.selectedMonitoringGroups?.forms?.length > 0) {
            const groupIndex = state.selectedMonitoringGroups.forms.findIndex((item) => item._id == form._id)
            state.selectedMonitoringGroups.forms.splice(groupIndex, 1)
        }
    },
    setSelected(state, task) {
        state.selected = task
    },
    setProjectMonitoringForms(state, monitoringForms) {
        state.monitoringForms = monitoringForms
    },
    setSelectedMonitoring(state, monitoring) {
        state.selectedMonitoring = monitoring
        if (monitoring.formTemplateId) {
            const template = state.templates.find((item) => item._id == monitoring.formTemplateId)
            state.selectedMonitoring.fields = Object.assign([], template.fields)
        }
    },
    setEditTask(state, value) {
        state.editTask = value
    },
    setEditMonitoring(state, value) {
        state.editMonitoring = value
    },
    setNewMonitoring(state, value) {
        state.newMonitoring = value
    },
    setNoteQueueMonitoring(state, value) {
        state.noteQueueMonitoring = value
    },
    setNoteQueueTask(state, value) {
        state.noteQueueTask = value
    },
    setClickInForm(state, status) {
        state.clickInForm = status
    },
    setSelectedFormDate(state, date) {
        state.selectedFormDate = date
    },
    setMonitoringFolderSelected(state, folder) {
        state.monitoringFolderSelected = folder
    },
    setMonitoringGroups(state, groups) {
        state.selectedMonitoringGroups = groups
    },
    setMonitoringTemplates(state, templates) {
        state.templates = templates
            .filter(template => template.category.match(/(customMonitoring|anomaliesMonitoring|sowingControl)/))
    },
    setButtonStatus(state, status) {
        state.saveButtonEnabled = status
    },
    setSelectedNotes(state, form) {
        state.selectedMonitoring.notes = form.notes || []
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.tasks = []
            state.monitoringForms = []
            state.templates = []
        }
        state.selected = {}
        state.selectedMonitoring = {}
        state.editTask = false
        state.editMonitoring = false
        state.newMonitoring = false
        state.noteQueueMonitoring = { readyToSave: false, notes: [] }
        state.noteQueueTask = { readyToSave: false, notes: [] }
        state.saveButtonEnabled = false
        state.clickInForm = false,
        state.selectedFormDate = undefined
        state.monitoringFolderSelected = '',
        state.selectedMonitoringGroups = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
