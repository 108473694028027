export default {
    install(Vue) {
        const serverUrl = process.env.VUE_APP_SERVER
        const api = {
            user() {
                return {
                    login: (data) => Vue.http.post(`${serverUrl}/user/login`, data),
                    loginGoogle: (data) => Vue.http.post(`${serverUrl}/user/login/google`, data),
                    loginApple: (data) => Vue.http.post(`${serverUrl}/user/login/apple`, data),
                    loginDevice: (data) => Vue.http.post(`${serverUrl}/device/login`, data),
                    connectDevice: (deviceId) => Vue.http.post(`${serverUrl}/device/${deviceId}/connect`),
                    disconnectDevice: (deviceId) => Vue.http.post(`${serverUrl}/device/${deviceId}/disconnect`),
                    get: () => Vue.http.get(`${serverUrl}/user`),
                    send: (formData) => Vue.http.post(`${serverUrl}/user/message`, formData),
                    updateTour: (userId, data) => Vue.http.put(`${serverUrl}/user/${userId}/tour`, data)
                }
            },
            pack() {
                return {
                    getAll: () => Vue.http.get(`${serverUrl}/pack/availables`),
                    get: (packId) => Vue.http.get(`${serverUrl}/pack/${packId}`),
                    getDemos: () => Vue.http.get(`${serverUrl}/pack/demos`)
                }
            },
            project() {
                return {
                    get: (projectId, userId) => Vue.http.get(`${serverUrl}/project/${projectId}/user/${userId}`),
                    getAll: () => Vue.http.get(`${serverUrl}/project`),
                    getShared: () => Vue.http.get(`${serverUrl}/project/shared`),
                    getByTeam: (teamId, userIdTeam) =>
                        Vue.http.get(`${serverUrl}/project/team/${teamId}/${userIdTeam}`),
                    create: (projectData) => Vue.http.post(`${serverUrl}/project`, projectData),
                    update: (projectId, projectData) => Vue.http.put(`${serverUrl}/project/${projectId}`, projectData),
                    getNotes: (projectId) => Vue.http.get(`${serverUrl}/note/project/${projectId}`),
                    getStages: (projectId) => Vue.http.get(`${serverUrl}/stage/project/${projectId}`),
                    getFolders: (projectId) => Vue.http.get(`${serverUrl}/folders/project/${projectId}`),
                    getProcesses: () => Vue.http.get(`${serverUrl}/project/processes`),
                    getProcess: (processId) => Vue.http.get(`${serverUrl}/project/process/${processId}`),
                    generateShapefile: (projectId, params) =>
                        Vue.http.post(`${serverUrl}/project/${projectId}/shapefile`, params),
                    addFolder: (projectId, folderId) =>
                        Vue.http.put(`${serverUrl}/project/${projectId}/folder/${folderId}`),
                    removeFolder: (projectId) => Vue.http.delete(`${serverUrl}/project/${projectId}/folder`),
                    enable: (projectId) => Vue.http.put(`${serverUrl}/project/${projectId}/enable`),
                    disable: (projectId) => Vue.http.put(`${serverUrl}/project/${projectId}/disable`),
                    delete: (projectId) => Vue.http.delete(`${serverUrl}/project/${projectId}`)
                }
            },
            dates() {
                return {
                    getFromProject: (projectId) => Vue.http.get(`${serverUrl}/dates/project/${projectId}/satellite`)
                }
            },
            stage() {
                return {
                    get: (stageId, projectId) => Vue.http.get(`${serverUrl}/stage/${stageId}/project/${projectId}`),
                    create: (stageData) => Vue.http.post(`${serverUrl}/stage`, stageData),
                    update: (stageId, projectId, stageData) =>
                        Vue.http.put(`${serverUrl}/stage/${stageId}/project/${projectId}`, stageData),
                    delete: (stageId, projectId) =>
                        Vue.http.delete(`${serverUrl}/stage/${stageId}/project/${projectId}`),
                    getNotes: (projectId, stageId) => Vue.http.get(`${serverUrl}/note/project/${projectId}/${stageId}`),
                    addFolder: (stageId, projectId, folderId) =>
                        Vue.http.put(`${serverUrl}/stage/${stageId}/project/${projectId}/folder/${folderId}`),
                    removeFolder: (stageId, projectId) =>
                        Vue.http.delete(`${serverUrl}/stage/${stageId}/project/${projectId}/folder`)
                }
            },
            folder() {
                return {
                    get: (folderId, userId) => Vue.http.get(`${serverUrl}/folders/${folderId}/user/${userId}`),
                    getProject: () => Vue.http.get(`${serverUrl}/folders`),
                    getAll: () => Vue.http.get(`${serverUrl}/folders/all`),
                    create: (folderData) => Vue.http.post(`${serverUrl}/folders`, folderData),
                    createStage: (projectId, folderData) =>
                        Vue.http.post(`${serverUrl}/folders/project/${projectId}`, folderData),
                    update: (folderId, userId, folderData) =>
                        Vue.http.put(`${serverUrl}/folders/${folderId}/user/${userId}`, folderData),
                    delete: (folderId, userId) => Vue.http.delete(`${serverUrl}/folders/${folderId}/user/${userId}`)
                }
            },
            note() {
                return {
                    getFromProject: (projectId) => Vue.http.get(`${serverUrl}/note/project/${projectId}`),
                    getById: (noteId, projectId) => Vue.http.get(`${serverUrl}/note/${noteId}/project/${projectId}`),
                    create: (noteData) => Vue.http.post(`${serverUrl}/note`, noteData),
                    update: (noteId, projectId, noteData) =>
                        Vue.http.put(`${serverUrl}/note/${noteId}/project/${projectId}`, noteData),
                    delete: (noteId, projectId) => Vue.http.delete(`${serverUrl}/note/${noteId}/project/${projectId}`),
                    upload: (userId, projectId, data, options) =>
                        Vue.http.post(`${serverUrl}/note/upload/${userId}/${projectId}`, data, options || {}),
                    getSubProject: (projectId) => Vue.http.get(`${serverUrl}/note/getsubproject/${projectId}`)
                }
            },
            team() {
                return {
                    getAll: () => Vue.http.get(`${serverUrl}/teams`),
                    getAllMember: () => Vue.http.get(`${serverUrl}/teams/member`),
                    getProjects: (sharedProjectsTeam) => Vue.http.post(`${serverUrl}/project`, sharedProjectsTeam),
                    getById: (teamId, userId) => Vue.http.get(`${serverUrl}/teams/${teamId}/user/${userId}`),
                    getMembersById: (teamId, userId) =>
                        Vue.http.get(`${serverUrl}/teams/${teamId}/members/user/${userId}`),
                    create: (teamData) => Vue.http.post(`${serverUrl}/teams`, teamData),
                    update: (teamId, userId, teamData) =>
                        Vue.http.put(`${serverUrl}/teams/${teamId}/user/${userId}`, teamData),
                    delete: (teamId, userId) => Vue.http.delete(`${serverUrl}/teams/${teamId}/user/${userId}`),
                    addUser: (teamId, userId, email, teamData) =>
                        Vue.http.put(`${serverUrl}/teams/${teamId}/user/${userId}/member/${email}`, teamData),
                    removeUser: (teamId, userId, email) =>
                        Vue.http.delete(`${serverUrl}/teams/${teamId}/user/${userId}/member/${email}`)
                }
            },
            offline() {
                return {
                    getProjects: () => Vue.http.get(`${serverUrl}/offline/projects`),
                    getDataProjects: () => Vue.http.get(`${serverUrl}/offline/data-projects`),
                    getStage: (stageId, projectId) =>
                        Vue.http.get(`${serverUrl}/offline/stage/${stageId}/project/${projectId}`),
                    updateNotes: (userId, projectId, notesData) =>
                        Vue.http.put(`${serverUrl}/offline/notes/${userId}/${projectId}`, notesData),
                    updateFormAnswer: (userId, projectId, formsData) =>
                        Vue.http.put(`${serverUrl}/offline/form-answer/${userId}/${projectId}`, formsData),
                    updateTask: (userId, projectId, taskData) =>
                        Vue.http.put(`${serverUrl}/offline/task/${userId}/${projectId}`, taskData)
                }
            },
            flight() {
                return {
                    get: (flightId, projectId) => Vue.http.get(`${serverUrl}/flights/${flightId}/project/${projectId}`),
                    getFromProject: (projectId) => Vue.http.get(`${serverUrl}/flights/project/${projectId}`),
                    create: (flightData) => Vue.http.post(`${serverUrl}/flights`, flightData),
                    update: (flightId, projectId, flightData) =>
                        Vue.http.put(`${serverUrl}/flights/${flightId}/project/${projectId}`, flightData),
                    delete: (flightId, projectId) =>
                        Vue.http.delete(`${serverUrl}/flights/${flightId}/project/${projectId}`)
                }
            },
            prescription() {
                return {
                    getFromProject: (projectId, modes) => Vue.http.get(
                        `${serverUrl}/prescriptions/project/${projectId}`,
                        {modes}
                    ),
                    getById: (prescriptionId, projectId) =>
                        Vue.http.get(`${serverUrl}/prescriptions/${prescriptionId}/project/${projectId}`),
                    create: (prescriptionData) => Vue.http.post(`${serverUrl}/prescriptions`, prescriptionData),
                    createGeojson: (projectId, data) =>
                        Vue.http.post(`${serverUrl}/prescriptions/project/${projectId}/geojson`, data),
                    update: (prescriptionId, projectId, prescriptionData) =>
                        Vue.http
                            .put(`${serverUrl}/prescriptions/${prescriptionId}/project/${projectId}`, prescriptionData),
                    updateGeojson: (prescriptionId, projectId, data) =>
                        Vue.http.put(`${serverUrl}/prescriptions/${prescriptionId}/project/${projectId}/geojson`, data),
                    delete: (prescriptionId, projectId) =>
                        Vue.http.delete(`${serverUrl}/prescriptions/${prescriptionId}/project/${projectId}`),
                    generate: (prescription, output, data, options) => {
                        const generateUrl = Vue.prototype.$tiles.url().fromPrescription(prescription, output)
                        return data ? Vue.http.post(generateUrl, data, options) : Vue.http.get(generateUrl, options)
                    }
                }
            },
            import() {
                return {
                    getFromProject: (projectId) => Vue.http.get(`${serverUrl}/imports/project/${projectId}`),
                    getFromProjectWithDrone: (projectId) =>
                        Vue.http.get(`${serverUrl}/imports/drone/project/${projectId}`),
                    getById: (importId, projectId) =>
                        Vue.http.get(`${serverUrl}/imports/${importId}/project/${projectId}`),
                    update: (importId, projectId, importData) =>
                        Vue.http.put(`${serverUrl}/imports/${importId}/project/${projectId}`, importData),
                    updateName: (importId, projectId, data) =>
                        Vue.http.put(`${serverUrl}/imports/rename/${importId}/project/${projectId}`, data),
                    upload: (userId, projectId, data, options) =>
                        Vue.http.post(`${serverUrl}/imports/upload/${userId}/${projectId}`, data, options || {}),
                    delete: (importId, projectId) =>
                        Vue.http.delete(`${serverUrl}/imports/${importId}/project/${projectId}`)
                }
            },
            season() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/seasons`),
                    create: (seasonData) => Vue.http.post(`${serverUrl}/seasons`, seasonData),
                    update: (seasonId, userId, seasonData) =>
                        Vue.http.put(`${serverUrl}/seasons/${seasonId}/user/${userId}`, seasonData),
                    delete: (seasonId, userId) => Vue.http.delete(`${serverUrl}/seasons/${seasonId}/user/${userId}`)
                }
            },
            grid() {
                return {
                    availables: (projectId) => Vue.http.get(`grid/availables/${projectId}`),
                    getList: (projectId, date) => {
                        const getUrl = `grid/list/${projectId}/${date}`
                        return Vue.http.get(getUrl)
                    },
                    getById: (gridId, projectId) =>
                        Vue.http.get(`${serverUrl}/grid/${gridId}/project/${projectId}`),
                    upload: (userId, projectId, date, data, options) => {
                        const uploadUrl = `${serverUrl}/grid/upload/${userId}/${projectId}/${date}`
                        return Vue.http.post(uploadUrl, data, options || {})
                    },
                    createDji: (userId, projectId, date, data, options) => {
                        const uploadUrl = `${serverUrl}/grid/createdji/${userId}/${projectId}/${date}`
                        return Vue.http.post(uploadUrl, data, options || {})
                    },
                    update: (gridId, projectId, gridData) =>
                        Vue.http.put(`${serverUrl}/grid/${gridId}/project/${projectId}`, gridData)
                }
            },
            drone() {
                return {
                    getFromProject: (projectId) =>
                        Vue.http.get(`${serverUrl}/drone/project/${projectId}`),
                    getById: (droneId, projectId) =>
                        Vue.http.get(`${serverUrl}/drone/${droneId}/project/${projectId}`),
                    create: (projectId, data) =>
                        Vue.http.post(`${serverUrl}/drone`, data),
                    createProcess: (droneId, projectId, data) =>
                        Vue.http.post(`${serverUrl}/drone/${droneId}/project/${projectId}/process`, data),
                    putImages: (hashes) =>
                        Vue.http.put(`${serverUrl}/drone/images`, hashes),
                    putImage: (data) =>
                        Vue.http.put(`${serverUrl}/drone/image`, data),
                    createDji: (projectId, data) =>
                        Vue.http.post(`${serverUrl}/drone/project/${projectId}/djiprocess`, data),
                    updateName: (droneId, projectId, data) =>
                        Vue.http.put(`${serverUrl}/drone/rename/${droneId}/project/${projectId}`, data)
                }
            },
            djiCloud() {
                return {
                    getImages: () =>
                        Vue.http.get(`${serverUrl}/cloudapi/images`)
                }
            },
            weather() {
                return {
                    getWeather: (projectId, userId) =>
                        Vue.http.get(`${serverUrl}/weather/forecast/project/${projectId}/userId/${userId}`),
                    getHistoricalWeather: (projectId, userId, startDate, endDate, baseTemp) =>
                        Vue.http.get(`${serverUrl}/weather/past/project/${projectId}/userId/${userId}`, {
                            params: { startDate, endDate, baseTemp }
                        })
                }
            },
            register() {
                return {
                    sendConfirm: (userData) => Vue.http.post(`${serverUrl}/user/register`, userData),
                    createUser: (jwt) => Vue.http.post(`${serverUrl}/user/confirm`, jwt)
                }
            },
            subscription() {
                return {
                    getActive: () => Vue.http.get(`${serverUrl}/subscriptions/active`),
                    getMercadoPagoPreference: (packId, hectares) => Vue.http
                        .get(`${serverUrl}/subscriptions/mercadopago-preference?packId=${packId}&hectares=${hectares}`),
                    create: (params) => Vue.http.post(`${serverUrl}/subscriptions`, params),
                    update: (subscriptionId, params) =>
                        Vue.http.put(`${serverUrl}/subscriptions/${subscriptionId}`, params),
                    validate: (subscriptionId) => Vue.http.post(`${serverUrl}/subscriptions/${subscriptionId}/validate`)
                }
            },
            recoverPass() {
                return {
                    sendMailRecover: (params) => Vue.http.post(`${serverUrl}/user/password/recover`, params),
                    changePass: (params) => Vue.http.post(`${serverUrl}/user/password/change`, params)
                }
            },
            externalIntegration() {
                return {
                    getAll: (userId) => Vue.http.get(`${serverUrl}/user/${userId}/external`),
                    add: (userId, integrationName, params) =>
                        Vue.http.post(`${serverUrl}/user/${userId}/external/${integrationName}`, params),
                    delete: (userId, integrationName, integrationId) =>
                        Vue.http.delete(`${serverUrl}/user/${userId}/external/${integrationName}/${integrationId}`),
                    johnDeere() {
                        return {
                            authorize: () =>
                                Vue.http.get(`${serverUrl}/user/external/johndeere/authorization`),
                            files: () =>
                                Vue.http.get(`${serverUrl}/user/external/johndeere/files`)
                        }
                    },
                    caseIh() {
                        return {
                            authorize: () =>
                                Vue.http.get(`${serverUrl}/user/external/caseih/authorization`),
                            files: () =>
                                Vue.http.get(`${serverUrl}/user/external/caseih/files`)
                        }
                    }
                }
            },
            stations() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/stations`),
                    getForecast: (stationId, timePeriod, dataGroup) =>
                        // eslint-disable-next-line max-len
                        Vue.http.get(`${serverUrl}/stations/${stationId}/forecast?timePeriod=${timePeriod}&dataGroup=${dataGroup}`),
                    getHistorical: (stationId, startDate, endDate, dataGroup) =>
                        Vue.http.get(`${serverUrl}/stations/${stationId}/past`, {
                            params: { startDate, endDate, dataGroup }
                        }),
                    getAllByUser: (userId) =>
                        Vue.http.get(`${serverUrl}/stations/user/${userId}`),
                    getByStationId: (userId, stationId) =>
                        Vue.http.get(`${serverUrl}/stations/user/${userId}/station/${stationId}`)
                }
            },
            formTemplate() {
                return {
                    getAll: () => Vue.http.get(`${serverUrl}/form-template`),
                    getByProject: (projectId, userId) =>
                        Vue.http.get(`${serverUrl}/form-template/project/${projectId}/user/${userId}`),
                    create: (params) => Vue.http.post(`${serverUrl}/form-template`, params),
                    update: (formId, userId, params) =>
                        Vue.http.put(`${serverUrl}/form-template/${formId}/user/${userId}`, params),
                    delete: (formId, userId) => Vue.http.delete(`${serverUrl}/form-template/${formId}/user/${userId}`),
                    assignUsers: (formId, userId, { usersIds }) =>
                        Vue.http.put(`${serverUrl}/form-template/${formId}/users/user/${userId}`, { usersIds }),
                    assignProjects: (formId, userId, { projectsIds }) =>
                        Vue.http.put(`${serverUrl}/form-template/${formId}/projects/user/${userId}`, { projectsIds }),
                    assignCrops: (formId, userId, { crop }) =>
                        Vue.http.put(`${serverUrl}/form-template/${formId}/crops/user/${userId}`, { crop })
                }
            },
            formAnswer() {
                return {
                    getByProject: (projectId) => Vue.http.get(`${serverUrl}/form-answer/project/${projectId}`),
                    getById: (formId, projectId) =>
                        Vue.http.get(`${serverUrl}/form-answer/${formId}/project/${projectId}`),
                    create: (params) => Vue.http.post(`${serverUrl}/form-answer`, params),
                    update: (formId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/form-answer/${formId}/project/${projectId}`, params),
                    delete: (formId, projectId) =>
                        Vue.http.delete(`${serverUrl}/form-answer/${formId}/project/${projectId}`),
                    upload: (userId, projectId, data, options) =>
                        Vue.http.post(`${serverUrl}/form-answer/upload/${userId}/${projectId}`, data, options || {})
                }
            },
            task() {
                return {
                    getByProject: (projectId) => Vue.http.get(`${serverUrl}/task/project/${projectId}`),
                    getById: (taskId, projectId) => Vue.http.get(`${serverUrl}/task/${taskId}/project/${projectId}`),
                    getMonitoringByProject: (projectId, queryParams) => Vue.http
                        .get(`${serverUrl}/task/monitoring/project/${projectId}`, {params: {queryParams}}),
                    create: (params) => Vue.http.post(`${serverUrl}/task`, params),
                    createMonitoring: (params, projectId) =>
                        Vue.http.post(`${serverUrl}/task/new-monitoring/project/${projectId}`, params),
                    update: (taskId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/task/${taskId}/project/${projectId}`, params),
                    addForm: (taskId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/task/${taskId}/form/project/${projectId}`, params),
                    delete: (taskId, projectId, formsAnswerId) =>
                        Vue.http.delete(`${serverUrl}/task/${taskId}/project/${projectId}`, {params: {formsAnswerId}}),
                    deleteForm: (formId, projectId) =>
                        Vue.http.delete(`${serverUrl}/task/form/${formId}/project/${projectId}`),
                    upload: (userId, projectId, data, options) =>
                        Vue.http.post(`${serverUrl}/task/upload/${userId}/${projectId}`, data, options || {})
                }
            },
            crops() {
                return {
                    get: (searchText) => Vue.http.get(`${serverUrl}/wiki/crops?search=${searchText}`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/crops/${id}`)
                }
            },
            weeds() {
                return {
                    get: (searchText) => Vue.http.get(`${serverUrl}/wiki/weeds?search=${searchText}`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/weeds/${id}`)
                }
            },
            pests() {
                return {
                    get: (searchText) => Vue.http.get(`${serverUrl}/wiki/pests?search=${searchText}`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/pests/${id}`)
                }
            },
            diseases() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/wiki/diseases`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/diseases/${id}`)
                }
            },
            products() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/wiki/products`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/products/${id}`)
                }
            },
            equipment() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/wiki/equipment`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/equipment/${id}`)
                }
            },
            fertilizers() {
                return {
                    get: () => Vue.http.get(`${serverUrl}/wiki/fertilizers`),
                    getOne: (id) => Vue.http.get(`${serverUrl}/wiki/fertilizers/${id}`)
                }
            },
            download() {
                return {
                    url: `${serverUrl}/wiki/download`,
                    get: () => Vue.http.get(`${serverUrl}/wiki/download`)
                }
            },
            laboratory() {
                return {
                    getFromProject: projectId =>
                        Vue.http.get(`${serverUrl}/laboratory-analysis/project/${projectId}`),
                    getById: (id, projectId) =>
                        Vue.http.get(`${serverUrl}/laboratory-analysis/${id}/project/${projectId}`),
                    create: analysisData =>
                        Vue.http.post(`${serverUrl}/laboratory-analysis`, analysisData),
                    update: (id, projectId, analysisData) =>
                        Vue.http.put(`${serverUrl}/laboratory-analysis/${id}/project/${projectId}`, analysisData),
                    updateSample: (id, projectId, sampleId) =>
                        Vue.http.put(`${serverUrl}/laboratory-analysis/${id}/project/${projectId}/sample/${sampleId}`),
                    updateMeasure: (id, projectId, sampleId, data) =>
                        Vue.http.put(
                            `${serverUrl}/laboratory-analysis/${id}/project/${projectId}/measure/${sampleId}`,
                            data
                        ),
                    delete: (id, projectId) =>
                        Vue.http.delete(`${serverUrl}/laboratory-analysis/${id}/project/${projectId}`),
                    upload: (userId, projectId, data, options) => {
                        const uploadUrl = `${serverUrl}/laboratory-analysis/upload/${userId}/${projectId}`
                        return Vue.http.post(uploadUrl, data, options || {})
                    }
                }
            },
            fertilization() {
                return {
                    getFromProject: projectId =>
                        Vue.http.get(`${serverUrl}/fertilization-plan/project/${projectId}`),
                    getById: (id, projectId) =>
                        Vue.http.get(`${serverUrl}/fertilization-plan/${id}/project/${projectId}`),
                    create: data =>
                        Vue.http.post(`${serverUrl}/fertilization-plan`, data),
                    update: (id, projectId, data) =>
                        Vue.http.put(`${serverUrl}/fertilization-plan/${id}/project/${projectId}`, data),
                    delete: (id, projectId) =>
                        Vue.http.delete(`${serverUrl}/fertilization-plan/${id}/project/${projectId}`)
                }
            },
            imageAnalysis() {
                return {
                    getByProject: (projectId) => Vue.http.get(`${serverUrl}/image-analysis/project/${projectId}`),
                    getById: (analysisId, projectId) =>
                        Vue.http.get(`${serverUrl}/image-analysis/${analysisId}/project/${projectId}`),
                    getByAnalysisType: (projectId, analysisType) =>
                        Vue.http.get(`${serverUrl}/image-analysis/project/${projectId}/analysisType/${analysisType}`),
                    create: (params) => Vue.http.post(`${serverUrl}/image-analysis`, params),
                    update:  (analysisId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/image-analysis/${analysisId}/project/${projectId}`, params),
                    delete: (analysisId, projectId) =>
                        Vue.http.delete(`${serverUrl}/image-analysis/${analysisId}/project/${projectId}`),
                    upload: (userId, projectId, data, options) =>
                        Vue.http.post(`${serverUrl}/image-analysis/upload/${userId}/${projectId}`, data, options || {}),
                    updateName: (analysisId, projectId, data) =>
                        Vue.http.put(`${serverUrl}/image-analysis/rename/${analysisId}/project/${projectId}`, data)
                }
            },
            analytics() {
                return {
                    embedurl: () => Vue.http.get(`${serverUrl}/qs/embedurl`),
                    dashboardsList: () => Vue.http.get(`${serverUrl}/analytics/dashboards`),
                    qsEmbedUrl: (id) => Vue.http.get(`${serverUrl}/analytics/qs/embedurl/${id}`),
                    gqsEmbedUrl: (id) => Vue.http.get(`${serverUrl}/analytics/gqs/embedurl/${id}`),
                    gqstEmbedUrl: (id) => Vue.http.get(`${serverUrl}/analytics/gqst/embedurl/${id}`),
                    qsAuthorEmbedUrl: (id) => Vue.http.get(`${serverUrl}/analytics/qsauthor/embedurl/${id}`),
                    ingestions: (data) => Vue.http.put(`${serverUrl}/analytics/ingestions`, data)
                }
            },
            subProjects() {
                return {
                    getFromProject: (projectId) => Vue.http.get(`${serverUrl}/subproject/project/${projectId}`),
                    getById: (subProjectId, projectId) =>
                        Vue.http.get(`${serverUrl}/subproject/${subProjectId}/project/${projectId}`),
                    create: (subProjectData) => Vue.http.post(`${serverUrl}/subproject`, subProjectData),
                    update: (subProjectId, projectId, subProjectData) =>
                        Vue.http.put(`${serverUrl}/subproject/${subProjectId}/project/${projectId}`, subProjectData),
                    delete: (subProjectId, projectId) =>
                        Vue.http.delete(`${serverUrl}/subproject/${subProjectId}/project/${projectId}`)
                }
            },
            admin() {
                return {
                    getUsers: () => Vue.http.get(`${serverUrl}/admin/users`),
                    getProjectsByUser: (userId) => Vue.http.get(`${serverUrl}/admin/user/${userId}/projects`),
                    getPacksByUser: (userId) => Vue.http.get(`${serverUrl}/admin/user/${userId}/packs`),
                    presign: (id) => Vue.http.get(`${serverUrl}/user/${id}/presign`),
                    assignStationToProject: (userId, data) =>
                        Vue.http.put(`${serverUrl}/admin/assign-station/user/${userId}`, data),
                    createUser: (user) => Vue.http.post(`${serverUrl}/admin/create-user`, user)
                }
            },
            irrigation() {
                return {
                    getByProject: (projectId) => Vue.http.get(`${serverUrl}/irrigation/project/${projectId}`),
                    getById: (irrigationId, projectId) =>
                        Vue.http.get(`${serverUrl}/irrigation/${irrigationId}/project/${projectId}`),
                    getStatus: (irrigationId, projectId) =>
                        Vue.http.get(`${serverUrl}/irrigation/status/${irrigationId}/project/${projectId}`),
                    getForecastStation: (irrigationId, stationId, userId, projectId) =>
                        // eslint-disable-next-line max-len
                        Vue.http.get(`${serverUrl}/irrigation/forecast/${irrigationId}/${stationId}/user/${userId}/project/${projectId}`),
                    create: (params) => Vue.http.post(`${serverUrl}/irrigation`, params),
                    update:  (irrigationId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/irrigation/${irrigationId}/project/${projectId}`, params),
                    delete: (irrigationId, projectId) =>
                        Vue.http.delete(`${serverUrl}/irrigation/${irrigationId}/project/${projectId}`),
                    updateUserInput:  (irrigationId, projectId, params) =>
                        Vue.http.put(`${serverUrl}/irrigation/${irrigationId}/project/${projectId}/user-input`, params)
                }
            },
            irrigationMetrics() {
                return {
                    getByAll: (irrigationAnalysisId) =>
                        Vue.http.get(`${serverUrl}/irrigation-metrics/irrigationAnalysisId/${irrigationAnalysisId}`),
                    getBetweenPeriod: (irrigationAnalysisId, from, to) =>
                        Vue.http.get(`${serverUrl}/irrigation-metrics/irrigationAnalysisId/
                            ${irrigationAnalysisId}/${from}/${to}`),
                    create: (params) => Vue.http.post(`${serverUrl}/irrigation-metrics`, params),
                    update:  (irrigationMetricId, irrigationAnalysisId, params) =>
                        Vue.http.put(`${serverUrl}/irrigation-metrics/
                            ${irrigationMetricId}/irrigationAnalysisId/${irrigationAnalysisId}`, params),
                    delete: (irrigationMetricId, irrigationAnalysisId) =>
                        Vue.http.delete(`${serverUrl}/irrigation-metrics/
                            ${irrigationMetricId}/irrigationAnalysisId/${irrigationAnalysisId}`)
                }
            },
            constants() {
                return {
                    getCrops: () => Vue.http.get(`${serverUrl}/constants/crops`),
                    getIrrigation: () => Vue.http.get(`${serverUrl}/constants/irrigation`)
                }
            },
            imageAnalysisCluster() {
                return {
                    getFromProject: projectId =>
                        Vue.http.get(`${serverUrl}/image-analysis-cluster/project/${projectId}`),
                    getById: (imageAnalysisClusterId, projectId) => Vue.http
                        .get(`${serverUrl}/image-analysis-cluster/${imageAnalysisClusterId}/project/${projectId}`),
                    create: analysisData =>
                        Vue.http.post(`${serverUrl}/image-analysis-cluster`, analysisData),
                    update: (id, projectId, analysisData) =>
                        Vue.http.put(`${serverUrl}/image-analysis-cluster/${id}/project/${projectId}`, analysisData),
                    delete: (id, projectId) =>
                        Vue.http.delete(`${serverUrl}/image-analysis-cluster/${id}/project/${projectId}`)
                }
            },
            yieldEstimation() {
                return {
                    getFromProject: projectId =>
                        Vue.http.get(`${serverUrl}/yield-estimation/project/${projectId}`),
                    getById: (id, projectId) =>
                        Vue.http.get(`${serverUrl}/yield-estimation/${id}/project/${projectId}`),
                    getStatus: (id, projectId) =>
                        Vue.http.get(`${serverUrl}/yield-estimation/status/${id}/project/${projectId}`),
                    create: yieldEstimationData =>
                        Vue.http.post(`${serverUrl}/yield-estimation`, yieldEstimationData),
                    update: (id, projectId, yieldEstimationData) =>
                        Vue.http.put(`${serverUrl}/yield-estimation/${id}/project/${projectId}`, yieldEstimationData),
                    delete: (id, projectId) =>
                        Vue.http.delete(`${serverUrl}/yield-estimation/${id}/project/${projectId}`),
                    addSample: (id, projectId, sample) =>
                        Vue.http.put(`${serverUrl}/yield-estimation/add-sample/${id}/project/${projectId}`, sample),
                    updateSample: (id, projectId, sample) =>
                        Vue.http.put(`${serverUrl}/yield-estimation/update-sample/${id}/project/${projectId}`, sample),
                    deleteSample: (id, projectId, sampleId) =>
                        Vue.http
                            .delete(
                                `${serverUrl}/yield-estimation/delete-sample/${id}/project/${projectId}/${sampleId}`
                            ),
                    startYieldEstimation: (id, projectId) =>
                        Vue.http
                            .post(`${serverUrl}/yield-estimation/start-yield-estimation/${id}/project/${projectId}`),
                    upload: (userId, projectId, data, options) => {
                        const uploadUrl = `${serverUrl}/yield-estimation/upload/${userId}/${projectId}`
                        return Vue.http.post(uploadUrl, data, options || {})
                    }
                }
            }
        }
        Vue.prototype.$api = api
    }
}