const state = {
    users: [],
    packs: [],
    projects: {},
    selectedUser: {},
    editUser: false
}

// getters
const getters = {
    users: (state) => state.users,
    projects: (state) => state.projects,
    selectedUser: (state) => state.selectedUser,
    editUser: (state) => state.editUser,
    packs: (state) => state.packs
}

// actions
const actions = {
    async loadUsers({ commit }, { vm }) {
        const response = await vm.$api.admin().getUsers()
        commit('setUsers', response.body.data)
        return response.body.data
    },
    async loadUserProjects({ commit }, { vm, userId }) {
        const response = await vm.$api.admin().getProjectsByUser(userId)
        commit('setUserProjects', { userId, projects: response.body.data })
        return response.body.data
    },
    async loadUserPacks({ commit, rootGetters }, { vm }) {
        const response = await vm.$api.admin().getPacksByUser(rootGetters['user/data']._id)
        commit('setUserPacks', response.body.data )
        return response.body.data
    },
    async createUser({ commit }, { vm, user }) {
        const response = await vm.$api.admin().createUser(user)
        commit('addUser', response.body.data )
        return response.body.message
    }
}

// mutations
const mutations = {
    setUsers(state, users) {
        state.users = users
    },
    setUserProjects(state, { userId, projects }) {
        state.projects = { ...state.projects, [userId]: projects }
    },
    setSelectedUser(state, user) {
        state.selectedUser = user
    },
    setEditUser(state, editUser) {
        state.editUser = editUser
    },
    setUserPacks(state, packs) {
        state.packs = packs
    },
    addUser(state, user) {
        state.users.unshift(user)
    },
    clearData(state) {
        state.selectedUser = {}
        state.editUser = false,
        state.packs = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
