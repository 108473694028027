const state = {
    selected: {},
    isOpen: false,
    sections: [
        {
            label: 'account',
            name: 'Profile',
            icon: 'aicon-cuenta',
            cat: 'profile',
            show: true
        },
        {
            label: 'projects',
            name: 'Projects',
            icon: 'aicon-campos',
            cat: 'app',
            show: true,
            offline: true
        },
        {
            label: 'globalWeather',
            name: 'GlobalWeather',
            icon: 'aicon-clima-global',
            cat: 'app',
            show: false
        },
        {
            label: 'help',
            name: 'https://help.agrodreams.com',
            icon: 'help_outline',
            cat: 'help',
            offline: false,
            show: true
        },
        {
            label: 'analytics',
            name: 'Analytics',
            icon: 'aicon-grafico',
            cat: 'app',
            offline: false,
            show: true
        },
        {
            label: 'knowledgeBase',
            name: 'KnowledgeBase',
            icon: 'aicon-info-proyecto',
            cat: 'app',
            offline: true,
            show: true
        },
        {
            label: 'teams',
            name: 'Teams',
            icon: 'aicon-equipos',
            cat: 'app',
            offline: false,
            show: false
        },
        {
            label: 'admin',
            name: 'AdminTemplates',
            icon: 'aicon-configuracion',
            cat: 'app',
            offline: false,
            show: true
        }
    ]
}

// getters
const getters = {
    selected: (state) => state.selected,
    sections: (state) => state.sections,
    isOpen: (state) => state.isOpen
}

// actions
const actions = {}

// mutations
const mutations = {
    changeSelected(state, selected) {
        state.selected = selected
    },
    clearSelected(state) {
        state.selected = {}
    },
    setIsOpen(state, value) {
        state.isOpen = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
