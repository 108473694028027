const state = {
    selected: {},
    editing: {},
    availables: [],
    visibility: false,
    layer: {},
    plotsLayer: {},
    plotsColors: {},
    plotsValues: {},
    irrigationLayer: {},
    irrigationColors: {},
    plantCoverVisibility: false,
    plantRowsVisibility: false,
    saveStatus: false
}

// getters
const getters = {
    selected: (state) => state.selected,
    editing: (state) => state.editing,
    availables: (state) => state.availables,
    projectAvailables: (state, _, rootState) => state.availables
        .filter((subProject) => subProject.projectId === rootState.projects.selectedProject._id),
    visibility: (state) => state.visibility,
    variability: (state) => state.variability,
    layer: (state) => state.layer,
    plotsLayer: (state) => state.plotsLayer,
    plotsColors: (state) => state.plotsColors,
    plotsValues: (state) => state.plotsValues,
    irrigationLayer: (state) => state.irrigationLayer,
    irrigationColors: (state) => state.irrigationColors,
    selectedAnalysis: (state, _, rootState) => rootState.imageAnalysis.results.find(
        (analysis) => analysis.subProjectId === state.selected._id && analysis.status === 'completed'
    ),
    plantCoverVisibility: (state) => state.plantCoverVisibility,
    plantRowsVisibility: (state) => state.plantRowsVisibility,
    saveStatus: (state) => state.saveStatus
}

// actions
const actions = {
    async loadSubProjects({ commit, rootGetters }, { vm }) {
        if (!rootGetters['offline/active']) {
            const response = await vm.$api.subProjects()
                .getFromProject(rootGetters['projects/selectedProject']._id)
            commit('setProjectSubProjects', response.body.data)
        }
    },
    async loadSubProjectById({ commit, rootGetters }, { vm, subProjectId }) {
        if (rootGetters['offline/active']) {
            const subProject = rootGetters['subProjects/availables']
                .find((item) => item._id === subProjectId)
            commit('setSelected', subProject)
            return subProject
        }
        const response = await vm.$api.subProjects()
            .getById(subProjectId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', response.body.data)
        return response.body.data
    },
    async create({ commit }, { vm, subProject }) {
        const response = await vm.$api.subProjects().create(subProject)
        commit('addSubProject', response.body.data)
        if (response.body.queue) {
            const pendingProcess = { _id: response.body.queue, progress: 0 }
            commit('projects/setPendingProcess', pendingProcess, { root: true })
        }
        amplitude.getInstance().logEvent('SUBPROJECT_CREATE')
        return response.body.data
    },
    async update({ commit, getters, rootGetters }, { vm, subProject }) {
        const response = await vm.$api.subProjects()
            .update(subProject._id, rootGetters['projects/selectedProject']._id, subProject)
        commit('updateSubProject', response.body.data)
        if (response.body.data._id == getters.selected._id) {
            commit('setSelected', response.body.data)
        }
        if (response.body.queue) {
            const pendingProcess = { _id: response.body.queue, progress: 0 }
            commit('projects/setPendingProcess', pendingProcess, { root: true })
        }
        amplitude.getInstance().logEvent('SUBPROJECT_UPDATE')
        return response.body.data
    },
    async delete({ commit, rootGetters }, { vm, subProject }) {
        const response = await vm.$api.subProjects()
            .delete(subProject._id, rootGetters['projects/selectedProject']._id)
        commit('deleteSubProject', response.body.data)
        amplitude.getInstance().logEvent('SUBPROJECT_DELETE')
        return response.body.data
    }
}

// mutations
const mutations = {
    setSelected(state, subProject) {
        state.selected = subProject
        state.selected.info = state.selected.info || {}
    },
    setEditing(state, subProject) {
        state.editing = subProject
        state.editing.info = state.editing.info || {}
    },
    addSubProject(state, subProject) {
        state.availables.unshift(subProject)
    },
    updateSubProject(state, subProject) {
        state.availables = state.availables
            .map((item) => (item._id == subProject._id ? subProject : item))
    },
    deleteSubProject(state, subProject) {
        const subProjectIndex = state.availables
            .findIndex((item) => item._id === subProject._id)
        state.availables.splice(subProjectIndex, 1)
    },
    setSubProjects(state, subProjects) {
        state.availables = subProjects
    },
    setProjectSubProjects(state, subProjects) {
        const projectId = subProjects.length > 0 ? subProjects[0].projectId : ''
        state.availables = subProjects
            .concat(state.availables.filter((subProject) => subProject.projectId !== projectId))
    },
    setVisibility(state, status) {
        state.visibility = status
    },
    setVariability(state, status) {
        state.variability = status
    },
    setLayer(state, layer) {
        state.layer = layer
    },
    setPlotsLayer(state, layer) {
        state.plotsLayer = layer
    },
    setPlotsColors(state, colors) {
        state.plotsColors = colors
    },
    setPlotsValues(state, values) {
        state.plotsValues = values
    },
    setIrrigationLayer(state, layer) {
        state.irrigationLayer = layer
    },
    setIrrigationColors(state, colors) {
        state.irrigationColors = colors
    },
    setPlantCoverVisibility(state, status) {
        state.plantCoverVisibility = status
    },
    setPlantRowsVisibility(state, status) {
        state.plantRowsVisibility = status
    },
    setSaveStatus(state, status) {
        state.saveStatus = status
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.availables = []
        }
        state.selected = {}
        state.editing = {}
        state.visibility = false
        state.layer = {}
        state.variability = false
        state.plotsColors = {}
        state.plotsValues = {}
        state.irrigationColors = {}
        state.plantCoverVisibility = false
        state.plantRowsVisibility = false
        state.saveStatus = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
