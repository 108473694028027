const state = {
    active: {},
    compare: '',
    compareType: '',
    availableSatelliteDates: [],
    availableDroneImages: [],
    cloudyDates: [],
    mapGroupA: undefined,
    mapGroupB: undefined,
    mapA: undefined,
    mapB: undefined,
    selectedDateA: '',
    selectedDateB: '',
    stageA: {},
    stageB: {},
    providerA: 'satelite',
    providerB: 'satelite',
    scoutingA: true,
    scoutingB: true,
    activeModel: '',
    loadingMap: false,
    posCompareSlide: null,
    indiceA: {},
    indiceB: {},
    indiceFixedA: {},
    indiceFixedB: {},
    sameMap: false,
    filterColorsA: [],
    filterColorsB: [],
    filterReset: '',
    fixedIndiceA: false,
    fixedIndiceB: false,
    resetSides: false,
    terrestrialMapA: {},
    terrestrialMapB: {},
    droneImageA: {},
    droneImageB: {},
    droneProcessA: {},
    droneProcessB: {}
}

// getters
const getters = {
    active: (state) => state.active,
    activeModel: (state) => state.activeModel,
    compare: (state) => state.compare,
    compareType: (state) => state.compareType,
    availableSatelliteDates: (state, _, rootState) => state.availableSatelliteDates.filter((satellite) => {
        // const subProjectId = rootState.subProjects.selected._id || 'default'
        const subProjectId = rootState.subProjects.selected._id
        const oldVersionProject = !rootState.projects.selectedProject.version ||
            rootState.projects.selectedProject.version < 2
        return (subProjectId || oldVersionProject) &&
            (!satellite.subProjectId || satellite.subProjectId === subProjectId)
    }),
    availableDroneImages: (state, _, rootState) => state.availableDroneImages.filter((drone) => {
        // const subProjectId = rootState.subProjects.selected._id || 'default'
        const subProjectId = rootState.subProjects.selected._id
        const oldVersionProject = !rootState.projects.selectedProject.version ||
            rootState.projects.selectedProject.version < 2
        return (rootState.user.data.role === 'agroAdmin' || drone.status === 'completed') &&
            (subProjectId || oldVersionProject) &&
            (!drone.subProjectsIds || drone.subProjectsIds.includes(subProjectId) ||
            drone.subProjectsIds.includes('default'))
    }),
    cloudyDates: (state) => state.cloudyDates,
    availableMaps: (_, getters, rootState) => {
        const allMaps = rootState.user.pack.permission.maps
        return [
            {
                label: 'none',
                name: 'none',
                drone: true,
                satelite: true,
                terrestrial: true
            }
        ].concat(allMaps.filter((group) => group[getters.provider]))
    },
    mapGroup: (state, getters) =>
        state.compare !== 'b'
            ? state.mapGroupA ||
              getters.availableMaps.find((map) => map.name == 'agroIndex' && map[getters.provider]) ||
              getters.availableMaps[0]
            : state.mapGroupB || getters.availableMaps[0],
    map: (state, getters) =>
        state.compare !== 'b'
            ? state.mapA || (getters.mapGroup.options ? getters.mapGroup.options[0] : getters.mapGroup)
            : state.mapB || (getters.mapGroup.options ? getters.mapGroup.options[0] : getters.mapGroup),
    selectedDate: (state) => (state.compare !== 'b' ? state.selectedDateA : state.selectedDateB || []),
    selectedDateA: (state) => state.selectedDateA,
    selectedDateB: (state) => state.selectedDateB,
    stage: (state) => (state.compare !== 'b' ? state.stageA : state.stageB),
    provider: (state) => (state.compare !== 'b' ? state.providerA : state.providerB),
    scouting: (state) => (state.compare !== 'b' ? state.scoutingA : state.scoutingB),
    loadingMap: (state) => state.loadingMap,
    posCompareSlide: (state) => state.posCompareSlide,
    mapGroupA: (state) => state.mapGroupA,
    mapGroupB: (state) => state.mapGroupB,
    mapA: (state) => state.mapA,
    mapB: (state) => state.mapB,
    indices: (state) => (state.compare !== 'b' ? state.indiceA : state.indiceB),
    indiceFixed: (state) => (state.compare !== 'b' ? state.indiceFixedA : state.indiceFixedB),
    sameMap: (state) => state.sameMap,
    resetSides: (state) => state.resetSides,
    filterColors: (state) => (state.compare !== 'b' ? state.filterColorsA : state.filterColorsB),
    filterColorsA: (state) => state.filterColorsA,
    filterColorsB: (state) => state.filterColorsB,
    filterReset: (state) => state.filterReset,
    fixedIndice: (state) => (state.compare !== 'b' ? state.fixedIndiceA : state.fixedIndiceB),
    terrestrialMap: (state) => (state.compare !== 'b' ? state.terrestrialMapA : state.terrestrialMapB),
    droneImage: (state) => (state.compare !== 'b' ? state.droneImageA : state.droneImageB),
    droneProcess: (state) => (state.compare !== 'b' ? state.droneProcessA : state.droneProcessB)
}

// actions
const actions = {
    async getAvailableSatelliteDates({ commit, rootGetters }, { vm }) {
        const response = await vm.$api.dates().getFromProject(rootGetters['projects/selectedProject']._id)
        commit('setAvailableSatelliteDates', response.body.data)
        return response.body.data
    },
    async getAvailableDroneImages({ commit, rootGetters }, { vm }) {
        const response = await vm.$api.drone().getFromProject(rootGetters['projects/selectedProject']._id)
        commit('setAvailableDroneImages', response.body.data)
        return response.body.data
    }
}

// mutations
const mutations = {
    setActive(state, active) {
        const layer = active.layer ? { _url: active.layer._url, options: active.layer.options } : undefined
        state.active = { ...active, layer }
    },
    setActiveModel(state, activeModel) {
        state.activeModel = activeModel
    },
    setCompare(state, compare) {
        state.compare = compare
        if (!compare) {
            state.filterColorsA = []
            state.filterColorsB = []
        }
    },
    setCompareType(state, type) {
        state.compareType = type
    },
    setCompareSlide(state, value) {
        state.posCompareSlide = value
    },
    setAvailableSatelliteDates(state, availableDates) {
        state.availableSatelliteDates = availableDates.filter(({ cloudy }) => !cloudy) || {}
        state.cloudyDates = availableDates.filter(({ cloudy }) => cloudy) || {}
    },
    setAvailableDroneImages(state, availableMaps) {
        state.availableDroneImages = availableMaps || []
        state.availableDroneImages.sort((a, b) => b.createdAt - a.createdAt)
    },
    setMapGroup(state, mapGroup) {
        if (state.compare !== 'b') {
            state.mapGroupA = mapGroup
            if (mapGroup?.options && mapGroup.options.length) {
                state.mapA =
                    mapGroup.options.find((option) => state.mapA && option.name === state.mapA.name) ||
                    mapGroup.options[0]
            } else {
                state.mapA = mapGroup
            }
        } else {
            state.mapGroupB = mapGroup
            if (mapGroup?.options && mapGroup.options.length) {
                state.mapB =
                    mapGroup.options.find((option) => state.mapB && option.name === state.mapB.name) ||
                    mapGroup.options[0]
            } else {
                state.mapB = mapGroup
            }
        }
    },
    setMap(state, map) {
        if (state.compare !== 'b') {
            state.mapA = map
            amplitude.getInstance().logEvent('MAP_CHANGE', map)
        } else {
            state.mapB = map
        }
    },
    setCompareMapB(state) {
        state.mapGroupB = state.mapGroupA
        state.mapB = state.mapA
        state.availableSatelliteDates.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        const indexDate = state.availableSatelliteDates.indexOf(state.selectedDateA)
        state.selectedDateB =
            state.availableSatelliteDates[indexDate - 1] || state.availableSatelliteDates[indexDate + 1]
    },
    setSelectedDate(state, date) {
        if (state.compare !== 'b') {
            state.selectedDateA = date
            if (state.selectedDateB == '' || !state.selectedDateB) {
                state.selectedDateB = date
            }
        } else {
            state.selectedDateB = date
        }
    },
    setSelectedDateA(state, date) {
        state.selectedDateA = date
    },
    setSelectedDateB(state, date) {
        state.selectedDateB = date
    },
    setStage(state, stage) {
        if (state.compare !== 'b') {
            state.stageA = stage
        } else {
            state.stageB = stage
        }
    },
    setProvider(state, provider) {
        if (state.compare !== 'b') {
            state.providerA = provider
        } else {
            state.providerB = provider
        }
    },
    setScouting(state, scouting) {
        if (state.sameMap) {
            state.scoutingA = scouting
            state.scoutingB = scouting
        } else if (state.compare !== 'b') {
            state.scoutingA = scouting
        } else {
            state.scoutingB = scouting
        }
    },
    setFixedIndice(state, fixedIndice) {
        if (state.sameMap) {
            state.fixedIndiceA = fixedIndice
            state.fixedIndiceB = fixedIndice
        } else if (state.compare !== 'b') {
            state.fixedIndiceA = fixedIndice
        } else {
            state.fixedIndiceB = fixedIndice
        }
    },
    setLoadingMap(state, value) {
        state.loadingMap = value
    },
    setIndice(state, indice) {
        if (state.compare !== 'b') {
            state.indiceA = indice
        } else {
            state.indiceB = indice
        }
    },
    setIndiceFixed(state, values) {
        if (state.compare !== 'b') {
            state.indiceFixedA = values
        } else {
            state.indiceFixedB = values
        }
    },
    setResetSides(state, value) {
        state.resetSides = value
    },
    setSameMap(state, value) {
        state.sameMap = value
        if (value && state.compare == 'b') {
            state.scoutingB = state.scoutingA
            state.fixedIndiceB = state.fixedIndiceA
            state.indiceFixedB = state.indiceFixedA
        } else if (value) {
            state.scoutingA = state.scoutingB
            state.fixedIndiceA = state.fixedIndiceB
            state.indiceFixedA = state.indiceFixedB
        }
    },
    setFilterReset(state, value) {
        state.filterReset = value
    },
    setFilterColors(state, colors) {
        if (state.sameMap) {
            state.filterColorsA = colors
            state.filterColorsB = colors
        } else if (state.compare !== 'b') {
            state.filterColorsA = colors
        } else {
            state.filterColorsB = colors
        }
    },
    setTerrestrialMap(state, map) {
        if (state.compare !== 'b') {
            state.terrestrialMapA = map
        } else {
            state.terrestrialMapB = map
        }
    },
    setDroneImage(state, map) {
        if (state.compare !== 'b') {
            state.droneImageA = map
        } else {
            state.droneImageB = map
        }
    },
    setDroneProcess(state, process) {
        if (state.compare !== 'b') {
            state.droneProcessA = process
        } else {
            state.droneProcessB = process
        }
    },
    clearMapB(state) {
        state.mapGroupB = undefined
        state.mapB = undefined
        state.selectedDateB = ''
        state.stageB = {}
        state.providerB = 'satelite'
        state.indiceB = {}
        state.indiceFixedB = {}
        state.filterColorsB = []
        state.fixedIndiceB = false
        state.terrestrialMapB = false
        state.droneImageB = {}
        state.droneProcessB = {}
    },
    clearData(state) {
        state.active = {}
        state.compare = ''
        state.compareType = ''
        state.availableSatelliteDates = []
        state.availableDroneImages = []
        state.cloudyDates = []
        state.mapGroupA = undefined
        state.mapGroupB = undefined
        state.mapA = undefined
        state.mapB = undefined
        state.selectedDateA = ''
        state.selectedDateB = ''
        state.stageA = {}
        state.stageB = {}
        state.providerA = 'satelite'
        state.providerB = 'satelite'
        state.scoutingA = true
        state.scoutingB = true
        state.activeModel = ''
        state.loadingMap = false
        state.indiceA = {}
        state.indiceB = {}
        state.indiceFixedA = {}
        state.indiceFixedB = {}
        state.sameMap = false
        state.filterColorsA = []
        state.filterColorsB = []
        state.filterReset = ''
        state.fixedIndiceA = false
        state.fixedIndiceB = false
        state.resetSides = false
        state.terrestrialMapA = {}
        state.terrestrialMapB = {}
        state.droneImageA = {}
        state.droneImageB = {}
        state.droneProcessA = {}
        state.droneProcessB = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
