const state = {
    flying: {},
    selected: {},
    flights: [],
    flightMode: {},
    showFlight: false,
    readyToFly: false
}

// getters
const getters = {
    flying: (state) => state.flying,
    selected: (state) => state.selected,
    flights: (state) => state.flights,
    projectFlights: (state, _, rootState) => state.flights
        .filter((flight) => flight.projectId === rootState.projects.selectedProject._id),
    flightMode: (state) => state.flightMode,
    showFlight: (state) => state.showFlight,
    readyToFly: (state) => state.readyToFly
}

// actions
const actions = {
    async loadFlights({ commit, getters, rootGetters }, { vm }) {
        if (rootGetters['offline/active']) {
            return getters.projectFlights
        }
        const responseFlights = await vm.$api.flight()
            .getFromProject(rootGetters['projects/selectedProject']._id)
        commit('setProjectFlights', responseFlights.body.data)
    },
    async create({ commit }, { vm, flight }) {
        const createResponse = await vm.$api.flight().create(flight)
        commit('addFlight', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        amplitude.getInstance().logEvent('FLIGHT_CREATE', {
            mode: createResponse.body.data.options.mode
        })
        return createResponse.body.message
    },
    async update({ commit, rootGetters }, { vm, flight }) {
        const updateResponse = await vm.$api.flight()
            .update(flight._id, rootGetters['projects/selectedProject']._id, flight)
        commit('updateFlight', updateResponse.body.data)
        return updateResponse.body.message
    },
    async delete({ commit, rootGetters }, { vm, flight }) {
        const deleteResponse = await vm.$api.flight()
            .delete(flight._id, rootGetters['projects/selectedProject']._id)
        commit('deleteFlight', deleteResponse.body.data)
        return deleteResponse.body.message
    }
}

// mutations
const mutations = {
    setFlying(state, flight) {
        state.flying = flight
    },
    setSelected(state, flight) {
        state.selected = flight
    },
    addFlight(state, flight) {
        state.flights.unshift(flight)
    },
    updateFlight(state, flight) {
        state.flights = state.flights.map((item) => (item._id == flight._id ? flight : item))
    },
    deleteFlight(state, flight) {
        const flightIndex = state.flights.findIndex((item) => item._id === flight._id)
        state.flights.splice(flightIndex, 1)
    },
    setFlights(state, flights) {
        state.flights = flights
    },
    setProjectFlights(state, flights) {
        const projectId = flights.length > 0 ? flights[0].projectId : ''
        state.flights = flights.concat(state.flights.filter((flight) => flight.projectId !== projectId))
    },
    setFlightMode(state, flightMode) {
        state.flightMode = flightMode
    },
    setShowFlight(state, showFlight) {
        state.showFlight = showFlight
    },
    setReadyToFly(state, value) {
        state.readyToFly = value
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.flights = []
            state.flying = {}
        }
        state.selected = {}
        state.flightMode = {}
        state.readyToFly = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
