const state = {
    selected: {},
    currentPage: 1,
    prescriptions: [],
    doseError: false,
    editPrescription: false,
    pointsGeojson: {},
    pointsLayer: {}
}

// getters
const getters = {
    selected: (state) => state.selected,
    currentPage: (state) => state.currentPage,
    prescriptions: (state) => state.prescriptions,
    doseError: (state) => state.doseError,
    editPrescription: (state) => state.editPrescription,
    pointsGeojson: (state) => state.pointsGeojson,
    pointsLayer: (state) => state.pointsLayer
}

// actions
const actions = {
    async loadPrescriptions({ commit, rootGetters }, { vm, modes }) {
        const responsePrescriptions = await vm.$api
            .prescription()
            .getFromProject(rootGetters['projects/selectedProject']._id, modes)
        const prescriptions = responsePrescriptions.body.data
        commit('setPrescriptions', prescriptions)
        return prescriptions
    },
    async loadPrescriptionById({ commit, rootGetters }, { vm, prescriptionId }) {
        const responsePrescriptions = await vm.$api
            .prescription()
            .getById(prescriptionId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', responsePrescriptions.body.data)
        return responsePrescriptions.body.data
    },
    async create({ commit }, { vm, prescriptionToSave }) {
        let createResponse
        if (prescriptionToSave.geojson) {
            const geojson = JSON.stringify(prescriptionToSave.geojson)
            const geojsonBlob = new Blob([geojson], { type: 'application/json' })
            const formData = new FormData()
            formData.append('file', geojsonBlob, 'points.geojson')
            formData.append('data', JSON.stringify({
                ...prescriptionToSave,
                geojson: undefined
            }))
            createResponse = await vm.$api.prescription()
                .createGeojson(prescriptionToSave.projectId, formData)
        } else {
            createResponse = await vm.$api.prescription().create(prescriptionToSave)
        }
        commit('addPrescription', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        const type = createResponse.body.data.mode
        amplitude.getInstance().logEvent('PRESCRIPTION_CREATE', { type })
        return createResponse.body.message
    },
    async update({ commit }, { vm, prescriptionToSave }) {
        let updateResponse
        if (prescriptionToSave.geojson) {
            const geojson = JSON.stringify(prescriptionToSave.geojson)
            const geojsonBlob = new Blob([geojson], { type: 'application/json' })
            const formData = new FormData()
            formData.append('file', geojsonBlob, 'points.geojson')
            formData.append('data', JSON.stringify({
                ...prescriptionToSave,
                geojson: undefined
            }))
            updateResponse = await vm.$api.prescription()
                .updateGeojson(prescriptionToSave._id, prescriptionToSave.projectId, formData)
        } else {
            updateResponse = await vm.$api.prescription()
                .update(prescriptionToSave._id, prescriptionToSave.projectId, prescriptionToSave)
        }
        commit('updatePrescription', updateResponse.body.data)
        commit('setSelected', updateResponse.body.data)
        const type = updateResponse.body.data.mode
        amplitude.getInstance().logEvent('PRESCRIPTION_UPDATE', { type })
        return updateResponse.body.message
    },
    async delete({ commit, rootGetters }, { vm, prescription }) {
        const deleteResponse = await vm.$api
            .prescription()
            .delete(prescription._id, rootGetters['projects/selectedProject']._id)
        commit('deletePrescription', prescription)
        const type = prescription.mode
        amplitude.getInstance().logEvent('PRESCRIPTION_DELETE', { type })
        return deleteResponse.body.message
    }
}

// mutations
const mutations = {
    setSelected(state, prescription) {
        state.selected = prescription
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage
    },
    setPreviousPage(state) {
        if (state.currentPage == 1) return
        const hasfertilizationPlan = !!state.selected?.options?.fertilizationPlan
        const hasFixedMode = state.selected?.options?.applicationMode === 'fixed'
        if (hasfertilizationPlan && state.currentPage == 3) state.currentPage = 1
        else if (hasFixedMode && state.currentPage == 3) state.currentPage = 1
        else state.currentPage = state.currentPage - 1
    },
    setNextPage(state) {
        if (state.currentPage == 3) return
        const hasfertilizationPlan = !!state.selected?.options?.fertilizationPlan
        const hasFixedMode = state.selected?.options?.applicationMode === 'fixed'
        if (hasfertilizationPlan && state.currentPage == 1) state.currentPage = 3
        else if (hasFixedMode && state.currentPage == 1) state.currentPage = 3
        else state.currentPage = state.currentPage + 1
    },
    addPrescription(state, prescriptionToSave) {
        state.prescriptions.unshift(prescriptionToSave)
    },
    updatePrescription(state, prescriptionToSave) {
        state.prescriptions = state.prescriptions.map((item) =>
            item._id == prescriptionToSave._id ? prescriptionToSave : item
        )
    },
    deletePrescription(state, prescription) {
        const prescriptionIndex = state.prescriptions.findIndex((item) => item._id == prescription._id)
        state.prescriptions.splice(prescriptionIndex, 1)
    },
    setPrescriptions(state, prescriptions) {
        state.prescriptions = prescriptions
    },
    setDoseError(state, value) {
        state.doseError = value
    },
    setEditPrescription(state, value) {
        state.editPrescription = value
    },
    setPointsGeojson(state, value) {
        state.pointsGeojson = value
    },
    setPointsLayer(state, value) {
        state.pointsLayer = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
