import { ulid } from 'ulid'

const state = {
    useSimulator: false,
    imageAnalysisClusterList: [],
    selected: {},
    selectedSample: {},
    editImageAnalysisCluster: false,
    takingSamples: false,
    noteQueue: { readyToSave: false, notes: [] },
    analysisDialog: false,
    resultDataJson: {}
}

// getters
const getters = {
    imageAnalysisClusterList: (state) => state.imageAnalysisClusterList,
    selected: (state) => state.selected,
    selectedSample: (state) => state.selectedSample,
    editImageAnalysisCluster: (state) => state.editImageAnalysisCluster,
    takingSamples: (state) => state.takingSamples,
    noteQueue: (state) => state.noteQueue,
    analysisDialog: (state) => state.analysisDialog,
    resultDataJson: (state) => state.resultDataJson
}

// actions
const actions = {
    async loadAnalyses({ commit, getters, rootGetters }, { vm }) {
        if (!getters.imageAnalysisClusterList.length) {
            const responseAnalyses = await vm.$api
                .imageAnalysisCluster()
                .getFromProject(rootGetters['projects/selectedProject']._id)
            commit('setAnalyses', responseAnalyses.body.data)
            return responseAnalyses.body.data
        }
        return getters.imageAnalysisClusterList
    },
    async loadById({ commit, rootGetters }, { vm, imageAnalysisClusterId }) {
        const response = await vm.$api
            .imageAnalysisCluster().getById(imageAnalysisClusterId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', response.body.data)
        return response.body.data
    },
    async create({ commit, rootGetters }, { vm, analysisToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const createResponse = await vm.$api.imageAnalysisCluster().create(analysisToSave)
            commit('addAnalysis', createResponse.body.data)
            return createResponse.body.data
        }
        const analysisCreated = { ...analysisToSave }
        analysisCreated._id = ulid()
        commit('addAnalysis', analysisCreated)
        return analysisCreated
    },
    async update({ commit, rootGetters }, { vm, analysisToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const updateResponse = await vm.$api
                .imageAnalysisCluster()
                .update(analysisToSave._id, rootGetters['projects/selectedProject']._id, analysisToSave)
            commit('updateAnalysis', updateResponse.body.data)
            return updateResponse.body.data
        }
        const analysisUpdated = { ...analysisToSave }
        commit('updateAnalysis', analysisUpdated)
        return analysisUpdated
    },
    async delete({ commit, rootGetters }, { vm, analysis }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const deleteResponse = await vm.$api
                .imageAnalysisCluster()
                .delete(analysis._id, rootGetters['projects/selectedProject']._id)
            commit('deleteAnalysis', analysis)
            return deleteResponse.body.message
        }
        commit('deleteAnalysis', analysis)
    }
}

// mutations
const mutations = {
    addAnalysis(state, analysis) {
        state.imageAnalysisClusterList.unshift(analysis)
    },
    updateAnalysis(state, analysisToSave) {
        state.imageAnalysisClusterList =
            state.imageAnalysisClusterList.map((item) => (item._id == analysisToSave._id ? analysisToSave : item))
    },
    deleteAnalysis(state, analysis) {
        const analysisIndex = state.imageAnalysisClusterList.findIndex((item) => item._id == analysis._id)
        state.imageAnalysisClusterList.splice(analysisIndex, 1)
    },
    setAnalyses(state, imageAnalysisClusterList) {
        state.imageAnalysisClusterList = imageAnalysisClusterList
    },
    setSelected(state, analysis) {
        state.selected = analysis
    },
    setSelectedSample(state, sample) {
        state.selectedSample = sample
    },
    setEditImageAnalysisCluster(state, value) {
        state.editImageAnalysisCluster = value
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setAnalysisDialog(state, value) {
        state.analysisDialog = value
    },
    setResultDataJson(state, data) {
        state.resultDataJson = data
    },
    clearAnalysis(state) {
        state.selected = {}
        state.selectedSample = {}
        state.editImageAnalysisCluster = false
        state.takingSamples = false
        state.noteQueue = { readyToSave: false, notes: [] }
        state.analysisDialog = false
    },
    clearData(state) {
        state.imageAnalysisClusterList = []
        state.selected = {}
        state.selectedSample = {}
        state.editImageAnalysisCluster = false
        state.takingSamples = false
        state.noteQueue = { readyToSave: false, notes: [] }
        state.analysisDialog = false
        state.resultDataJson = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
