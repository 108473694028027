const state = {
    seasons: [],
    selected: {},
    oldSeason: {}
}

// getters
const getters = {
    selected: (state) => state.selected,
    seasons: (state, _, rootState) => rootState.projects.selectedProject?.seasons || state.seasons,
    oldSeason: (state) => state.oldSeason
}

// actions
const actions = {
    async loadSeasons({ commit, getters }, { vm }) {
        const responseSeasons = await vm.$api.season().get()
        const seasons = responseSeasons.body.data
        commit('setSeasons', seasons)
        commit('setNewSeason')
        if (!getters.selected || !getters.selected._id) {
            commit('setSelected', getters.seasons.sort((p1, p2) => p2.end - p1.end)[0])
        }
        return seasons
    },
    async create({ commit }, { vm, seasonToSave }) {
        const createResponse = await vm.$api.season().create(seasonToSave)
        commit('addSeason', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        amplitude.getInstance().logEvent('SEASON_CREATE')
        return createResponse.body.message
    },
    async update({ state, commit, rootGetters }, { vm, seasonToSave }) {
        const updateResponse = await vm.$api
            .season()
            .update(seasonToSave._id, rootGetters['user/data']._id, seasonToSave)
        commit('updateSeason', updateResponse.body.data)
        if (state.selected._id == updateResponse.body.data._id) {
            commit('setSelected', updateResponse.body.data)
        }
        amplitude.getInstance().logEvent('SEASON_UPDATE')
        return updateResponse.body.message
    },
    async delete({ commit, getters, rootGetters }, { vm, season }) {
        const deleteResponse = await vm.$api.season().delete(season._id, rootGetters['user/data']._id)
        commit('deleteSeason', season)
        if (getters.selected && getters.selected._id == season._id) {
            commit('setSelected', getters.seasons.sort((p1, p2) => (p1.end < p2.end ? 1 : p1.end > p2.end ? -1 : 0))[0])
        }
        amplitude.getInstance().logEvent('SEASON_DELETE')
        return deleteResponse.body.message
    }
}

// mutations
const mutations = {
    setSelected(state, season) {
        state.selected = season
    },
    setSeasons(state, seasons) {
        state.seasons = seasons
    },
    addSeason(state, seasonToSave) {
        state.seasons.unshift(seasonToSave)
    },
    setOldSeason(state, oldSeason) {
        state.oldSeason = oldSeason
    },
    setNewSeason(state) {
        state.seasons.push({ name: 'new', icon: 'aicon-mas' })
    },
    updateSeason(state, seasonToSave) {
        state.seasons = state.seasons.map((item) => (item._id == seasonToSave._id ? seasonToSave : item))
    },
    deleteSeason(state, season) {
        const seasonIndex = state.seasons.findIndex((item) => item._id == season._id)
        state.seasons.splice(seasonIndex, 1)
    },
    clearData(state) {
        state.seasons = []
        state.selected = {}
        state.oldSeason = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
