const state = {
    selected: {},
    stations: [],
    view: 'forecast',
    viewType: 'chart',
    forecastTimePeriod: 'general3',
    historicalDataGroup: 'hourly',
    historicalSensorGroup: [],
    historicalDateFrom: '',
    historicalDateTo: '',
    userStations: []
}

// getters
const getters = {
    selected: (state) => state.selected,
    stations: (state) => state.stations,
    userStations: (state) => state.userStations,
    view: (state) => state.view,
    viewType: (state) => state.viewType,
    forecastTimePeriod: (state) => state.forecastTimePeriod,
    historicalDataGroup: (state) => state.historicalDataGroup,
    historicalSensorGroup: (state) => state.historicalSensorGroup,
    historicalDateFrom: (state) => state.historicalDateFrom,
    historicalDateTo: (state) => state.historicalDateTo
}

// actions
const actions = {
    async loadStations({ commit }, { vm }) {
        const responseStations = await vm.$api.stations().get()
        commit('setStations', responseStations.body.data)
        commit('setSelected', (responseStations.body.data || [])[0])
        return responseStations.body.data
    },
    async loadUserStations({ commit, rootGetters }, { vm }) {
        const responseStations = await vm.$api.stations().getAllByUser(rootGetters['user/data']._id)
        commit('setUserStations', responseStations.body.data)
        return responseStations.body.data
    },
    async loadStation({ commit, rootGetters }, { vm, stationId }) {
        const responseStations = await vm.$api.stations().getByStationId(rootGetters['user/data']._id, stationId)
        commit('setSelected', responseStations.body.data[0])
        return responseStations.body.data[0]
    }
}

// mutations
const mutations = {
    setSelected(state, selected) {
        state.selected = selected
    },
    setStations(state, stations) {
        state.stations = stations
    },
    setUserStations(state, stations) {
        state.userStations = stations
    },
    setView(state, view) {
        state.view = view
    },
    setViewType(state, viewType) {
        state.viewType = viewType
    },
    setForecastTimePeriod(state, forecastTimePeriod) {
        state.forecastTimePeriod = forecastTimePeriod
    },
    setHistoricalDataGroup(state, historicalDataGroup) {
        state.historicalDataGroup = historicalDataGroup
    },
    setHistoricalSensorGroup(state, historicalSensorGroup) {
        state.historicalSensorGroup = historicalSensorGroup
    },
    setHistoricalDateFrom(state, historicalDateFrom) {
        state.historicalDateFrom = historicalDateFrom
    },
    setHistoricalDateTo(state, historicalDateTo) {
        state.historicalDateTo = historicalDateTo
    },
    clearData(state) {
        state.selected = {}
        state.stations = []
        state.userStations = []
        state.view = 'forecast'
        state.viewType = 'chart'
        state.forecastTimePeriod = 'general3'
        state.historicalDataGroup = 'hourly'
        state.historicalSensorGroup = []
        state.historicalDateFrom = ''
        state.historicalDateTo = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
