export default {
    install(Vue) {
        const tileServerUrl = process.env.VUE_APP_TILES
        const tiles = {
            url() {
                return {
                    fromStage: (stage) => {
                        let stageTileUrl = tileServerUrl
                        stageTileUrl += `/tiles/${stage.userId}/${stage.projectId}`
                        if (stage.mosaic.mapId && stage.mosaic.algoritmo) {
                            stageTileUrl += `/nutrient/${stage.mosaic.algoritmo}`
                            stageTileUrl += `/${stage.mosaic.mapId}`
                        } else if (stage.mosaic.mapId) {
                            stageTileUrl += `/import/${stage.mosaic.mapId}`
                        } else if (stage.mosaic.droneId && stage.mosaic.processId) {
                            stageTileUrl += `/drone/${stage.mosaic.droneId}`
                            stageTileUrl += `/process/${stage.mosaic.processId}`
                            stageTileUrl += `/${stage.mosaic.algoritmo}`
                        } else {
                            stageTileUrl += `/${stage.mosaic.provider}`
                            stageTileUrl += `/${stage.mosaic.year}/${stage.mosaic.month}/${stage.mosaic.day}`
                            stageTileUrl += `/${stage.mosaic.algoritmo}`
                        }
                        stageTileUrl += '/{z}/{x}/{y}?'
                        stageTileUrl += stage.mosaic.subProjectId ? `subProjectId=${stage.mosaic.subProjectId}&` : ''
                        stageTileUrl += stage.mosaic.minValue !== undefined ? `minValue=${stage.mosaic.minValue}&` : ''
                        stageTileUrl += stage.mosaic.maxValue !== undefined ? `maxValue=${stage.mosaic.maxValue}&` : ''
                        stageTileUrl += stage.mosaic.scouting ? `scouting=${stage.mosaic.scouting}&` : ''
                        stageTileUrl += stage.mosaic.cmap ? `cmap=${stage.mosaic.cmap}&` : ''
                        stageTileUrl += stage.mosaic.hiddenColors ? `colors=${stage.mosaic.hiddenColors}` : ''
                        return stageTileUrl
                    },
                    fromLayer: (project, layer) => {
                        let layerTileUrl = tileServerUrl
                        layerTileUrl += `/tiles/${project.userId}/${project._id}`
                        if (layer.mapId && layer.algoritmo) {
                            layerTileUrl += `/nutrient/${layer.algoritmo}`
                            layerTileUrl += `/${layer.mapId}`
                        } else if (layer.mapId) {
                            layerTileUrl += `/import/${layer.mapId}`
                        } else if(layer.compare) {
                            layerTileUrl += `/${layer.provider}`
                            layerTileUrl += `/${layer.algoritmo}`
                            layerTileUrl += `/${layer.compare}`
                        } else if (layer.droneId && layer.processId) {
                            layerTileUrl += `/drone/${layer.droneId}`
                            layerTileUrl += `/process/${layer.processId}`
                            layerTileUrl += `/${layer.algoritmo}`
                        } else {
                            layerTileUrl += `/${layer.provider}`
                            layerTileUrl += `/${layer.year}/${layer.month}/${layer.day}`
                            layerTileUrl += `/${layer.algoritmo}`
                        }
                        layerTileUrl += '/{z}/{x}/{y}?'
                        layerTileUrl += layer.subProjectId ? `subProjectId=${layer.subProjectId}&` : ''
                        layerTileUrl += layer.minValue !== undefined ? `minValue=${layer.minValue}&` : ''
                        layerTileUrl += layer.maxValue !== undefined ? `maxValue=${layer.maxValue}&` : ''
                        layerTileUrl += layer.firstDate !== undefined ? `firstDate=${layer.firstDate}&` : ''
                        layerTileUrl += layer.secondDate !== undefined ? `secondDate=${layer.secondDate}&` : ''
                        layerTileUrl += layer.scouting ? `scouting=${layer.scouting}&` : ''
                        layerTileUrl += layer.cmap ? `cmap=${layer.cmap}&` : ''
                        layerTileUrl += layer.hiddenColors ? `colors=${layer.hiddenColors}` : ''
                        return layerTileUrl
                    },
                    fromGrid: (grid, pointId) => {
                        let gridTileUrl = tileServerUrl
                        gridTileUrl += `/tiles/${grid.userId}/${grid.projectId}`
                        gridTileUrl += `/drone/${grid.date.split('-').join('/')}`
                        gridTileUrl += '/TRUE_COLOR/{z}/{x}/{y}?'
                        gridTileUrl += `grid_id=${grid._id}&`
                        gridTileUrl += `point_id=${pointId}`
                        return gridTileUrl
                    },
                    fromPrescription: (prescription, format) => {
                        let prescriptionUrl = tileServerUrl
                        prescriptionUrl += `/prescriptions/${prescription.userId}/${prescription.projectId}`
                        if (prescription.geojson) {
                            prescriptionUrl += '/geojson?'
                        } else if (prescription.layer && prescription.layer.mapId) {
                            prescriptionUrl += `/import/${prescription.layer.mapId}?`
                        } else if (prescription.layer && prescription.layer.droneId &&
                            prescription.layer.processId) {
                            prescriptionUrl += `/drone/${prescription.layer.droneId}`
                            prescriptionUrl += `/process/${prescription.layer.processId}`
                            prescriptionUrl += `/${prescription.layer.algoritmo}?`
                        } else {
                            prescriptionUrl += `/${prescription.layer.provider}`
                            prescriptionUrl += `/${prescription.layer.year}/${
                                prescription.layer.month}/${prescription.layer.day}`
                            prescriptionUrl += `/${prescription.layer.algoritmo}?`
                        }
                        prescriptionUrl += format ? `output_format=${format}&` : ''
                        prescriptionUrl +=
                            prescription.options && prescription.options.resolution
                                ? `resolution=${prescription.options.resolution}&`
                                : ''
                        prescriptionUrl +=
                            prescription.layer && prescription.layer.minValue !== undefined
                                ? `min_value=${prescription.layer.minValue}&`
                                : ''
                        prescriptionUrl +=
                            prescription.layer && prescription.layer.maxValue !== undefined
                                ? `max_value=${prescription.layer.maxValue}&`
                                : ''
                        prescriptionUrl +=
                            prescription.options && prescription.options.productValues
                                ? `fertilizer_values=${JSON.stringify(prescription.options.productValues)}&`
                                : ''
                        prescriptionUrl += prescription.layer && prescription.layer.subProjectId
                            ? `subProjectId=${prescription.layer.subProjectId}&`
                            : ''
                        prescriptionUrl +=
                            prescription.layer &&
                            prescription.layer.cropPolygon &&
                            prescription.layer.cropPolygon.length > 0
                                ? `polygon=${JSON.stringify(prescription.layer.cropPolygon)}&`
                                : ''
                        return prescriptionUrl
                    },
                    fromGlobalWeather: (forecastParameter, forecastDateYmdh) => {
                        let gridTileUrl = tileServerUrl
                        gridTileUrl += `/forecast/${forecastDateYmdh}/${forecastParameter}`
                        // Binaries, CAPE, Csnow and Wind don't require z/x/y
                        if (
                            forecastParameter === 'temperature_bin' ||
                            forecastParameter === 'snow_bin' ||
                            forecastParameter === 'precipitation_bin' ||
                            forecastParameter === 'cape' ||
                            forecastParameter === 'csnow' ||
                            forecastParameter === 'wind'
                        ) {
                            return gridTileUrl
                        }
                        gridTileUrl += '/{z}/{x}/{y}'
                        return gridTileUrl
                    }
                }
            },
            stats() {
                return {
                    get: (project, layer, crop) => {
                        let statsUrl = tileServerUrl
                        statsUrl += `/stats/${project.userId}/${project._id}`
                        if (layer.mapId && layer.algoritmo) {
                            statsUrl += `/nutrient/${layer.algoritmo}`
                            statsUrl += `/${layer.mapId}?`
                        } else if (layer.mapId) {
                            statsUrl += `/import/${layer.mapId}?`
                        } else if(layer.compare) {
                            statsUrl += `/${layer.provider}`
                            statsUrl += `/${layer.algoritmo}`
                            statsUrl += `/${layer.compare}?`
                        } else if (layer.droneId && layer.processId) {
                            statsUrl += `/drone/${layer.droneId}`
                            statsUrl += `/process/${layer.processId}`
                            statsUrl += `/${layer.algoritmo}?`
                        } else {
                            statsUrl += `/${layer.provider}`
                            statsUrl += `/${layer.year}/${layer.month}/${layer.day}`
                            statsUrl += `/${layer.algoritmo}?`
                        }
                        statsUrl += layer.subProjectId ? `subProjectId=${layer.subProjectId}&` : ''
                        statsUrl += layer.firstDate !== undefined ? `firstDate=${layer.firstDate}&` : ''
                        statsUrl += layer.secondDate !== undefined ? `secondDate=${layer.secondDate}&` : ''
                        statsUrl += crop && crop.length > 0 && !layer.subProjectId
                            ? `polygon=${JSON.stringify(crop)}&`
                            : ''
                        return Vue.http.get(statsUrl, { timeout: 60000 })
                    },
                    getVegetationIndex: (userId, projectId, provider, indice, startDate, endDate, crop) => {
                        let statsUrl = tileServerUrl
                        statsUrl += `/stats/${userId}/${projectId}/${provider}/${indice}`
                        statsUrl += `?startDate=${startDate}&endDate=${endDate}&`
                        statsUrl += crop && crop.length > 0 ? `polygon=${JSON.stringify(crop)}&` : ''
                        return Vue.http.get(statsUrl, { timeout: 60000 })
                    },
                    getGeojson: (project, layer, geojson) => {
                        let statsUrl = tileServerUrl
                        statsUrl += `/stats/${project.userId}/${project._id}`
                        statsUrl += `/${layer.provider}`
                        statsUrl += `/${layer.year}/${layer.month}/${layer.day}`
                        statsUrl += `/${layer.algoritmo}/geojson?`
                        statsUrl += layer.subProjectId ? `subProjectId=${layer.subProjectId}&` : ''
                        return Vue.http.post(statsUrl, { geojson }, { timeout: 60000 })
                    }
                }
            },
            utils() {
                return {
                    generateGrid: ({ polygon, angleSide1, angleSide2, rows, columns, hectares, aspectRate }) => {
                        let statsUrl = tileServerUrl
                        statsUrl += '/utils/grid?'
                        statsUrl += `angle_side1=${angleSide1}&`
                        statsUrl += `angle_side2=${angleSide2}&`
                        statsUrl += `polygon=${JSON.stringify(polygon)}&`
                        statsUrl += columns ? `columns=${columns}&` : ''
                        statsUrl += rows ? `rows=${rows}&` : ''
                        statsUrl += hectares ? `hectares=${hectares}&` : ''
                        statsUrl += aspectRate ? `aspect_rate=${aspectRate}&` : ''
                        statsUrl += 'disolve_area=0.3&'
                        return Vue.http.get(statsUrl, { timeout: 60000 })
                    }
                }
            },
            imageAnalysis() {
                return {
                    segmentsIndexes: (project, analysis, layer) => {
                        let segmentsUrl = `${analysis.options.provider?.type}`
                        if (analysis.options.provider?.droneId && analysis.options.provider?.processId) {
                            segmentsUrl += `/${analysis.options.provider.droneId}`
                            segmentsUrl += `/process/${analysis.options.provider.processId}`
                        } else {
                            const date = analysis.options.provider?.date.split('-')
                                .map((item) => parseInt(item)).join('/')
                            segmentsUrl += `/${date}`
                        }
                        segmentsUrl += `/analytics/${analysis.subProjectId}/plantCentroids.zip`
                        let layerTileUrl = tileServerUrl
                        layerTileUrl += `/image-analysis/${project.userId}/${project._id}`
                        layerTileUrl += `/${layer.provider}/${layer.year}/${layer.month}/${layer.day}`
                        layerTileUrl += `/${layer.algoritmo}/geojson?shapefile=${segmentsUrl}`
                        layerTileUrl += analysis.subProjectId ? `&subProjectId=${analysis.subProjectId}` : ''
                        return Vue.http.get(layerTileUrl)
                    }
                }
            }
        }
        Vue.prototype.$tiles = tiles
    }
}
