const state = {
    folders: [],
    stages: []
}

// getters
const getters = {
    folders: (state) => state.folders,
    stages: (state) => state.stages,
    projectStages: (state, _, rootState) => state.stages
        .filter((stage) => stage.projectId == rootState.projects.selectedProject._id && !stage.folder),
    projectFolders: (state, _, rootState) => state.folders
        .filter((folder) => folder.projectId == rootState.projects.selectedProject._id)
}

// actions
const actions = {
    async loadStages({ commit, getters, rootGetters }, { vm }) {
        if (!rootGetters['offline/active']) {
            const responseStages = await vm.$api.project()
                .getStages(rootGetters['projects/selectedProject']._id)
            const stages = responseStages.body.data.map((stage) => {
                stage.offline = getters.stages.find((el) => el._id === stage._id)?.offline
                return stage
            })

            const responseFolders = await vm.$api.project()
                .getFolders(rootGetters['projects/selectedProject']._id)
            // Muevo los proyectos a sus carpetas correspondientes
            const folders = responseFolders.body.data.map((folder) => {
                folder.stages = stages.filter((stage) => stage.folder === folder._id)
                return folder
            })

            commit('setProjectFolders', folders)
            commit('setProjectStages', stages)
        }
    },
    async create({ commit }, { vm, stage }) {
        const createResponse = await vm.$api.stage().create(stage)
        commit('addStage', createResponse.body.data)
        amplitude.getInstance().logEvent('SCENARIO_CREATE')
        return createResponse.body.data
    },
    async update({ commit, rootGetters }, { vm, stage }) {
        const updateResponse = await vm.$api.stage()
            .update(stage._id, rootGetters['projects/selectedProject']._id, stage)
        commit('updateStage', updateResponse.body.data)
        return updateResponse.body.data
    },
    async delete({ commit, rootGetters }, { vm, stage }) {
        const deleteResponse = await vm.$api.stage()
            .delete(stage._id, rootGetters['projects/selectedProject']._id)
        commit('deleteStage', deleteResponse.body.data)
        return deleteResponse.body.data
    },
    async move({ commit, rootGetters }, { vm, stage, folderId }) {
        if (folderId) {
            const addFolderResponse = await vm.$api.stage()
                .addFolder(stage._id, rootGetters['projects/selectedProject']._id, folderId)
            commit('removeFromFolder', addFolderResponse.body.data)
            commit('addToFolder', addFolderResponse.body.data)
            return addFolderResponse.body.data
        }

        const removeFolderResponse = await vm.$api.stage()
            .removeFolder(stage._id, rootGetters['projects/selectedProject']._id)
        commit('removeFromFolder', removeFolderResponse.body.data)
        return removeFolderResponse.body.data
    },
    async saveFolder({ commit, rootGetters }, { vm, projectId, folder }) {
        if (folder._id) {
            const updateResponse = await vm.$api.folder()
                .update(folder._id, rootGetters['user/data']._id, folder)
            commit('updateFolder', updateResponse.body.data)
            return updateResponse.body.data
        }

        const createResponse = await vm.$api.folder().createStage(projectId, folder)
        createResponse.body.data.stages = []
        commit('addFolder', createResponse.body.data)
        return createResponse.body.data
    },
    async deleteFolder({ commit, rootGetters }, { vm, folder }) {
        const deleteResponse = await vm.$api.folder()
            .delete(folder._id, rootGetters['user/data']._id)
        commit('deleteFolder', deleteResponse.body.data)
        return deleteResponse.body.data
    }
}

// mutations
const mutations = {
    setFolders(state, folders) {
        state.folders = folders
    },
    setProjectFolders(state, folders) {
        const projectId = folders.length > 0 ? folders[0].project : ''
        state.folders = folders.concat(state.folders.filter((folder) => folder.project !== projectId))
    },
    addStage(state, stage) {
        state.stages.unshift(stage)
        if (stage.folder) {
            state.folders = state.folders.map((folder) => {
                if (folder._id === stage.folder) folder.stages.unshift(stage)
                return folder
            })
        }
    },
    updateStage(state, stage) {
        state.stages = state.stages.map((item) => (item._id == stage._id ? stage : item))
        if (stage.folder) {
            state.folders = state.folders.map((folder) => {
                if (folder._id === stage.folder) {
                    folder.stages = folder.stages.map((item) => (item._id === stage._id ? stage : item))
                    folder.offline = folder.stages.some((el) => el.offline)
                }
                return folder
            })
        }
    },
    deleteStage(state, stage) {
        const stageIndex = state.stages.findIndex((item) => item._id === stage._id)
        state.stages.splice(stageIndex, 1)
        if (stage.folder) {
            state.folders = state.folders.map((folder) => {
                if (folder._id === stage.folder) {
                    const folderStageIndex = folder.stages.findIndex((item) => item._id === stage._id)
                    if (folderStageIndex != -1) folder.stages.splice(folderStageIndex, 1)
                    folder.offline = folder.stages.some((el) => el.offline)
                }
                return folder
            })
        }
    },
    addToFolder(state, stage) {
        state.stages = state.stages.map((item) => (item._id == stage._id ? stage : item))
        state.folders = state.folders.map((folder) => {
            if (folder._id === stage.folder) {
                if (folder.stages) folder.stages.unshift(stage)
                else folder.stages = [stage]
            }
            return folder
        })
    },
    removeFromFolder(state, stage) {
        state.stages = state.stages.map((item) => (item._id == stage._id ? stage : item))
        state.folders = state.folders.map((folder) => {
            const stageIndex = folder.stages.findIndex((item) => item._id === stage._id)
            if (stageIndex != -1) folder.stages.splice(stageIndex, 1)
            return folder
        })
    },
    addFolder(state, folder) {
        state.folders.unshift(folder)
    },
    updateFolder(state, folder) {
        state.folders = state.folders.map((item) => (item._id == folder._id ? folder : item))
    },
    deleteFolder(state, folder) {
        const folderIndex = state.folders.findIndex((item) => item._id === folder._id)
        state.folders.splice(folderIndex, 1)
    },
    setStages(state, stages) {
        state.stages = stages
    },
    setProjectStages(state, stages) {
        const projectId = stages.length > 0 ? stages[0].project : ''
        state.stages = stages.concat(state.stages.filter((stage) => stage.project !== projectId))
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.folders = []
            state.stages = []
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
