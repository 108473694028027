const state = {
    results: [],
    selected: {},
    noteQueue: { readyToSave: false, notes: [] },
    saveButtonEnabled: false,
    editIrrigation: false,
    replicateIrrigation: false,
    viewType: 'chart',
    accumulatedIrrigation: 0,
    consumptionAdjustmentState: false,
    irrigationMetrics: [],
    statusCreated: null,
    currentPage: 1
}

// getters
const getters = {
    results: (state) => state.results,
    selected: (state) => state.selected,
    editIrrigation: (state) => state.editIrrigation,
    replicateIrrigation: (state) => state.replicateIrrigation,
    noteQueue: (state) => state.noteQueue,
    saveButtonEnabled: (state) => state.saveButtonEnabled,
    viewType: (state) => state.viewType,
    accumulatedIrrigation: (state) => state.accumulatedIrrigation,
    consumptionAdjustmentState: (state) => state.consumptionAdjustmentState,
    irrigationMetrics: (state) => state.irrigationMetrics,
    statusCreated: (state) => state.statusCreated,
    currentPage: (state) => state.currentPage
}

// actions
const actions = {
    async loadResults({ commit, rootGetters }, { vm }) {
        const res = await vm.$api.irrigation().getByProject(rootGetters['projects/selectedProject']._id)
        commit('setResults', res.body.data)
        return res.body.data
    },
    async loadById({ commit, rootGetters }, { vm, irrigationId }) {
        const res = await vm.$api.irrigation().getById(irrigationId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', res.body.data)
        return res.body.data
    },
    async create({ commit }, { vm, irrigation }) {
        const res = await vm.$api.irrigation().create(irrigation)
        commit('setSelected', res.body.data)
        commit('addResult', res.body.data)
        commit('setStatusCreated', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IRRIGATION_CREATE', { type })
        return res.body.message
    },
    async update({ commit, rootGetters }, { vm, irrigation }) {
        const res = await vm.$api
            .irrigation()
            .update(irrigation._id, rootGetters['projects/selectedProject']._id, irrigation)
        commit('updateResult', res.body.data)
        commit('setSelected', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IRRIGATION_UPDATE', { type })
        return res.body.message
    },
    async delete({ commit, rootGetters }, { vm, irrigation }) {
        const res = await vm.$api.irrigation().delete(irrigation._id, rootGetters['projects/selectedProject']._id)
        commit('deleteResult', irrigation)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IRRIGATION_DELETE', { type })
        return res.body.message
    },
    async updateUserInput({ commit, rootGetters }, { vm, irrigation }) {
        const res = await vm.$api
            .irrigation()
            .updateUserInput(irrigation._id, rootGetters['projects/selectedProject']._id, irrigation)
        commit('updateResult', res.body.data)
        commit('setSelected', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IRRIGATION_UPDATE', { type })
        return res.body.message
    },
    async checkPendingCreated({ commit, rootGetters }, { vm, irrigationId }) {
        const response = await vm.$api.irrigation().getStatus(irrigationId, rootGetters['projects/selectedProject']._id)
        commit('setStatusCreated', response.body.data)
        return response.body.data
    }
}

// mutations
const mutations = {
    setSelected(state, irrigation) {
        state.selected = irrigation
    },
    setEditIrrigation(state, value) {
        state.editIrrigation = value
    },
    setReplicateIrrigation(state, value) {
        state.replicateIrrigation = value
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setButtonStatus(state, status) {
        state.saveButtonEnabled = status
    },
    addResult(state, irrigationToSave) {
        state.results.unshift(irrigationToSave)
    },
    updateResult(state, irrigation) {
        state.results = state.results.map((item) => (item._id == irrigation._id ? irrigation : item))
    },
    deleteResult(state, irrigationToDelete) {
        const irrigationIndex = state.results.findIndex((item) => item._id == irrigationToDelete._id)
        state.results.splice(irrigationIndex, 1)
    },
    setResults(state, results) {
        state.results = results
    },
    setViewType(state, viewType) {
        state.viewType = viewType
    },
    setAccumulatedIrrigation(state, accumulatedIrrigation) {
        state.accumulatedIrrigation = accumulatedIrrigation
    },
    setConsumptionAdjustmentState(state, consumptionAdjustmentState) {
        state.consumptionAdjustmentState = consumptionAdjustmentState
    },
    setIrrigationMetrics(state, irrigationMetrics) {
        state.irrigationMetrics = irrigationMetrics
    },
    setStatusCreated(state, statusCreated) {
        state.statusCreated = statusCreated
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage
    },
    setPreviousPage(state) {
        if (state.currentPage == 1) return
        state.currentPage = state.currentPage - 1
    },
    setNextPage(state) {
        if (state.currentPage == 3) return
        state.currentPage = state.currentPage + 1
    },
    clearData(state) {
        state.selected = {}
        state.editIrrigation = false
        state.replicateIrrigation = false
        state.noteQueue = { readyToSave: false, notes: [] }
        state.saveButtonEnabled = false,
        state.consumptionAdjustmentState = false,
        state.viewType = 'chart',
        state.accumulatedIrrigation = 0,
        state.irrigationMetrics = []
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}