const state = {
    results: [],
    selected: {},
    editAnalysis: false,
    editAnalysisGeoJson: false,
    noteQueue: { readyToSave: false, notes: [] },
    resultDataJson: {},
    saveButtonEnabled: false
}

// getters
const getters = {
    selected: (state) => state.selected,
    results: (state) => state.results,
    editAnalysis: (state) => state.editAnalysis,
    editAnalysisGeoJson: (state) => state.editAnalysisGeoJson,
    noteQueue: (state) => state.noteQueue,
    resultDataJson: (state) => state.resultDataJson,
    saveButtonEnabled: (state) => state.saveButtonEnabled
}

// actions
const actions = {
    async loadResults({ commit, rootGetters }, { vm }) {
        const res = await vm.$api.imageAnalysis().getByProject(rootGetters['projects/selectedProject']._id)
        commit('setResults', res.body.data)
        return res.body.data
    },
    async loadById({ commit, rootGetters }, { vm, analysisId }) {
        const res = await vm.$api.imageAnalysis().getById(analysisId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', res.body.data)
        return res.body.data
    },
    async getById({ rootGetters }, { vm, analysisId }) {
        const res = await vm.$api.imageAnalysis().getById(analysisId, rootGetters['projects/selectedProject']._id)
        return res.body.data
    },
    async create({ commit }, { vm, analysis }) {
        const res = await vm.$api.imageAnalysis().create(analysis)
        commit('setSelected', res.body.data)
        commit('addResult', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IMAGE_ANALYSIS_CREATE', { type })
        return res.body.message
    },
    async update({ commit, rootGetters }, { vm, analysis }) {
        const res = await vm.$api
            .imageAnalysis()
            .update(analysis._id, rootGetters['projects/selectedProject']._id, analysis)
        commit('updateResult', res.body.data)
        commit('setSelected', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IMAGE_ANALYSIS_UPDATE', { type })
        return res.body.message
    },
    async updateName({ commit, rootGetters, getters }, { vm, analysis }) {
        const res = await vm.$api
            .imageAnalysis()
            .updateName(analysis._id, rootGetters['projects/selectedProject']._id, analysis)
        commit('updateResult', res.body.data)
        if(getters.selected?._id) {
            commit('setSelected', res.body.data)
        }
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IMAGE_ANALYSIS_UPDATE', { type })
        return res.body.message
    },
    async updateNotes({ commit }, { vm, analysis }) {
        const res = await vm.$api.imageAnalysis().update(analysis._id, analysis)
        commit('updateResult', res.body.data)
        commit('setSelectedNotes', res.body.data)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IMAGE_ANALYSIS_UPDATE', { type })
        return res.body.message
    },
    async delete({ commit, rootGetters }, { vm, analysis }) {
        const res = await vm.$api.imageAnalysis().delete(analysis._id, rootGetters['projects/selectedProject']._id)
        commit('deleteResult', analysis)
        const type = res.body.data.type
        amplitude.getInstance().logEvent('IMAGE_ANALYSIS_DELETE', { type })
        return res.body.message
    }
}

// mutations
const mutations = {
    setResults(state, results) {
        state.results = results
    },
    addResult(state, analysisToSave) {
        state.results.unshift(analysisToSave)
    },
    updateResult(state, analysis) {
        state.results = state.results.map((item) => (item._id == analysis._id ? analysis : item))
    },
    deleteResult(state, analysisToDelete) {
        const analysisIndex = state.results.findIndex((item) => item._id == analysisToDelete._id)
        state.results.splice(analysisIndex, 1)
    },
    setSelected(state, analysis) {
        state.selected = analysis
    },
    setSelectedNotes(state, analysis) {
        state.selected.notes = analysis.notes || []
    },
    setEditAnalysis(state, value) {
        state.editAnalysis = value
    },
    setEditAnalysisGeoJson(state, value) {
        state.editAnalysisGeoJson = value
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setResultDataJson(state, data) {
        state.resultDataJson = data
    },
    setButtonStatus(state, status) {
        state.saveButtonEnabled = status
    },
    clearData(state) {
        state.results = []
        state.selected = {}
        state.editAnalysis = false
        state.editAnalysisGeoJson = false
        state.selected = ''
        state.noteQueue = { readyToSave: false, notes: [] }
        state.resultDataJson = {}
        state.saveButtonEnabled = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
