import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: {
            es,
            en
        },
        current: 'es'
    },
    icons: {
        iconfont: 'md'
    },
    theme: {
        themes: {
            light: {
                primary: '#5852F2',
                secondary: '#8CDD58',
                accent: '#F9C80E',
                error: '#FF4949',
                warning: '#F2D600',
                info: '#03A9F4',
                success: '#04BF68',
                folder: '#FF931B',
                disabled: '#84979B',
                lightGreen: '#BBEF29',
                greyLite: '#F3F5F5',
                grey: '#E7EAEB',
                greyMedium: '#A9A9A9',
                greyDark: '#84979B',
                titleColor: '#0A2E36',
                subtitleColor: '#546D72',
                primaryBackground: '#F2F1FE'
            }
        }
    }
})
