const state = {
    accumulatedRain: 0,
    view: null,
    weatherForecast: []
}

// getters
const getters = {
    accumulatedRain: (state) => state.accumulatedRain,
    view: (state) => state.view,
    weatherForecast: (state) => state.weatherForecast
}

// actions
const actions = {
    async loadWeather({ commit, rootGetters }, { vm }) {
        const responseWeather = await vm.$api
            .weather()
            .getWeather(rootGetters['projects/selectedProject']._id, rootGetters['user/data']._id)
        const newArrayWeather = responseWeather.body.data.weather.slice()
        const nextWeather = responseWeather.body.data
        commit('setWeatherForecast', newArrayWeather)
        return nextWeather
    },
    async loadHistoricalWeather({ rootGetters }, { vm, startDate, endDate, baseTemp }) {
        const responseWeather = await vm.$api
            .weather()
            .getHistoricalWeather(
                rootGetters['projects/selectedProject']._id,
                rootGetters['user/data']._id,
                startDate,
                endDate,
                baseTemp
            )
        const historicalWeather = responseWeather.body.data
        return historicalWeather
    }
}

// mutations
const mutations = {
    setAccumulatedRain(state, mm) {
        state.accumulatedRain = mm
    },
    setView(state, view) {
        state.view = view
    },
    setWeatherForecast(state, forecast) {
        state.weatherForecast = forecast
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
