import Vue from 'vue'
import store from '../store'
import Router from 'vue-router'
import Meta from 'vue-meta'

const Home = () =>
    import(/* webpackChunkName: "main" */ '@/views/Home/Home')
const Login = () =>
    import(/* webpackChunkName: "main" */ '@/views/Login/Login')
const Register = () =>
    import(/* webpackChunkName: "main" */ '@/views/Login/Register')
const Projects = () =>
    import(/* webpackChunkName: "main" */ '@/views/Projects/Projects')

const Project = () =>
    import(/* webpackChunkName: "project" */ '@/views/Project/Project')
const Images = () =>
    import(/* webpackChunkName: "project" */ '@/views/Project/Images/Images')
const ImagesFooter = () =>
    import(/* webpackChunkName: "project" */ '@/views/Project/Images/ImagesFooter')
const SubProject = () =>
    import(/* webpackChunkName: "project" */ '@/views/Project/SubProject/SubProject')
const SubProjectFooter = () =>
    import(/* webpackChunkName: "project" */ '@/views/Project/SubProject/SubProjectFooter')

const Weather = () =>
    import(/* webpackChunkName: "weather" */ '@/views/Project/Weather/Weather')
const WeatherFooter = () =>
    import(/* webpackChunkName: "weather" */ '@/views/Project/Weather/WeatherFooter')
const WeatherSubContent = () =>
    import(/* webpackChunkName: "weather" */ '@/views/Project/Weather/WeatherSubContent')

const Stations = () =>
    import(/* webpackChunkName: "stations" */ '@/views/Project/Stations/Stations')
const StationsSubContent = () =>
    import(/* webpackChunkName: "stations" */ '@/views/Project/Stations/StationsSubContent')

const Flights = () =>
    import(/* webpackChunkName: "drone" */ '@/views/Project/Flights/Flights')
const FlightsFooter = () =>
    import(/* webpackChunkName: "drone" */ '@/views/Project/Flights/FlightsFooter')
const Flight = () =>
    import(/* webpackChunkName: "drone" */ '@/views/Drone/Flight')
const Grid = () =>
    import(/* webpackChunkName: "drone" */ '@/views/Project/Grids/Grid')

const Stages = () =>
    import(/* webpackChunkName: "stages" */ '@/views/Project/Stages/Stages')

const Notes = () =>
    import(/* webpackChunkName: "notes" */ '@/views/Project/Notes/Notes')
const Note = () =>
    import(/* webpackChunkName: "notes" */ '@/views/Project/Notes/Note')
const NoteFooter = () =>
    import(/* webpackChunkName: "notes" */ '@/views/Project/Notes/NoteFooter')

const Prescriptions = () =>
    import(/* webpackChunkName: "prescription" */ '@/views/Project/Prescriptions/Prescriptions')
const Prescription = () =>
    import(/* webpackChunkName: "prescription" */ '@/views/Project/Prescriptions/Prescription')
const PrescriptionFooter = () =>
    import(/* webpackChunkName: "prescription" */ '@/views/Project/Prescriptions/PrescriptionFooter')

const Imports = () =>
    import(/* webpackChunkName: "import" */ '@/views/Project/Imports/Imports')
const Import = () =>
    import(/* webpackChunkName: "import" */ '@/views/Project/Imports/Import')
const ImportFooter = () =>
    import(/* webpackChunkName: "import" */ '@/views/Project/Imports/ImportFooter')

const Account = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Account')
const Profile = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Profile/Profile')
const Plans = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/Plans')
const PlanCheckout = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/Checkout')
const PlanHectares = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/Hectares')
const PlanConfirm = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/Confirm')
const Subscription = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/Subscription')
const SubscriptionFooter = () =>
    import(/* webpackChunkName: "account" */ '@/views/Account/Subscription/SubscriptionFooter')
const RecoverPass = () =>
    import(/* webpackChunkName: "account" */ '@/views/Login/RecoverPass')
const NewPass = () =>
    import(/* webpackChunkName: "account" */ '@/views/Login/NewPass')

const Admin = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Admin')
const Templates = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Templates/Templates')
const TemplatesSubContent = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Templates/TemplatesSubContent')
const Users = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Users/Users')
const User = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Users/User')
const UsersSubContent = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Users/UsersSubContent')
const UserFooter = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Users/UserFooter')
const TeamsAdmin = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Teams/Teams')
const TeamsAdminSubContent = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Teams/TeamsSubContent')
const NewTeam = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Teams/Team')
const NewTeamFooter = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Teams/TeamFooter')
const AdminProjects = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Projects/Projects')
const AdminProject = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Projects/Project')
const AdminProjectsSubContent = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Projects/ProjectsSubContent')
const Integrations = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Integrations/Integrations')
const Integration = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Integrations/Integration')
const IntegrationsSubContent = () =>
    import(/* webpackChunkName: "admin" */ '@/views/Admin/Integrations/IntegrationsSubContent')

const Activities = () =>
    import(/* webpackChunkName: "activity" */ '@/views/Project/Activities/Activities')
const Activity = () =>
    import(/* webpackChunkName: "activity" */ '@/views/Project/Activities/Activity')
const ActivityFooter = () =>
    import(/* webpackChunkName: "activity" */ '@/views/Project/Activities/ActivityFooter')
const Monitoring = () =>
    import(/* webpackChunkName: "monitoring" */ '@/views/Project/Monitoring/Monitoring')
const TaskMonitoring = () =>
    import(/* webpackChunkName: "monitoring" */ '@/views/Project/Monitoring/TaskMonitoring')
const MonitoringCompleted = () =>
    import(/* webpackChunkName: "monitoring" */ '@/views/Project/Monitoring/MonitoringCompleted')
const MonitoringFooter = () =>
    import(/* webpackChunkName: "monitoring" */ '@/views/Project/Monitoring/MonitoringFooter')
const Template = () =>
    import(/* webpackChunkName: "activity" */ '@/views/Admin/Templates/Template')
const TemplateFooter = () =>
    import(/* webpackChunkName: "activity" */ '@/views/Admin/Templates/TemplateFooter')

const KnowledgeBase = () =>
    import(/* webpackChunkName: "knowledge-base" */ '@/views/KnowledgeBase/KnowledgeBase')
const Analytics = () =>
    import(/* webpackChunkName: "data-analytics" */ '@/views/Analytics/Analytics')
const Quicksight = () =>
    import(/* webpackChunkName: "analytics-quicksight" */ '@/views/Analytics/Quicksight/Quicksight')
const GQuicksight = () =>
    import(/* webpackChunkName: "analytics-quicksight" */ '@/views/Analytics/Quicksight/GQuicksight')
const QuicksightTemplated = () =>
    import(/* webpackChunkName: "analytics-templated-qs" */ '@/views/Analytics/QuicksightTemplated/QuicksightTemplated')
const QuicksightAuthor = () =>
    import(/* webpackChunkName: "analytics-qs-author" */ '@/views/Analytics/QuicksightAuthor/QuicksightAuthor')
const MapViewer = () =>
    import(/* webpackChunkName: "map-viewer" */ '@/views/MapViewer/MapViewer')

const LaboratoryAnalysisList = () =>
    import(/* webpackChunkName: "laboratory" */ '@/views/Project/LaboratoryAnalysis/LaboratoryAnalysisList')
const LaboratoryAnalysis = () =>
    import(/* webpackChunkName: "laboratory" */ '@/views/Project/LaboratoryAnalysis/LaboratoryAnalysis')
const LaboratoryAnalysisSubContent = () =>
    import(/* webpackChunkName: "laboratory" */ '@/views/Project/LaboratoryAnalysis/LaboratoryAnalysisSubContent')
const LaboratoryAnalysisFooter = () =>
    import(/* webpackChunkName: "laboratory" */ '@/views/Project/LaboratoryAnalysis/LaboratoryAnalysisFooter')

const FertilizationPlanList = () =>
    import(/* webpackChunkName: "fertilization" */ '@/views/Project/FertilizationPlan/FertilizationPlanList')
const FertilizationPlan = () =>
    import(/* webpackChunkName: "fertilization" */ '@/views/Project/FertilizationPlan/FertilizationPlan')
const FertilizationPlanFooter = () =>
    import(/* webpackChunkName: "fertilization" */ '@/views/Project/FertilizationPlan/FertilizationPlanFooter')

const ImageAnalysis = () =>
    import(/* webpackChunkName: "image-analysis" */ '@/views/Project/ImageAnalysis/ImageAnalysis')
const ImageAnalysisResult = () =>
    import(/* webpackChunkName: "image-analysis" */ '@/views/Project/ImageAnalysis/ImageAnalysisResult')
const ImageAnalysisResultFooter = () =>
    import(/* webpackChunkName: "image-analysis" */ '@/views/Project/ImageAnalysis/ImageAnalysisResultFooter')

const Irrigation = () =>
    import(/* webpackChunkName: "irrigation" */ '@/views/Project/Irrigation/Irrigation')
const IrrigationResult = () =>
    import(/* webpackChunkName: "irrigation" */ '@/views/Project/Irrigation/IrrigationResult')
const IrrigationResultFooter = () =>
    import(/* webpackChunkName: "irrigation" */ '@/views/Project/Irrigation/IrrigationResultFooter')
const IrrigationSubContent = () =>
    import(/* webpackChunkName: "irrigation" */ '@/views/Project/Irrigation/IrrigationSubContent')

const YieldEstimationList = () =>
    import(/* webpackChunkName: "yield-estimation" */ '@/views/Project/YieldEstimation/YieldEstimationList')
const YieldEstimationResult = () =>
    import(/* webpackChunkName: "yield-estimation" */ '@/views/Project/YieldEstimation/YieldEstimationResult')
const YieldEstimationResultFooter = () =>
    import(/* webpackChunkName: "yield-estimation" */ '@/views/Project/YieldEstimation/YieldEstimationResultFooter')

const DjiIntegration = () =>
    import(/* webpackChunkName: "dji" */ '@/views/DjiIntegration/DjiIntegration')


Vue.use(Router)
Vue.use(Meta)

const routes = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            children: [
                {
                    path: '/',
                    name: 'Projects',
                    component: Projects
                },
                {
                    path: ':userId/project/:id',
                    name: 'Project',
                    component: Project,
                    children: [
                        {
                            path: 'images',
                            name: 'ProjectImages',
                            components: {
                                default: Images,
                                footer: ImagesFooter
                            }
                        },
                        {
                            path: 'weather',
                            name: 'ProjectWeather',
                            components: {
                                default: Weather,
                                subContent: WeatherSubContent,
                                footer: WeatherFooter
                            }
                        },
                        {
                            path: 'stations',
                            name: 'ProjectStations',
                            components: {
                                default: Stations,
                                subContent: StationsSubContent
                            }
                        },
                        {
                            path: 'flights',
                            name: 'ProjectFlights',
                            components: {
                                default: Flights,
                                footer: FlightsFooter
                            }
                        },
                        {
                            path: 'grid',
                            name: 'ProjectGrid',
                            component: Grid
                        },
                        {
                            path: 'notes',
                            name: 'ProjectNotes',
                            component: Notes
                        },
                        {
                            path: 'stages',
                            name: 'ProjectStages',
                            component: Stages
                        },
                        {
                            path: 'note/:noteId',
                            name: 'ProjectNote',
                            components: {
                                default: Note,
                                footer: NoteFooter
                            }
                        },
                        {
                            path: 'prescriptions',
                            name: 'ProjectPrescriptions',
                            component: Prescriptions
                        },
                        {
                            path: 'prescription/:prescriptionId',
                            name: 'ProjectPrescription',
                            components: {
                                default: Prescription,
                                footer: PrescriptionFooter
                            }
                        },
                        {
                            path: 'imports',
                            name: 'ProjectImports',
                            components: {
                                default: Imports
                            }
                        },
                        {
                            path: 'import/:importId',
                            name: 'ProjectImport',
                            components: {
                                default: Import,
                                footer: ImportFooter
                            }
                        },
                        {
                            path: 'activities',
                            name: 'ProjectActivities',
                            components: {
                                default: Activities
                            }
                        },
                        {
                            path: 'activity/:formId',
                            name: 'ProjectActivity',
                            components: {
                                default: Activity,
                                footer: ActivityFooter
                            }
                        },
                        {
                            path: 'monitoring',
                            name: 'ProjectMonitoring',
                            components: {
                                default: Monitoring
                            }
                        },
                        {
                            path: 'monitoring/completed',
                            name: 'ProjectMonitoringCompleted',
                            components: {
                                default: MonitoringCompleted,
                                footer: MonitoringFooter
                            }
                        },
                        {
                            path: 'monitoring/:taskId',
                            name: 'ProjectTaskMonitoring',
                            components: {
                                default: TaskMonitoring,
                                footer: MonitoringFooter
                            }
                        },
                        {
                            path: 'laboratory-analysis',
                            name: 'ProjectLaboratoryAnalyses',
                            component: LaboratoryAnalysisList
                        },
                        {
                            path: 'laboratory-analysis/:analysisId',
                            name: 'ProjectLaboratoryAnalysis',
                            components: {
                                default: LaboratoryAnalysis,
                                subContent: LaboratoryAnalysisSubContent,
                                footer: LaboratoryAnalysisFooter
                            }
                        },
                        {
                            path: 'fertilization-plan',
                            name: 'ProjectFertilizationPlanList',
                            component: FertilizationPlanList
                        },
                        {
                            path: 'fertilization-plan/:fertilizationPlanId',
                            name: 'ProjectFertilizationPlan',
                            components: {
                                default: FertilizationPlan,
                                footer: FertilizationPlanFooter
                            }
                        },
                        {
                            path: 'image-analysis',
                            name: 'ProjectImageAnalysis',
                            components: {
                                default: ImageAnalysis
                            }
                        },
                        {
                            path: 'image-analysis/:analysisId',
                            name: 'ProjectImageAnalysisResult',
                            components: {
                                default: ImageAnalysisResult,
                                footer: ImageAnalysisResultFooter
                            }
                        },
                        {
                            path: 'subproject/:subProjectId',
                            name: 'SubProject',
                            components: {
                                default: SubProject,
                                footer: SubProjectFooter
                            }
                        },
                        {
                            path: 'irrigation',
                            name: 'ProjectIrrigation',
                            components: {
                                default: Irrigation
                            }
                        },
                        {
                            path: 'irrigation/:irrigationId',
                            name: 'ProjectIrrigationResult',
                            components: {
                                default: IrrigationResult,
                                footer: IrrigationResultFooter,
                                subContent: IrrigationSubContent
                            }
                        },
                        {
                            path: 'yield-estimation',
                            name: 'ProjectYieldEstimation',
                            components: {
                                default: YieldEstimationList
                            }
                        },
                        {
                            path: 'yield-estimation/:yieldEstimationId',
                            name: 'ProjectYieldEstimationResult',
                            components: {
                                default: YieldEstimationResult,
                                footer: YieldEstimationResultFooter
                            }
                        }
                    ]
                },
                {
                    path: ':userId/project/:projectId/flight/:flightId',
                    name: 'Flight',
                    component: Flight
                },
                {
                    path: '/account',
                    component: Account,
                    children: [
                        {
                            path: '/',
                            name: 'Profile',
                            components: {
                                default: Profile
                            }
                        },
                        {
                            path: 'subscription',
                            name: 'ProfileSubscription',
                            components: {
                                default: Subscription,
                                footer: SubscriptionFooter
                            }
                        }
                    ]
                },
                {
                    path: '/admin',
                    name: 'Admin',
                    component: Admin,
                    children: [
                        {
                            path: 'templates',
                            name: 'AdminTemplates',
                            components: {
                                default: Templates,
                                subContent: TemplatesSubContent
                            }
                        },
                        {
                            path: 'template/create',
                            name: 'Template',
                            components: {
                                default: Template,
                                footer: TemplateFooter,
                                subContent: TemplatesSubContent
                            }
                        },
                        {
                            path: 'users',
                            name: 'AdminUsers',
                            components: {
                                default: Users,
                                subContent: UsersSubContent
                            }
                        },
                        {
                            path: 'user/:userId',
                            name: 'AdminUser',
                            components: {
                                default: User,
                                footer: UserFooter,
                                subContent: UsersSubContent
                            }
                        },
                        {
                            path: 'teams',
                            name: 'AdminTeams',
                            components: {
                                default: TeamsAdmin,
                                subContent: TeamsAdminSubContent
                            }
                        },
                        {
                            path: 'team/:teamId',
                            name: 'AdminTeam',
                            components: {
                                default: NewTeam,
                                footer: NewTeamFooter,
                                subContent: TeamsAdminSubContent
                            }
                        },
                        {
                            path: 'projects',
                            name: 'AdminProjects',
                            components: {
                                default: AdminProjects,
                                subContent: AdminProjectsSubContent
                            }
                        },
                        {
                            path: 'project/:projectId',
                            name: 'AdminProject',
                            components: {
                                default: AdminProject,
                                subContent: AdminProjectsSubContent
                            }
                        },
                        {
                            path: 'integrations',
                            name: 'AdminIntegrations',
                            components: {
                                default: Integrations,
                                subContent: IntegrationsSubContent
                            }
                        },
                        {
                            path: 'integration/:integrationId',
                            name: 'AdminIntegration',
                            components: {
                                default: Integration,
                                subContent: IntegrationsSubContent
                            }
                        }
                    ]
                }
            ],
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                depth: 0
            }
        },
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                depth: 0
            }
        },
        {
            path: '/recover',
            name: 'RecoverPass',
            component: RecoverPass,
            meta: {
                depth: 0
            }
        },
        {
            path: '/new-pass',
            name: 'NewPass',
            component: NewPass,
            meta: {
                depth: 0
            }
        },
        {
            path: '/dji',
            name: 'DjiIntegration',
            component: DjiIntegration,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/account/subscription/plans',
            name: 'Plans',
            component: Plans,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/account/subscription/checkout',
            name: 'PlanCheckout',
            component: PlanCheckout,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/account/subscription/hectares',
            name: 'PlanHectares',
            component: PlanHectares,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/account/subscription/confirm',
            name: 'PlanConfirm',
            component: PlanConfirm,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/knowledge-base',
            name: 'KnowledgeBase',
            component: KnowledgeBase,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/analytics',
            name: 'Analytics',
            component: Analytics,
            children: [
                {
                    path: 'qs/:id',
                    name: 'Quicksight',
                    component: Quicksight
                },
                {
                    path: 'gqs/:id',
                    name: 'GQuicksight',
                    component: GQuicksight
                },
                {
                    path: 'gqst/:id',
                    name: 'GQuicksightTemplated',
                    component: QuicksightTemplated
                },
                {
                    path: 'qsauthor/:id',
                    name: 'QuicksightAuthor',
                    component: QuicksightAuthor
                }
            ],
            meta: {
                requiresAuth: true,
                depth: 1
            }
        },
        {
            path: '/map-viewer',
            name: 'MapViewer',
            component: MapViewer,
            meta: {
                depth: 0
            }
        },
        {
            path: '*',
            name: 'Default',
            component: Home,
            meta: {
                requiresAuth: true,
                depth: 1
            }
        }
    ]
})

routes.beforeEach(async (to, from, next) => {
    if (store.getters['aside/isNavigationButtonClick']) {
        store.commit('aside/setIsNavigationButtonClick', false)
    }
    await store.restored
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters['user/token'] || to.query.token) return next()
        const redirectQueryParam = to.fullPath != '/' && to.name != 'Default'
            ? `?redirect=${encodeURIComponent(to.fullPath)}` : ''
        return next('/login' + redirectQueryParam)
    }
    return next()
})

export default routes
