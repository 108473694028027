const state = {
    teams: [],
    selected: {}
}

// getters
const getters = {
    selected: (state) => state.selected,
    teams: (state) => state.teams
}

// actions
const actions = {
    async loadTeams({ commit }, { vm }) {
        const responseTeams = await vm.$api.team().getAll()
        const teams = responseTeams.body.data
        commit('setTeams', teams)
        return teams
    },
    async loadTeamById({ commit, rootGetters }, { vm, teamId }) {
        const responseTeam = await vm.$api.team().getMembersById(teamId, rootGetters['user/data']._id)
        commit('updateTeam', responseTeam.body.data)
        commit('setSelected', responseTeam.body.data)
        return responseTeam.body.data
    },
    async create({ commit }, { vm, team }) {
        const createResponse = await vm.$api.team().create(team)
        commit('addTeam', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        amplitude.getInstance().logEvent('TEAM_CREATE')
        return createResponse.body.message
    },
    async update({ state, commit, rootGetters }, { vm, team }) {
        const updateResponse = await vm.$api.team().update(team._id, rootGetters['user/data']._id, team)
        commit('updateTeam', updateResponse.body.data)
        if (state.selected._id == updateResponse.body.data._id) {
            commit('setSelected', updateResponse.body.data)
        }
        amplitude.getInstance().logEvent('TEAM_UPDATE')
        return updateResponse.body.message
    },
    async delete({ state, commit, rootGetters }, { vm, teamId }) {
        const deleteResponse = await vm.$api.team().delete(teamId, rootGetters['user/data']._id)
        commit('deleteTeam', deleteResponse.body.data)
        if (state.selected._id == deleteResponse.body.data._id) {
            commit('setSelected', {})
        }
        amplitude.getInstance().logEvent('TEAM_DELETE')
        return deleteResponse.body.message
    }
}

// mutations
const mutations = {
    setSelected(state, team) {
        state.selected = JSON.parse(JSON.stringify(team))
    },
    setTeams(state, teams) {
        state.teams = teams
    },
    addTeam(state, teamToSave) {
        const team = JSON.parse(JSON.stringify(teamToSave))
        state.teams.unshift(team)
    },
    updateTeam(state, teamToSave) {
        const team = JSON.parse(JSON.stringify(teamToSave))
        state.teams = state.teams.map((item) => (item._id == team._id ? team : item))
    },
    deleteTeam(state, team) {
        const teamIndex = state.teams.findIndex((item) => item._id == team._id)
        state.teams.splice(teamIndex, 1)
    },
    clearData(state) {
        state.teams = []
        state.selected = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
