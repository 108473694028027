import { Filesystem, Directory } from '@capacitor/filesystem'

const file = {}

function getDirectory(directoryName) {
    if (directoryName == 'data') {
        return Directory.Data
    } else if (directoryName == 'documents') {
        return Directory.Documents
    } else if (directoryName == 'library') {
        return Directory.Library
    } else if (directoryName == 'cache') {
        return Directory.Cache
    } else if (directoryName == 'external') {
        return Directory.External
    } else if (directoryName == 'externalStorage') {
        return Directory.ExternalStorage
    } return false
}

file.writeFile = async (imageBase64String, uri, directory = 'data') => {
    try {
        const directoryPath = getDirectory(directory)
        if (!directoryPath) throw 'Error en nombre de directory'
        const result = await Filesystem.writeFile({
            path: uri,
            data: imageBase64String,
            directory: directoryPath,
            recursive: true
        })
        return result
    } catch (err) {
        console.log(err)
        return false
    }
}

file.readFile = async (uri) => {
    try {
        const result = await Filesystem.readFile({
            path: uri
            // directory: Directory.Data
        })
        return result
    } catch (err) {
        console.log(err)
        return false
    }
}

file.readdir = async (uri) => {
    try {
        const result = await Filesystem.readdir({
            path: uri,
            directory: Directory.Data
        })
        return result
    } catch (err) {
        console.log(err)
        return false
    }
}

file.deleteFile = async (uri) => {
    try {
        const result = await Filesystem.deleteFile({
            path: uri
            // directory: Directory.Data
        })
        console.log(result)
        return result
    } catch (err) {
        console.log(err)
        return false
    }
}

export default {
    install: async function (Vue) {
        const fileSystem = {
            writeFile: file.writeFile,
            readFile: file.readFile,
            readdir: file.readdir,
            deleteFile: file.deleteFile
        }
        Vue.prototype.$fileSystem = fileSystem
    }
}