const state = {
    activeTour: false,
    showingTour: false,
    tour: null,
    toursComplete: {
        tourButtons: false,
        tourCreateProject: false,
        tourProject: false
    },
    tourProjectActive: false
}

// getters
const getters = {
    activeTour: (state) => state.activeTour,
    showingTour: (state) => state.showingTour,
    tour: (state) => state.tour,
    toursComplete: (state) => state.toursComplete,
    tourProjectActive: (state) => state.tourProjectActive,
    windowWidth: (state) => state.windowWidth
}

// actions
const actions = {}

// mutations
const mutations = {
    setActiveTour(state, value) {
        state.activeTour = value
    },
    setShowingTour(state, value) {
        state.showingTour = value
    },
    setTour(state, value) {
        state.tour = value
    },
    setTourComplete(state, { tour, vm, value }) {
        state.toursComplete[tour] = value
        const allComplete = Object.keys(state.toursComplete).every((key) => state.toursComplete[key])
        if (allComplete) {
            vm.$store.dispatch('user/updateTour', {
                vm,
                userToUpdate: {
                    toursComplete: true
                }
            })
        }
        state.activeTour = false
        state.showingTour = false
        state.tour = null
    },
    setCompleteAllTours(state) {
        const keys = Object.keys(state.toursComplete)
        for (let i = 0; i < keys.length; i++) {
            const tour = keys[i]
            state.toursComplete[tour] = true
        }
        state.activeTour = false
        state.showingTour = false
    },
    setTourProjectActive(state, value) {
        state.tourProjectActive = value
    },
    setWindowWidth(state, value) {
        state.windowWidth = value
    },
    nextTour(state) {
        if (state.activeTour) {
            setTimeout(state.tour.next, 200)
        }
    },
    hideTour(state) {
        if (state.activeTour) {
            state.tour.hide()
        }
    },
    cancelTour(state) {
        if(state.tour && state.activeTour){
            state.tour.complete()
            state.tour = null
            state.activeTour = false
        }
    },
    validateInput(state, value) {
        if (!state.activeTour) {
            return
        }
        if (value?.length > 0) {
            state.tour.next()
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
