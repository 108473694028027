const state = {
    visibility: true,
    availableDates: [],
    availables: [],
    waypoints: [],
    selected: {},
    selectedPoint: {},
    selectedDate: '',
    status: '',
    showUploadButton: false,
    uploadImages: false,
    uploadingImages: false,
    uploadProgress: 0
}

// getters
const getters = {
    availableDates: (state) => state.availableDates,
    availables: (state) => state.availables,
    waypoints: (state) => state.waypoints,
    selected: (state) => state.selected,
    selectedPoint: (state) => state.selectedPoint,
    selectedDate: (state) => state.selectedDate,
    status: (state) => state.status,
    visibility: (state) => state.visibility,
    showUploadButton: (state) => state.showUploadButton,
    uploadImages: (state) => state.uploadImages,
    uploadingImages: (state) => state.uploadingImages,
    uploadProgress: (state) => state.uploadProgress
}

// actions
const actions = {
    async loadAvailables({ commit, rootGetters }, { vm }) {
        const responseDates = await vm.$api.grid().availables(rootGetters['projects/selectedProject']._id)
        const dates = responseDates.body.data
        commit('setAvailableDates', dates)
        return dates
    },
    async loadGrids({ commit, rootGetters }, { vm, date }) {
        const responseDates = await vm.$api.grid().getList(rootGetters['projects/selectedProject']._id, date)
        const grids = responseDates.body.data
        commit('setGrids', grids)
        return grids
    }
}

// mutations
const mutations = {
    setAvailableDates(state, dates) {
        state.availableDates = dates
    },
    setGrids(state, grids) {
        state.availables = grids
    },
    setWaypoints(state, waypoints) {
        state.waypoints = waypoints
    },
    addWaypoint(state, waypoint) {
        state.waypoints.push(waypoint)
    },
    setSelected(state, grid) {
        state.selected = grid
        state.waypoints = []
    },
    setSelectedPoint(state, point) {
        state.selectedPoint = point
    },
    setSelectedDate(state, date) {
        state.selectedDate = date
    },
    setStatus(state, status) {
        state.status = status
    },
    setVisibility(state, show) {
        state.visibility = show
    },
    setShowUploadButton(state, value) {
        state.showUploadButton = value
    },
    setUploadImages(state, value) {
        state.uploadImages = value
    },
    setUploadingImages(state, value) {
        state.uploadingImages = value
    },
    setUploadProgress(state, value) {
        state.uploadProgress = value
    },
    clearData(state) {
        state.availableDates = []
        state.availables = []
        state.waypoints = []
        state.selected = {}
        state.selectedPoint = {}
        state.selectedDate = ''
        state.status = ''
        state.visibility = true
        state.showUploadButton = false
        state.uploadImages = false
        state.uploadingImages = false
        state.uploadProgress = 0
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
