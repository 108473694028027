const state = {
    isConnected: false,
    images: 0,
    waypoints: {},
    droneFlightPath: [[]],
    dronePosition: {},
    homePosition: {},
    nextWaypointIndex: 0,
    isNewFlightPath: true
}

// getters
const getters = {
    isConnected: (state) => state.isConnected,
    images: (state) => state.images,
    waypoints: (state) => state.waypoints,
    droneFlightPath: (state) => state.droneFlightPath,
    dronePosition: (state) => state.dronePosition,
    homePosition: (state) => state.homePosition,
    nextWaypointIndex: (state) => state.nextWaypointIndex
}

// actions
const actions = {}

// mutations
const mutations = {
    setIsConnected: (state, isConnected) => {
        state.isNewFlightPath = state.isNewFlightPath || state.isConnected != isConnected
        state.isConnected = isConnected
    },
    setImages: (state, images) => {
        state.images = images
    },
    setWaypoints: (state, waypoints) => {
        state.waypoints = waypoints
    },
    setDronePosition: (state, dronePosition) => {
        state.dronePosition = dronePosition
        if(state.isNewFlightPath || !state.isConnected) {
            state.droneFlightPath.push([])
            state.isNewFlightPath = false
        }
        state.droneFlightPath[state.droneFlightPath.length - 1]
            .push([dronePosition.lng, dronePosition.lat])
    },
    setHomePosition: (state, homePosition) => {
        state.homePosition = homePosition
    },
    setNextWaypointIndex: (state, nextWaypointIndex) => {
        state.nextWaypointIndex = nextWaypointIndex
    },
    clearDroneFlightPath: (state) => {
        state.droneFlightPath = [[]]
        state.isNewFlightPath = true
    },
    clearData(state) {
        state.isConnected = false
        state.images = 0
        state.waypoints = {}
        state.dronePosition = {}
        state.homePosition = {}
        state.nextWaypointIndex = 0
        state.droneFlightPath = [[]]
        state.isNewFlightPath = true
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
