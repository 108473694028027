const state = {
    selected: {},
    imports: [],
    variablesImportMap: [],
    importDataJson: {},
    variableSelected: undefined,
    section: '',
    editImport: false,
    generateImport: false
}

// getters
const getters = {
    selected: (state) => state.selected,
    importDataJson: (state) => state.importDataJson,
    imports: (state) => state.imports,
    variablesImportMap: (state) => state.variablesImportMap,
    variableSelected: (state) => state.variableSelected,
    section: (state) => state.section,
    editImport: (state) => state.editImport,
    generateImport: (state) => state.generateImport
}

// actions
const actions = {
    async loadImports({ commit, rootGetters }, { vm }) {
        const responseImports = await vm.$api.import()
            .getFromProjectWithDrone(rootGetters['projects/selectedProject']._id)
        const imports = responseImports.body.data
        commit('setImports', imports)
        return imports
    },
    async loadImportById({ commit, rootGetters }, { vm, importId }) {
        let responseImport = await vm.$api.import().getById(importId, rootGetters['projects/selectedProject']._id)
        responseImport = {...responseImport.body.data, section: 'map'}
        commit('setSelected', responseImport)
        return responseImport
    },
    async loadGridById({ commit, rootGetters }, { vm, gridId }) {
        let responseGrid = await vm.$api.grid().getById(gridId, rootGetters['projects/selectedProject']._id)
        responseGrid = {...responseGrid.body.data, section: 'grid'}
        commit('setSelected', responseGrid)
        return responseGrid
    },
    async loadDroneById({ commit, rootGetters }, { vm, droneId }) {
        let responseDrone = await vm.$api.drone().getById(droneId, rootGetters['projects/selectedProject']._id)
        responseDrone = {...responseDrone.body.data, section: 'drone'}
        commit('setSelected', responseDrone)
        return responseDrone
    },
    async create({ commit }, { vm, importToSave }) {
        const createResponse = await vm.$api.import().create(importToSave)
        commit('addImport', createResponse.body.data)
        commit('setSelected', createResponse.body.data)
        return createResponse.body.message
    },
    async update({ commit, rootGetters }, { vm, importToSave }) {
        const updateResponse = await vm.$api
            .import()
            .update(importToSave._id, rootGetters['projects/selectedProject']._id, importToSave)
        commit('updateImport', updateResponse.body.data)
        return updateResponse.body.message
    },
    async updateName({ commit, getters, rootGetters }, { vm, importToSave }) {
        let updateResponse = {}
        if(importToSave.section == 'drone') {
            updateResponse = await vm.$api
                .drone()
                .updateName(importToSave._id, rootGetters['projects/selectedProject']._id, importToSave)
            updateResponse.body.data._id = updateResponse.body.data.droneId
            updateResponse.body.data.section = 'drone'
        } else if(importToSave.section == 'map') {
            updateResponse = await vm.$api
                .import()
                .updateName(importToSave._id, rootGetters['projects/selectedProject']._id, importToSave)
            updateResponse.body.data.section = 'map'

        } else if(importToSave.section == 'grid') {
            updateResponse = await vm.$api
                .grid()
                .update(importToSave._id, rootGetters['projects/selectedProject']._id, importToSave)
            updateResponse.body.data.section = 'grid'
        }
        if(getters.selected?._id) {
            commit('setSelected', updateResponse.body.data)
        }
        commit('updateImport', updateResponse.body.data)
        return updateResponse.body.message
    },
    async delete({ commit, rootGetters }, { vm, importMap }) {
        const deleteResponse = await vm.$api.import().delete(importMap._id, rootGetters['projects/selectedProject']._id)
        commit('deleteImport', importMap)
        return deleteResponse.body.message
    }
}

// mutations
const mutations = {
    setSelected(state, importMap) {
        state.selected = importMap
    },
    setImportDataJson(state, data) {
        state.importDataJson = data
    },
    setVariables(state, variables) {
        state.variablesImportMap = variables
    },
    setVariableSelected(state, variable) {
        state.variableSelected = variable
    },
    addImport(state, importToSave) {
        state.imports.unshift(importToSave)
    },
    updateImport(state, importToSave) {
        state.imports = state.imports.map((item) => (item._id == importToSave._id ? importToSave : item))
    },
    deleteImport(state, importMap) {
        const importIndex = state.imports.findIndex((item) => item._id == importMap._id)
        state.imports.splice(importIndex, 1)
    },
    setImports(state, imports) {
        state.imports = imports
    },
    setSection(state, section) {
        state.section = section
    },
    setEditImport(state, value) {
        state.editImport = value
    },
    setGenerateImport(state, value) {
        state.generateImport = value
    },
    clearData(state) {
        state.selected = {}
        state.imports = []
        state.variablesImportMap = []
        state.importDataJson = {}
        state.variableSelected = undefined
        state.generateImport = false
        state.section = ''
        state.editImport = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
