const state = {
    isOpen: true,
    isFullscreen: false,
    isMobile: false,
    position: 0,
    oldPosition: 0,
    transition: false,
    isLocked: false,
    angleOrientation: 0,
    subContent: false,
    subContentOpen: false,
    overContent: false,
    showTimer: false,
    isNavigationButtonClick: false,
    editionMode: false,
    controllerMode: false
}

// getters
const getters = {
    isOpen: (state) => state.isOpen,
    isFullscreen: (state) => state.isFullscreen,
    isMobile: (state) => state.isMobile,
    position: (state) => state.position,
    transition: (state) => state.transition,
    isLocked: (state) => state.isLocked,
    angleOrientation: (state) => state.angleOrientation,
    subContentOpen: (state) => state.subContentOpen,
    subContent: (state) => state.subContent,
    overContent: (state) => state.overContent,
    showTimer: (state) => state.showTimer,
    isNavigationButtonClick: (state) => state.isNavigationButtonClick,
    editionMode: (state) => state.editionMode,
    controllerMode: (state) => state.controllerMode
}

// actions
const actions = {}

// mutations
const mutations = {
    setIsOpen(state, value) {
        state.isOpen = value
    },
    setIsUnlocked(state) {
        state.isLocked = false
    },
    setPosition(state, value) {
        state.position = value
        state.oldPosition = state.position
        state.isFullscreen = state.position < window.innerHeight * 0.45
        state.isMobile = window.innerWidth < 768
    },
    setMiddlePosition(state) {
        state.isMobile = window.innerWidth < 768
        if (state.isMobile) {
            state.transition = true
            state.position = window.innerHeight * 0.45
            state.isFullscreen = state.position < window.innerHeight * 0.45
            setTimeout(() => {
                state.transition = false
            }, 200)
        }
    },
    setFullscreen(state, { isLocked } = {}) {
        state.transition = true
        state.isLocked = isLocked || false
        state.position = state.showTimer ? 20 : 0
        state.isFullscreen = true
        state.isMobile = window.innerWidth < 768
        setTimeout(() => {
            state.transition = false
        }, 200)
    },
    moveToPosition(state, value) {
        state.transition = true
        state.position = value
        state.oldPosition = state.position
        state.isFullscreen = state.position < window.innerHeight * 0.45
        state.isMobile = window.innerWidth < 768
        setTimeout(() => {
            state.transition = false
        }, 200)
    },
    hide(state, { isLocked, isFullscreen } = {}) {
        state.isOpen = false
        state.isLocked = isLocked || false
        state.transition = true
        state.oldPosition = state.position
        state.position = Math.max(state.position, window.innerHeight - (isFullscreen ? 10 : 54))
        state.isFullscreen = false
        state.isMobile = window.innerWidth < 768
        setTimeout(() => {
            state.transition = false
        }, 200)
    },
    restore(state) {
        state.isOpen = true
        state.isLocked = false
        state.transition = true
        state.position = state.oldPosition
        state.isFullscreen = state.position < window.innerHeight * 0.45
        state.isMobile = window.innerWidth < 768
        setTimeout(() => {
            state.transition = false
        }, 200)
    },
    setAngleOrientation(state, angle) {
        state.angleOrientation = angle
    },
    setSubContentOpen(state, value) {
        state.subContentOpen = value
    },
    setSubContent(state, value) {
        state.subContent = value
    },
    setOverContent(state, value) {
        state.overContent = value
    },
    setShowTimer(state, value) {
        state.showTimer = value
    },
    setIsNavigationButtonClick(state, value) {
        state.isNavigationButtonClick = value
    },
    setEditionMode(state, value) {
        state.editionMode = value
    },
    setControllerMode(state, value) {
        state.controllerMode = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
