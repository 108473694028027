/* eslint-disable max-len */
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite'

async function queryDb(query, values = []) {
    const dbName = 'wiki'
    const sqlite = new SQLiteConnection(CapacitorSQLite)
    const ret = await sqlite.checkConnectionsConsistency()
    const isConn = (await sqlite.isConnection(dbName)).result
    let db
    if (ret.result && isConn) {
        db = await sqlite.retrieveConnection(dbName)
    } else {
        db = await sqlite.createConnection(dbName, false, 'no-encryption', 1)
    }
    await db.open()
    try {
        const result = await db.query(query, values)
        await sqlite.closeConnection(dbName)
        return result['values']
    } catch (error) {
        console.log(error)
        await sqlite.closeConnection(dbName)
        return false
    }
}

const wiki = {}

wiki.isAvailable = async () => {
    try {
        const results = await queryDb('SELECT * FROM countries')
        if(results) return (results.length)
        return results
    } catch (err) {
        console.log(err)
        return false
    }
}

wiki.getVersion = async () => {
    try {
        const result = await queryDb('SELECT * FROM meta WHERE key="version"')
        return result['0']['value']
    } catch (err) {
        console.log(err)
        return '0'
    }
}

/* WEEDS */

wiki.getWeeds = async (searchText) => {
    try {
        let result
        if (searchText) {
            result = await queryDb(
                `
				SELECT
					*
				FROM
					weeds
				WHERE
					name_es LIKE "%" || ?1 || "%" OR
					name_en LIKE "%" || ?1 || "%" OR
					name_br LIKE "%" || ?1 || "%" OR
					description_es LIKE "%" || ?1 || "%" OR
					description_en LIKE "%" || ?1 || "%" OR
					description_br LIKE "%" || ?1 || "%"
			`,
                [searchText]
            )
        } else {
            result = await queryDb('SELECT * FROM weeds')
        }
        const r = result.map((e) => ({
            id: e['name_lat'],
            title: e['name_lat'],
            subtitle: e['name_es'] || e['name_en'] || e['name_br'],
            thumb: e['thumb']
        }))
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

wiki.getWeed = async (name) => {
    try {
        const resultWeed = await queryDb('SELECT * FROM weeds WHERE name_lat=?', [name])
        if (!resultWeed) return false

        const r = resultWeed['0']

        const productsQuery = `
			SELECT
				product_ratings_for_weeds.product_id,
				product_ratings_for_weeds.rating,
				products.name,
				products.country_id,
				products.category_id,
				countries.name AS country
			FROM
				product_ratings_for_weeds
					LEFT JOIN products ON product_ratings_for_weeds.product_id = products.id
					LEFT JOIN countries ON products.country_id = countries.id
			WHERE
				product_ratings_for_weeds.weed_name = ?
                AND
                products.country_id = (SELECT value FROM meta WHERE key LIKE 'country_id')
		`
        const resultProducts = await queryDb(productsQuery, [name])

        r['products'] = resultProducts

        const imgs = []
        for (let i = 1; i <= 12; i++) {
            if (r['img' + i]) imgs.push(r['img' + i])
        }
        r['imgs'] = imgs

        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

/* DISEASES */

wiki.getDiseases = async (searchText) => {
    try {
        let result
        if (searchText) {
            result = await queryDb(
                `
				SELECT
					*
				FROM
					diseases
				WHERE
					name_es LIKE "%" || ?1 || "%" OR
					name_en LIKE "%" || ?1 || "%" OR
					name_br LIKE "%" || ?1 || "%" OR
					description_es LIKE "%" || ?1 || "%" OR
					description_en LIKE "%" || ?1 || "%" OR
					description_br LIKE "%" || ?1 || "%"
			`,
                [searchText]
            )
        } else {
            result = await queryDb('SELECT * FROM diseases')
        }
        const r = result.map((e) => ({
            id: e['name_lat'],
            title: e['name_lat'],
            subtitle: e['name_es'] || e['name_en'] || e['name_br'],
            thumb: e['thumb']
        }))
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

wiki.getDisease = async (name) => {
    try {
        const resultDisease = await queryDb('SELECT * FROM diseases WHERE name_lat=?', [name])
        if (!resultDisease) return false

        const r = resultDisease['0']

        const productsQuery = `
			SELECT
				product_ratings_for_diseases.product_id,
				product_ratings_for_diseases.rating,
				products.name,
				products.country_id,
				products.category_id,
				countries.name AS country
			FROM
				product_ratings_for_diseases
					LEFT JOIN products ON product_ratings_for_diseases.product_id = products.id
					LEFT JOIN countries ON products.country_id = countries.id
			WHERE
				product_ratings_for_diseases.disease_name = ?
                AND
                products.country_id = (SELECT value FROM meta WHERE key LIKE 'country_id')
		`
        const resultProducts = await queryDb(productsQuery, [name])

        r['products'] = resultProducts

        const imgs = []
        for (let i = 1; i <= 12; i++) {
            if (r['img' + i]) imgs.push(r['img' + i])
        }
        r['imgs'] = imgs

        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

/* PESTS */

wiki.getPests = async (searchText) => {
    try {
        let result
        if (searchText) {
            result = await queryDb(
                `
				SELECT
					*
				FROM
					pests
				WHERE
					name_es LIKE "%" || ?1 || "%" OR
					name_en LIKE "%" || ?1 || "%" OR
					name_br LIKE "%" || ?1 || "%" OR
					description_es LIKE "%" || ?1 || "%" OR
					description_en LIKE "%" || ?1 || "%" OR
					description_br LIKE "%" || ?1 || "%"
			`,
                [searchText]
            )
        } else {
            result = await queryDb('SELECT * FROM pests')
        }
        const r = result.map((e) => ({
            id: e['name_lat'],
            title: e['name_lat'],
            subtitle: e['name_es'] || e['name_en'] || e['name_br'],
            thumb: e['thumb']
        }))
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

wiki.getPest = async (name) => {
    try {
        const resultPest = await queryDb('SELECT * FROM pests WHERE name_lat=?', [name])
        if (!resultPest) return false

        const r = resultPest['0']

        const productsQuery = `
			SELECT
				product_ratings_for_pests.product_id,
				product_ratings_for_pests.rating,
				products.name,
				products.country_id,
				products.category_id,
				countries.name AS country
			FROM
				product_ratings_for_pests
					LEFT JOIN products ON product_ratings_for_pests.product_id = products.id
					LEFT JOIN countries ON products.country_id = countries.id
			WHERE
				product_ratings_for_pests.pest_name = ?
                AND
                products.country_id = (SELECT value FROM meta WHERE key LIKE 'country_id')
		`
        const resultProducts = await queryDb(productsQuery, [name])

        r['products'] = resultProducts

        const imgs = []
        for (let i = 1; i <= 12; i++) {
            if (r['img' + i]) imgs.push(r['img' + i])
        }
        r['imgs'] = imgs

        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

/* CROPS */

wiki.getCrops = async () => {
    try {
        const q = `
			SELECT
				id,
				IFNULL(name_es, IFNULL(name_en, name_br)) AS title
			FROM
				crops
		`
        const result = await queryDb(q)
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getCrop = async (id) => {
    try {
        const resultCrop = await queryDb('SELECT * FROM crops WHERE id=?', [String(id)])
        if (!resultCrop) return false

        const r = resultCrop['0']

        const productsQuery = `
			SELECT
				products.id AS product_id,
				products.name,
				products.country_id,
				products.category_id,
				countries.name AS country,
				CAST(CAST(product_ratings_for_crops.bbch_min AS INT) AS TEXT) || "-" || CAST(CAST(product_ratings_for_crops.bbch_max AS INT) AS TEXT) AS bbch,
				CAST(CAST(product_ratings_for_crops.rate_min AS INT) AS TEXT) || "-" || CAST(CAST(product_ratings_for_crops.rate_max AS INT) AS TEXT) || " " || product_ratings_for_crops.rate_unit AS rate,
				product_ratings_for_crops.preharvest_interval
			FROM
				product_ratings_for_crops
					LEFT JOIN products ON product_ratings_for_crops.product_id = products.id
					LEFT JOIN countries ON products.country_id = countries.id
			WHERE
				product_ratings_for_crops.crop_id = ?
                AND
                products.country_id = (SELECT value FROM meta WHERE key LIKE 'country_id')
		`
        const resultProducts = await queryDb(productsQuery, [String(id)])

        r['products'] = resultProducts
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

/* SUBSTANCES */

wiki.getSubstances = async () => {
    try {
        const result = await queryDb('SELECT id, name_en AS title FROM substances')
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getSubstance = async (id) => {
    try {
        const result = await queryDb('SELECT id, name_en AS title FROM substances WHERE id=?', [String(id)])
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

/* PRODUCTS */

wiki.getProducts = async () => {
    try {
        const productsQuery = `
			SELECT
				id,
				name AS title,
				category_id
			FROM
				products
			LIMIT 100
		`
        const result = await queryDb(productsQuery)
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getProduct = async (id) => {
    try {
        const resultProduct = await queryDb('SELECT * FROM products WHERE id=?', [String(id)])
        if (!resultProduct) return false

        const r = resultProduct['0']

        const cropsQuery = `
			SELECT
				product_ratings_for_crops.crop_id,
				CAST(CAST(product_ratings_for_crops.bbch_min AS INT) AS TEXT) || "-" || CAST(CAST(product_ratings_for_crops.bbch_max AS INT) AS TEXT) AS bbch,
				CAST(CAST(product_ratings_for_crops.rate_min AS INT) AS TEXT) || "-" || CAST(CAST(product_ratings_for_crops.rate_max AS INT) AS TEXT) || " " || product_ratings_for_crops.rate_unit AS rate,
				product_ratings_for_crops.preharvest_interval,
				crops.name_es,
				crops.name_en,
				crops.name_br,
                products.country_id
			FROM
				product_ratings_for_crops
					LEFT JOIN crops ON product_ratings_for_crops.crop_id = crops.id
                    LEFT JOIN products ON product_ratings_for_crops.product_id = products.id
			WHERE
				product_ratings_for_crops.product_id = ?
                AND
                products.country_id = (SELECT value FROM meta WHERE key LIKE 'country_id')
		`
        const resultCrops = await queryDb(cropsQuery, [String(id)])

        r['crops'] = resultCrops
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

/* MANUFACTURERS */

wiki.getManufacturers = async () => {
    try {
        const result = await queryDb('SELECT id, name AS title FROM manufacturers')
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getManufacturer = async (id) => {
    try {
        const result = await queryDb('SELECT id, name AS title FROM manufacturers WHERE id=?', [String(id)])
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

/* EQUIPMENT */

wiki.getEquipments = async () => {
    try {
        const result = await queryDb('SELECT DISTINCT marca AS title, marca AS id FROM equipamiento')
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getEquipment = async (id) => {
    try {
        const result = await queryDb('SELECT marca, modelo FROM equipamiento WHERE marca=?', [String(id)])
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

/* FERTILIZERS */

wiki.getFertilizers = async () => {
    try {
        const result = await queryDb('SELECT nombre AS title, nombre AS id FROM fertilizantes')
        return { body: result }
    } catch (err) {
        console.log(err)
    }
}

wiki.getFertilizer = async (id) => {
    try {
        const resultFertilizer = await queryDb('SELECT * FROM fertilizantes WHERE nombre=?', [String(id)])
        const r = resultFertilizer[0]
        return { body: r }
    } catch (err) {
        console.log(err)
    }
}

export default {
    install: async function (Vue) {
        const offlineWiki = {
            isAvailable: wiki.isAvailable,
            getVersion: wiki.getVersion,
            weeds: () => ({
                get: wiki.getWeeds,
                getOne: wiki.getWeed
            }),
            pests: () => ({
                get: wiki.getPests,
                getOne: wiki.getPest
            }),
            diseases: () => ({
                get: wiki.getDiseases,
                getOne: wiki.getDisease
            }),
            crops: () => ({
                get: wiki.getCrops,
                getOne: wiki.getCrop
            }),
            substances: () => ({
                get: wiki.getSubstances,
                getOne: wiki.getSubstance
            }),
            products: () => ({
                get: wiki.getProducts,
                getOne: wiki.getProduct
            }),
            manufacturers: () => ({
                get: wiki.getManufacturers,
                getOne: wiki.getManufacturer
            }),
            equipment: () => ({
                get: wiki.getEquipments,
                getOne: wiki.getEquipment
            }),
            fertilizers: () => ({
                get: wiki.getFertilizers,
                getOne: wiki.getFertilizer
            })
        }
        Vue.prototype.$offlineWiki = offlineWiki
    }
}
