import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import VueGtag from 'vue-gtag'
import VueResource from 'vue-resource'
import Cache from '@/services/cache'
import Token from '@/services/token'
import router from '@/router'
import dayjs from 'dayjs'
import customParseFormat  from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/es'
import toastr from 'toastr'
import VueI18n from 'vue-i18n'
import store from '@/store'
import api from '@/services/api'
import tiles from '@/services/tiles'
import offlineWiki from '@/services/offlineWiki'
import VueShepherd from 'vue-shepherd'
import fileSystem from '@/services/fileSystem'

import { messages } from '@/locales/index.js'

import 'leaflet'
import 'leaflet.vectorgrid'
import '@/assets/js/leaflet-side-by-side'
import '@/assets/js/leaflet-offline'
import '@/assets/js/leaflet-tilelayer-boundary-mapper'
import 'leaflet-rotatedmarker'
import 'leaflet.markercluster'
import '@geoman-io/leaflet-geoman-free'

import '@codetrix-studio/capacitor-google-auth'
import '@capacitor-community/sqlite'

import '@/plugins/service-worker'

Vue.use(
    VueGtag,
    {
        config: {
            id: process.env.VUE_APP_ANALYTICS_ID
        }
    },
    router
)

Vue.use(VueResource)
Vue.use(VueI18n)
Vue.use(Cache)
Vue.use(Token)
Vue.use(api)
Vue.use(tiles)
Vue.use(offlineWiki)
Vue.use(VueShepherd)
Vue.use(fileSystem)

Vue.config.productionTip = false
Vue.config.performance = true
Vue.http.options.root = process.env.VUE_APP_SERVER

// Config toastr alert
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    onclick: null,
    showDuration: '300000',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
}

// Variables prototypes
Vue.prototype.$dayjs = dayjs.extend(customParseFormat)
Vue.prototype.$toastr = toastr

// Traslate
const i18n = new VueI18n({
    locale: navigator.language.split('-')[0],
    silentFallbackWarn: true,
    fallbackLocale: 'es',
    messages
})

new Vue({
    vuetify,
    i18n,
    router,
    store,
    data() {
        return {
            map: {
                pm: {}
            },
            mapLeft: {},
            mapRight: {},
            layerRoot: '',
            compareLayerLeft: {},
            compareLayerRight: {},
            leftCompareTile: {},
            rightCompareTile: {},
            layerRootType: 'https://maps.geo.sa-east-1.amazonaws.com/maps/v0/maps/{id}/tiles/{z}/{x}/{y}?key={token}',
            publicPath: process.env.BASE_URL,
            serverUrl: process.env.VUE_APP_SERVER,
            tilesUrl: process.env.VUE_APP_TILES,
            principalPolygon: {},
            cropPolygon: null,
            layerEditActive: {},
            showMenu: true,
            sideBySide: {},
            importMapLayer: {},
            leftTile: {},
            rightTile: {},
            tileActive: {
                layer: {},
                provider: 'satelite',
                algoritmo: 'TRUE_COLOR',
                cmap: '',
                minValue: 0,
                maxValue: 0,
                minIndex: 0,
                maxIndex: 0,
                scouting: false,
                year: '',
                month: '',
                day: ''
            },
            showIndexesValues: false,
            taskLayer: {},
            formLayer: {},
            pointFormLayer: {},
            analysisLayer: {},
            polygonLayerPlantCount: {},
            clusterPlantsLayer: {}
        }
    },
    render: (h) => h(App)
}).$mount('#app')
