import router from '../router'

export default {
    install(Vue) {
        const session = window.sessionStorage

        Vue.http.interceptors.push((request) => {
            if (
                router.app._route.matched[1] &&
                router.app._route.matched[1].name == 'Project' &&
                request.method == 'GET'
            ) {
                const cache = session.getItem(`CACHE_${request.getUrl()}`)
                if (cache) {
                    return request.respondWith(JSON.parse(cache), { status: 200, statusText: 'Ok' })
                }
            } else if (router.app._route.name == 'Projects' && request.method == 'GET') {
                session.clear()
            }

            return (response) => {
                if (
                    response.status === 200 &&
                    router.app._route.matched[1] &&
                    router.app._route.matched[1].name == 'Project' &&
                    request.method == 'GET'
                ) {
                    if (request.getUrl().match(/.\/(stats|weather)\/./)) {
                        session.setItem(`CACHE_${request.getUrl()}`, JSON.stringify(response.body))
                    }
                }
            }
        })
    }
}
