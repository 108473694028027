import { ulid } from 'ulid'

const state = {
    useSimulator: false,
    yieldEstimationList: [],
    selected: {},
    selectedSample: {},
    editYieldEstimation: false,
    takingSamples: false,
    editSample: false,
    readSample: false,
    yieldEstimationDialog: false,
    resultDataJson: {},
    saveStatus: false,
    controlPlantsList: [],
    noteQueue: { readyToSave: false, notes: [] },
    pointsLayer: {},
    statusAnalysisProcess: null,
    sampleDeleted: false
}

// getters
const getters = {
    yieldEstimationList: (state) => state.yieldEstimationList,
    selected: (state) => state.selected,
    selectedSample: (state) => state.selectedSample,
    editYieldEstimation: (state) => state.editYieldEstimation,
    takingSamples: (state) => state.takingSamples,
    editSample: (state) => state.editSample,
    readSample: (state) => state.readSample,
    yieldEstimationDialog: (state) => state.yieldEstimationDialog,
    resultDataJson: (state) => state.resultDataJson,
    controlPlantsList: (state) => state.controlPlantsList,
    noteQueue: (state) => state.noteQueue,
    saveStatus: (state) => state.saveStatus,
    pointsLayer: (state) => state.pointsLayer,
    statusAnalysisProcess: (state) => state.statusAnalysisProcess,
    sampleDeleted: (state) => state.sampleDeleted
}

// actions
const actions = {
    async loadYieldEstimationAnalyses({ commit, getters, rootGetters }, { vm }) {
        if (!getters.yieldEstimationList.length) {
            const responseAnalyses = await vm.$api
                .yieldEstimation()
                .getFromProject(rootGetters['projects/selectedProject']._id)
            commit('setYieldEstimationList', responseAnalyses.body.data)
            return responseAnalyses.body.data
        }
        return getters.yieldEstimationList
    },
    async loadById({ commit, rootGetters }, { vm, yieldEstimationId }) {
        const response = await vm.$api
            .yieldEstimation().getById(yieldEstimationId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', response.body.data)
        return response.body.data
    },
    async create({ commit, rootGetters }, { vm, yieldEstimationToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const createResponse = await vm.$api.yieldEstimation().create(yieldEstimationToSave)
            commit('addAnalysis', createResponse.body.data)
            if (yieldEstimationToSave.imageAnalysisClusterId == 'new') {
                commit('setStatusAnalysisProcess', createResponse.body.data)
            }
            return createResponse
        }
        const yieldEstimationCreated = { ...yieldEstimationToSave }
        yieldEstimationCreated._id = ulid()
        commit('addAnalysis', yieldEstimationCreated)
        return yieldEstimationCreated
    },
    async update({ commit, rootGetters, getters }, { vm, yieldEstimationToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const updateResponse = await vm.$api
                .yieldEstimation()
                .update(yieldEstimationToSave._id, rootGetters['projects/selectedProject']._id, yieldEstimationToSave)
            commit('updateYieldEstimation', updateResponse.body.data)
            if(getters.selected?._id) {
                commit('setSelected', updateResponse.body.data)
            }
            return updateResponse
        }
        const yieldEstimationUpdated = { ...yieldEstimationToSave }
        commit('updateYieldEstimation', yieldEstimationUpdated)
        if(getters.selected?._id) {
            commit('setSelected', yieldEstimationUpdated)
        }
        return yieldEstimationUpdated
    },
    async delete({ commit, rootGetters }, { vm, yieldEstimation }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const deleteResponse = await vm.$api
                .yieldEstimation()
                .delete(yieldEstimation._id, rootGetters['projects/selectedProject']._id)
            commit('deleteYieldEstimation', yieldEstimation)
            return deleteResponse.body.message
        }
        commit('deleteYieldEstimation', yieldEstimation)
    },
    async addSample({ commit, rootGetters }, { vm, yieldEstimationId, sample }) {
        const updateResponse = await vm.$api
            .yieldEstimation()
            .addSample(yieldEstimationId, rootGetters['projects/selectedProject']._id, sample)
        commit('updateYieldEstimation', updateResponse.body.data)
        commit('setSelected', updateResponse.body.data)
        return updateResponse
    },
    async updateSample({ commit, rootGetters }, { vm, yieldEstimationId, sample }) {
        const updateResponse = await vm.$api
            .yieldEstimation()
            .updateSample(yieldEstimationId, rootGetters['projects/selectedProject']._id, sample)
        commit('updateYieldEstimation', updateResponse.body.data)
        commit('setSelected', updateResponse.body.data)
        return updateResponse
    },
    async deleteSample({ commit, rootGetters }, { vm, yieldEstimationId, sampleId }) {
        const updateResponse = await vm.$api
            .yieldEstimation()
            .deleteSample(yieldEstimationId, rootGetters['projects/selectedProject']._id, sampleId)
        commit('updateYieldEstimation', updateResponse.body.data)
        commit('setSelected', updateResponse.body.data)
        return updateResponse.body.message
    },
    async startYieldEstimation({ commit, rootGetters }, { vm, yieldEstimationId }) {
        const updateResponse = await vm.$api
            .yieldEstimation()
            .startYieldEstimation(yieldEstimationId, rootGetters['projects/selectedProject']._id)
        commit('updateYieldEstimation', updateResponse.body.data)
        commit('setStatusAnalysisProcess', updateResponse.body.data)
        return updateResponse
    },
    async checkStatusYieldEstimation({ commit, rootGetters }, { vm, yieldEstimationId }) {
        const response = await vm.$api.yieldEstimation()
            .getStatus(yieldEstimationId, rootGetters['projects/selectedProject']._id)
        commit('setStatusAnalysisProcess', response.body.data)
        return response.body.data
    }
}

// mutations
const mutations = {
    addAnalysis(state, yieldEstimation) {
        state.yieldEstimationList.unshift(yieldEstimation)
    },
    updateYieldEstimation(state, yieldEstimationToSave) {
        state.yieldEstimationList =
            state.yieldEstimationList.map((item) =>
                (item._id == yieldEstimationToSave._id ? yieldEstimationToSave : item))
    },
    deleteYieldEstimation(state, yieldEstimation) {
        const yieldEstimationIndex = state.yieldEstimationList.findIndex((item) => item._id == yieldEstimation._id)
        state.yieldEstimationList.splice(yieldEstimationIndex, 1)
    },
    setYieldEstimationList(state, yieldEstimationList) {
        state.yieldEstimationList = yieldEstimationList
    },
    setSelected(state, yieldEstimation) {
        state.selected = yieldEstimation
    },
    setSelectedSample(state, sample) {
        state.selectedSample = sample
    },
    setTakingSamples(state, value) {
        state.takingSamples = value
    },
    setEditSample(state, value) {
        state.editSample = value
    },
    setReadSample(state, value) {
        state.readSample = value
    },
    setEditYieldEstimation(state, value) {
        state.editYieldEstimation = value
    },
    setAnalysisDialog(state, value) {
        state.yieldEstimationDialog = value
    },
    setResultDataJson(state, data) {
        state.resultDataJson = data
    },
    setSaveStatus(state, status) {
        state.saveStatus = status
    },
    setControlPlantsList(state, controlPlantsList) {
        state.controlPlantsList = controlPlantsList
    },
    setNoteQueue(state, value) {
        state.noteQueue = value
    },
    setPointsLayer(state, value) {
        state.pointsLayer = value
    },
    setStatusAnalysisProcess(state, statusAnalysisProcess) {
        state.statusAnalysisProcess = statusAnalysisProcess
    },
    setSampleDeleted(state, value) {
        state.sampleDeleted = value
    },
    clearAnalysis(state) {
        state.selected = {}
        state.selectedSample = {}
        state.editYieldEstimation = false
        state.takingSamples = false
        state.editSample = false
        state.readSample = false
        state.yieldEstimationDialog = false
        state.controlPlantsList = []
        state.noteQueue = { readyToSave: false, notes: [] },
        state.pointsLayer = {},
        state.sampleDeleted = false
    },
    clearData(state) {
        state.yieldEstimationList = []
        state.selected = {}
        state.selectedSample = {}
        state.editYieldEstimation = false
        state.takingSamples = false
        state.editSample = false
        state.readSample = false
        state.yieldEstimationDialog = false
        state.resultDataJson = {},
        state.saveStatus = false,
        state.controlPlantsList = []
        state.noteQueue = { readyToSave: false, notes: [] },
        state.pointsLayer = {},
        state.sampleDeleted = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
