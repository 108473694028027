const state = {
    plans: [],
    selected: {},
    currentPage: 1,
    newPlan: false,
    editablePlan: false,
    planDialog: false,
    nutrientsCalculated: false
}

// getters
const getters = {
    plans: (state) => state.plans,
    selected: (state) => state.selected,
    currentPage: (state) => state.currentPage,
    newPlan: (state) => state.newPlan,
    editablePlan: (state) => state.editablePlan,
    planDialog: (state) => state.planDialog,
    nutrientsCalculated: (state) => state.nutrientsCalculated
}

// actions
const actions = {
    async loadPlans({ commit, getters, rootGetters }, { vm }) {
        if (!getters.plans.length || !rootGetters['projects/selectedProject'].demo) {
            const responsePlans = await vm.$api.fertilization()
                .getFromProject(rootGetters['projects/selectedProject']._id)
            commit('setPlans', responsePlans.body.data)
            return responsePlans.body.data
        }
        return getters.plans
    },
    async loadById({ commit, rootGetters }, { vm, planId }) {
        const response = await vm.$api.fertilization().getById(planId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', response.body.data)
        return response.body.data
    },
    async create({commit}, {vm, planToSave}) {
        const response = await vm.$api.fertilization().create(planToSave)
        commit('addPlan', response.body.data)
        return response.body.data
    },
    async update({ commit, rootGetters }, { vm, planToSave }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const updateResponse = await vm.$api.fertilization()
                .update(planToSave._id, rootGetters['projects/selectedProject']._id, planToSave)
            commit('updatePlan', updateResponse.body.data)
            return updateResponse.body.data
        }
        const planUpdated = {
            ...planToSave
        }
        commit('updatePlan', planUpdated)
        return planUpdated
    },
    async delete({ commit, rootGetters }, { vm, plan }) {
        if (!rootGetters['projects/selectedProject'].demo) {
            const deleteResponse = await vm.$api.fertilization()
                .delete(plan._id, rootGetters['projects/selectedProject']._id)
            commit('deletePlan', plan)
            return deleteResponse.body.message
        }
        commit('deletePlan', plan)
    }
}

// mutations
const mutations = {
    addPlan(state, plan) {
        state.plans.unshift(plan)
    },
    updatePlan(state, planToSave) {
        state.plans = state.plans.map(item => item._id == planToSave._id ? planToSave : item)
    },
    deletePlan(state, plan) {
        const planIndex = state.plans.findIndex(item => item._id == plan._id)
        state.plans.splice(planIndex, 1)
    },
    setPlans(state, plans) {
        state.plans = plans
    },
    setSelected(state, plan) {
        state.selected = plan
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage
    },
    setNewPlan(state, value) {
        state.newPlan = value
    },
    setEditablePlan(state, value) {
        state.editablePlan = value
    },
    setPlanDialog(state, value) {
        state.planDialog = value
    },
    setNutrientsCalculated(state, value) {
        state.nutrientsCalculated = value
    },
    clearPlan(state) {
        state.selected = {}
        state.selectedSample = {}
        state.newPlan = false
        state.editablePlan = false
        state.planDialog = false
    },
    clearData(state) {
        state.plans = []
        state.selected = {}
        state.newPlan = false
        state.editablePlan = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
