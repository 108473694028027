const state = {
    selected: {},
    innerTitle: false,
    innerIcon: undefined,
    selectedSection: {},
    isOpen: false,
    sections: [
        {
            icon: 'aicon-imagenes',
            name: 'ProjectImages',
            text: 'images',
            info: 'menuInfo.images'
        },
        {
            icon: 'aicon-mapas-guardados',
            name: 'ProjectStages',
            text: 'stages',
            info: 'menuInfo.stages',
            offline: true
        },
        {
            icon: 'aicon-notas',
            name: 'ProjectNotes',
            text: 'notes',
            info: 'menuInfo.notes',
            offline: true
        },
        {
            icon: 'aicon-zoom-detallado',
            name: 'ProjectGrid',
            text: 'grid',
            info: 'menuInfo.grid'
        },
        {
            icon: 'aicon-planes-vuelo',
            name: 'ProjectFlights',
            text: 'planner',
            info: 'menuInfo.planner',
            offline: true
        },
        {
            icon: 'aicon-importar-mapas',
            name: 'ProjectImports',
            text: 'import',
            info: 'menuInfo.importMaps'
        },
        {
            icon: 'aicon-clima',
            name: 'ProjectWeather',
            text: 'weather',
            info: 'menuInfo.weather'
        },
        {
            icon: 'aicon-estacion-meteorologica',
            name: 'ProjectStations',
            text: 'stations',
            info: 'menuInfo.stations'
        },
        {
            icon: 'aicon-lab-movil',
            name: 'ProjectLaboratoryAnalyses',
            text: 'laboratory',
            info: 'menuInfo.laboratory'
        },
        {
            icon: 'aicon-plan-fertilizacion',
            name: 'ProjectFertilizationPlanList',
            text: 'fertilization',
            info: 'menuInfo.fertilization'
        },
        {
            icon: 'aicon-prescripcion-variable',
            name: 'ProjectPrescriptions',
            text: 'prescriptions',
            info: 'menuInfo.prescriptions'
        },
        {
            icon: 'aicon-muestra-fertiriego',
            name: 'ProjectIrrigation',
            text: 'irrigation',
            info: 'menuInfo.irrigation'
        },
        {
            icon: 'aicon-actividades',
            name: 'ProjectActivities',
            text: 'activities',
            info: 'menuInfo.activities',
            offline: true
        },
        {
            icon: 'aicon-monitoreo',
            name: 'ProjectMonitoring',
            text: 'monitoring',
            info: 'menuInfo.monitoring',
            offline: true
        },
        {
            icon: 'aicon-inteligencia-imagenes',
            name: 'ProjectYieldEstimation',
            text: 'yieldEstimation',
            info: 'menuInfo.yieldEstimation'
        },
        {
            icon: 'aicon-inteligencia-imagenes',
            name: 'ProjectImageAnalysis',
            text: 'analysis',
            info: 'menuInfo.analysis'
        }
    ],
    createTitle: null,
    dialogCreate: false,
    innerData: null,
    innerActions: null
}

// getters
const getters = {
    getSelected: (state) => state.selected,
    getInnerTitle: (state) => state.innerTitle,
    getMenu: (state) => state.menu,
    getIsOpen: (state) => state.isOpen,
    createTitle: (state) => state.createTitle,
    dialogCreate: (state) => state.dialogCreate,
    innerData: (state) => state.innerData,
    innerActions: (state) => state.innerActions
}

// actions
const actions = {}

// mutations
const mutations = {
    changeSelected(state, selected) {
        state.selected = selected
        amplitude.getInstance().logEvent('MENU_CHANGE', { name: selected.name })
    },
    changeInnerSection(state, section) {
        state.innerTitle = section.title
        state.innerIcon = section.icon
        state.innerData = section.data
        state.innerActions = section.actions
    },
    clearInnerSection(state) {
        state.innerTitle = false
        state.innerIcon = undefined
        state.isOpen = false
        state.innerData = null,
        state.innerActions = null
    },
    clearSelected(state) {
        state.selected = {}
    },
    setIsOpen(state, value) {
        state.isOpen = value
    },
    setCreateTitle(state, title) {
        state.createTitle = title
    },
    setDialogCreate(state, value) {
        state.dialogCreate = value
    },
    setInnerData(state, value) {
        state.innerData = value
    },
    setInnerTitle(state, value) {
        state.innerTitle = value
    },
    setInnerIcon(state, value) {
        state.innerIcon = value
    },
    setInnerActions(state, value) {
        state.innerActions = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
