import en from './en.json'
import es from './es.json'

function checkTranslationKeys(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
        console.log('[Translate error] section with different keys:', keys1, keys2)
        return false
    }
    if(!keys1.every(key => keys2.includes(key))) {
        console.log('[Translate error] section with missing key:', keys1, keys2)
        return false
    }
    if(!keys2.every(key => keys1.includes(key))) {
        console.log('[Translate error] section with missing key:', keys1, keys2)
        return false
    }
    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if (areObjects && !checkTranslationKeys(val1, val2)) {
            return false
        }
    }
    return true
}

function isObject(object) {
    return object != null && typeof object === 'object'
}

if(process.env.NODE_ENV == 'development') {
    checkTranslationKeys(es, en)
}

const messages = Object.assign({
    en: en,
    es: es
})

export { messages }