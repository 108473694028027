const state = {
    selectedPlan: {},
    selectedHectares: 0
}

// getters
const getters = {
    selectedPlan: (state) => state.selectedPlan,
    selectedHectares: (state) => state.selectedHectares
}

// actions
const actions = {}

// mutations
const mutations = {
    changeSelectedPlan(state, selected) {
        state.selectedPlan = selected
    },
    changeSelectedHectares(state, value) {
        state.selectedHectares = value
    },
    clearData(state) {
        state.selectedHectares = 0
        state.selectedPlan = {}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
