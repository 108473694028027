<template>
    <v-dialog v-model="showDialog" max-width="400" persistent class="dialog-form">
        <v-card>
            <form>
                <v-card-title class="title" primary-title>{{ title }}</v-card-title>
                <v-card-text>
                    <div v-if="subject" class="dialog-form__option">
                        <label>{{ $t('profile.subject') }}</label>
                        <v-text-field
                            v-model="subject"
                            :label="$t('common.select')"
                            solo
                            dense
                            outlined
                            hide-details
                            readonly
                        />
                    </div>
                    <div v-else class="dialog-form__option">
                        <label>{{ $t('profile.selectSubject') }}</label>
                        <v-select
                            v-model="formData.subject"
                            solo
                            attach
                            dense
                            outlined
                            :items="subjectOptions"
                            append-icon="aicon-arrow-down"
                            :placeholder="$t('common.select')"
                            hide-details
                            menu-props="offset-y"
                            item-text="name"
                        >
                            <template #selection="{ item }">
                                {{ item }}
                            </template>
                            <template #item="{ item }">
                                {{ item }}
                            </template>
                        </v-select>
                    </div>
                    <div class="dialog-form__option">
                        <label>{{ $t('profile.writeMessage') }}</label>
                        <v-textarea
                            v-model="formData.message"
                            class="dialog-form__option--textarea"
                            rows="7"
                            auto-grow
                            dense
                            outlined
                            hide-details
                        />
                    </div>
                </v-card-text>
                <v-card-actions v-if="cancelText || confirmText">
                    <v-spacer />
                    <v-btn rounded color="greyDark" text @click="cancelClick">{{ cancelText }}</v-btn>
                    <v-btn rounded outlined color="primary" :disabled="!sendDisabled" @click="sendMessage">
                        {{ confirmText }}
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>
import { Device } from '@capacitor/device'
import { mapState } from 'vuex'
export default {
    name: 'DialogContact',
    props: {
        active: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        subject: {
            type: String,
            required: false,
            default: ''
        },
        confirmText: {
            type: String,
            required: false,
            default: 'Ok'
        },
        cancelText: {
            type: String,
            required: false,
            default: 'Cancel'
        }
    },
    data() {
        return {
            formData: {},
            subjectOptions: Object.values(this.$t('profile.subjects'))
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user.data
        }),
        showDialog: {
            get() {
                return this.active
            },
            set(newValue) {
                this.$emit('update:active', newValue)
            }
        },
        sendDisabled() {
            return (this.subject || this.formData.subject) && this.formData.message
        }
    },
    async mounted() {
        const info = await Device.getInfo()
        if (info.platform == 'ios' || info.operatingSystem == 'ios') {
            this.subjectOptions = this.subjectOptions.filter(
                (item) => item !== this.$t('profile.subjects.plan') && item !== this.$t('profile.subjects.hectares')
            )
        }
    },
    methods: {
        cancelClick() {
            this.formData = {}
            this.showDialog = false
            this.$emit('cancel')
        },
        sendMessage() {
            const formData = Object.assign({}, this.formData)
            formData.email = this.user.email
            formData.name = this.user.firstName + ' ' + this.user.lastName
            if (this.subject) formData.subject = this.subject
            this.$store
                .dispatch('user/sendMessage', { vm: this, formData })
                .then(() => {
                    this.$toastr.success(this.$t('profile.sendedMessage'))
                    this.editPrescription = false
                    this.formData = {}
                })
                .catch((err) => {
                    this.$toastr.error(err?.body?.message ?? err.toString())
                })
            this.showDialog = false
            this.$emit('confirm')
        }
    }
}
</script>

<style lang="scss">
.dialog-form {
    &__option {
        padding-bottom: 1.25em;

        label {
            display: block;
            margin-bottom: 0.3em;
        }

        &--textarea {
            textarea {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}
</style>
