import { ulid } from 'ulid'

const state = {
    selected: {},
    notesLayer: {},
    notes: [],
    visibility: true,
    type: 'notes',
    currentNote: undefined
}

// getters
const getters = {
    selected: (state) => state.selected,
    notes: (state) => state.notes,
    projectNotes: (state, _, rootState) => state.notes
        .filter((note) => note.projectId === rootState.projects.selectedProject._id),
    notesLayer: (state) => state.notesLayer,
    visibility: (state) => state.visibility,
    getCurrentNote: (state) => state.currentNote
}

// actions
const actions = {
    async loadNotes({ commit, getters, rootGetters }, { vm }) {
        if (rootGetters['offline/active']) {
            return getters.projectNotes
        }
        const responseNotes = await vm.$api.note()
            .getFromProject(rootGetters['projects/selectedProject']._id)
        commit('setProjectNotes', responseNotes.body.data)
    },
    async loadNoteById({ commit, getters, rootGetters }, { vm, noteId }) {
        if (rootGetters['offline/active']) {
            const note = getters.notes.find((item) => item._id === noteId)
            commit('setSelected', note)
            return note
        }
        const responseNotes = await vm.$api.note()
            .getById(noteId, rootGetters['projects/selectedProject']._id)
        commit('setSelected', responseNotes.body.data)
        return responseNotes.body.data
    },
    async create({ commit, rootGetters }, { vm, note }) {
        if (rootGetters['offline/active']) {
            const noteId = ulid()
            const geoJson = JSON.parse(note.geoJson)
            geoJson.properties._id = noteId
            const newNote = {
                ...note,
                _id: noteId,
                geoJson: JSON.stringify(geoJson),
                new: true,
                update: true
            }
            commit('addNote', newNote)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return newNote
        }

        const createResponse = await vm.$api.note().create(note)
        commit('addNote', createResponse.body.data)
        const type = createResponse.body.data.typeNote?.description
        amplitude.getInstance().logEvent('NOTE_CREATE', { type })
        return createResponse.body.data
    },
    async update({ commit, rootGetters }, { vm, note }) {
        if (rootGetters['offline/active']) {
            const updatedNote = { ...note, update: true }
            commit('updateNote', updatedNote)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return updatedNote
        }

        const updateResponse = await vm.$api.note().update(note._id, rootGetters['projects/selectedProject']._id, note)
        commit('updateNote', updateResponse.body.data)
        const type = updateResponse.body.data.typeNote?.description
        amplitude.getInstance().logEvent('NOTE_UPDATE', { type })
        return updateResponse.body.data
    },
    async delete({ commit, rootGetters }, { vm, note }) {
        if (rootGetters['offline/active']) {
            commit('deleteNote', note)
            commit('offline/addProjectToSync', rootGetters['projects/selectedProject'], { root: true })
            return note
        }

        const deleteResponse = await vm.$api.note().delete(note._id, rootGetters['projects/selectedProject']._id)
        commit('deleteNote', deleteResponse.body.data)
        const type = deleteResponse.body.data.typeNote?.description
        amplitude.getInstance().logEvent('NOTE_DELETE', { type })
        return deleteResponse.body.data
    }
}

// mutations
const mutations = {
    setSelected(state, note) {
        state.selected = note
    },
    setProjectNotes(state, notes) {
        const projectId = notes.length > 0 ? notes[0].projectId : ''
        state.notes = notes.concat(state.notes.filter((note) => note.projectId !== projectId))
    },
    setVisibility(state, status) {
        state.visibility = status
    },
    addNote(state, note) {
        state.notes.unshift(note)
    },
    updateNote(state, note) {
        state.notes = state.notes.map((item) => (item._id == note._id ? note : item))
    },
    deleteNote(state, note) {
        const noteIndex = state.notes.findIndex((item) => item._id === note._id)
        state.notes.splice(noteIndex, 1)
    },
    setNotes(state, notes) {
        state.notes = notes
    },
    setNotesLayer(state, notesLayer) {
        state.notesLayer = notesLayer
    },
    setCurrentNote(state, currentNote) {
        state.currentNote = currentNote
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.notes = []
        }
        state.selected = {}
        state.visibility = true
        state.currentNote = undefined
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
