const state = {
    projects: [],
    selectedProject: {},
    teams: [],
    selectedTeam: {},
    folders: [],
    selectedFolder: {},
    users: [],
    selectedUser: {},
    showingProjects: [],
    processes: [],
    pendingProcess: null,
    createProject: false,
    saveStatus: false
}

// getters
const getters = {
    projects: (state) => state.projects,
    selectedProject: (state) => state.selectedProject,
    selectedProjectCrops: (state, _, rootState) => {
        const subProjectsCropsSet = rootState.subProjects.availables
            .filter(({ projectId, info }) => projectId == state.selectedProject._id && info?.crop)
            .reduce((crops, { info }) => crops.add(info.crop), new Set())
        const subProjectsCrops  = [...subProjectsCropsSet].map((crop) => ({ crop }))
        return subProjectsCrops.length ? subProjectsCrops : (state.selectedProject.crops || [])
    },
    teams: (state) => state.teams,
    selectedTeam: (state) => state.selectedTeam,
    folders: (state) => state.folders,
    selectedFolder: (state) => state.selectedFolder,
    users: (state) => state.users,
    selectedUser: (state) => state.selectedUser,
    showingProjects: (state) => state.showingProjects,
    processes: (state, _, rootState) => state.processes
        .filter((process) => process.projectId == rootState.projects.selectedProject._id),
    pendingProcess: (state, getters) => state.pendingProcess ||
        getters.processes.find(process => process.status.match(/(pending|running)/) &&
            (!process.type || process.type == 'processSatelliteImages')),
    createProject: (state) => state.createProject,
    saveStatus: (state) => state.saveStatus
}

// actions
const actions = {
    async loadData({ commit, getters, rootGetters }, { vm }) {
        let { teams, folders, projects, users } = getters

        if (!rootGetters['offline/active']) {
            const teamsPromise = vm.$api.team().getAllMember()
            const projectsPromise = vm.$api.project().getAll()
            const foldersPromise = vm.$api.folder().getProject()
            const adminPromise = rootGetters['user/data']?.role.match(/(admin|superAdmin)/)
                ? vm.$api.admin().getUsers()
                : Promise.resolve({ body: { data: [] } })

            const [teamsResponse, projectsResponse, foldersResponse, adminResponse] = await Promise.all([
                teamsPromise, projectsPromise, foldersPromise, adminPromise ])

            projects = projectsResponse.body.data
            teams = teamsResponse.body.data
            commit('setTeams', teams)

            users = adminResponse.body.data
                .filter((user) => user._id !== rootGetters['user/data']._id)
                .map((user) => ({
                    _id: user._id,
                    name: `${user.firstName} ${user.lastName}`,
                    group: user.adminId
                        .split('-')
                        .map((text) => `${text[0].toUpperCase()}${text.slice(1)}`)
                        .join(' '),
                    projects: user.projects
                }))
            commit('setUsers', users)

            // Muevo los proyectos a sus carpetas correspondientes
            folders = foldersResponse.body.data.map((folder) => {
                folder.projects = projects.filter((project) => project.folder == folder._id)
                return folder
            })
            // Muevo los proyectos compartidos a una carpeta
            if (rootGetters['user/data']?.sharedProjects?.length) {
                folders.push({
                    _id: 'shared',
                    name: vm.$t('projects.sharedProjects'),
                    projects: []
                })
            }
            if (rootGetters['user/data']?.configProfile?.demos) {
                folders.push({
                    _id: 'demos',
                    name: 'Demos',
                    projects: []
                })
            }
            commit('setFolders', folders)
            // Filtro los proyectos que son propios y no estan en carpetas
            projects = projects.filter((project) => !project.folder &&
                project.userId == rootGetters['user/data']._id)
            commit('setProjects', projects)
        }

        // Muestro carpetas, proyectos de equipo y proyectos propios
        if (getters.selectedFolder._id) {
            commit(
                'setSelectedFolder',
                getters.folders.find((folder) => folder._id == getters.selectedFolder._id)
            )
        } else if (getters.selectedTeam._id) {
            commit(
                'setSelectedTeam',
                getters.teams.find((team) => team._id == state.selectedFolder._id)
            )
        } else if (getters.selectedUser._id) {
            commit(
                'setSelectedUser',
                getters.users.find((user) => user._id == state.selectedUser._id)
            )
        } else {
            commit(
                'setShowingProjects',
                getters.folders.concat(getters.teams).concat(getters.users).concat(getters.projects)
            )
        }
    },
    async loadProcesses({ commit }, { vm }) {
        const response = await vm.$api.project().getProcesses()
        const processes = response.body.data
        commit('setProcesses', processes)
        commit('setPendingProcess', null)
        return processes
    },
    async checkPendingProcess({ commit, getters }, { vm }) {
        const response = await vm.$api.project().getProcess(getters.pendingProcess._id)
        commit('setPendingProcess', response.body.data || {
            _id: getters.pendingProcess._id,
            progress: 0
        })
        return response.body.data
    },
    async getShared({ commit }, { vm }) {
        const response = await vm.$api.project().getShared()
        const projectShared = response.body.data
        commit('setProjectShared', projectShared)
        return projectShared
    },
    async loadTeam({ commit }, { vm, team }) {
        const teamResponse = await vm.$api.project()
            .getByTeam(team._id, team.userId)
        commit('updateTeamProjects', { _id: team._id, projects: teamResponse.body.data })
        return teamResponse.body.message
    },
    async loadDemos({ commit }, { vm }) {
        const demos = vm.folders.find(folder => folder._id == 'demos')
        if (demos.projects?.length) {
            commit('setProjectDemos', { projects: demos.projects })
        } else{
            const demosResponse = await vm.$api.pack().getDemos()
            commit('setProjectDemos', { projects: demosResponse.body.data })
        }
    },
    async loadUser({ commit }, { vm, user }) {
        const userResponse = await vm.$api.admin().getProjectsByUser(user._id)
        commit('updateUserProjects', { _id: user._id, projects: userResponse.body.data })
        return userResponse.body.message
    },
    async loadProject({ commit, getters, rootGetters }, { vm, projectId, userId }) {
        if (rootGetters['offline/active']) {
            const project = getters.projects.find(el => el._id == projectId) ||
                getters.folders.find(folder => folder.projects?.find(el => el._id == projectId)) ||
                getters.teams.find(team => team.projects?.find(el => el._id == projectId))
            commit('setSelectedProject', project)
            return project
        }

        const response = await vm.$api.project().get(projectId, userId)
        const project = response.body.data
        commit('setSelectedProject', project)

        // Update project in offline data
        const projects = getters.projects.map((item) => (item._id === project._id ? project : item))
        commit('setProjects', projects)
        const folders = getters.folders.map((folder) => {
            folder.projects = folder.projects?.map((item) => (item._id === project._id ? project : item))
            return folder
        })
        commit('setFolders', folders)
        const teams = getters.teams.map((team) => {
            team.projects = team.projects?.map((item) => (item._id === project._id ? project : item))
            return team
        })
        commit('setTeams', teams)
        return project
    },
    async createProject({ commit }, { vm, project, folderId }) {
        const createResponse = await vm.$api.project().create(project)
        let createdProject = createResponse.body.data

        if (folderId) {
            const moveResponse = await vm.$api.project()
                .addFolder(createdProject._id, folderId)
            createdProject = moveResponse.body.data
        }

        if (createResponse.body.queue) {
            const pendingProcess = { _id: createResponse.body.queue, progress: 0 }
            commit('setPendingProcess', pendingProcess)
        }

        commit('addProject', createdProject)
        amplitude.getInstance().logEvent('PROJECT_CREATE', {
            area: project.area,
            bbox: project.bbox
        })
        return createResponse.body.message
    },
    async updateProject({ commit }, { vm, project }) {
        const updateResponse = await vm.$api.project().update(project._id, project)
        commit('updateProject', updateResponse.body.data)
        amplitude.getInstance().logEvent('PROJECT_UPDATE')
        return updateResponse.body.message
    },
    async deleteProject({ commit }, { vm, project }) {
        const deleteResponse = await vm.$api.project().delete(project._id)
        commit('deleteProject', deleteResponse.body.data)
        amplitude.getInstance().logEvent('PROJECT_DELETE')
        return deleteResponse.body.message
    },
    async moveProject({ commit }, { vm, project, folderId }) {
        const moveResponse = folderId
            ? await vm.$api.project().addFolder(project._id, folderId)
            : await vm.$api.project().removeFolder(project._id)
        commit('updateProject', moveResponse.body.data)
        return moveResponse.body.message
    },
    async enableProject({ commit }, { vm, project }) {
        const enableResponse = await vm.$api.project().enable(project._id)
        commit('updateProject', enableResponse.body.data)
        return enableResponse.body
    },
    async createFolder({ commit }, { vm, folder }) {
        const createResponse = await vm.$api.folder().create(folder)
        commit('addFolder', createResponse.body.data)
        return createResponse.body.message
    },
    async updateFolder({ commit, rootGetters }, { vm, folder }) {
        const updateResponse = await vm.$api.folder()
            .update(folder._id, rootGetters['user/data']._id, folder)
        updateResponse.body.data.projects = folder.projects
        commit('updateFolder', updateResponse.body.data)
        return updateResponse.body.message
    },
    async deleteFolder({ commit, rootGetters }, { vm, folder }) {
        const deleteResponse = await vm.$api.folder()
            .delete(folder._id, rootGetters['user/data']._id)
        commit('deleteFolder', deleteResponse.body.data)
        return deleteResponse.body.message
    },
    async assignStationToProject({ commit, rootGetters }, { vm, data }) {
        const updatedProjects = await vm.$api.admin().assignStationToProject(rootGetters['user/data']._id, data)
        updatedProjects.body.data.forEach(project => {
            commit('updateProject', project)
        })
        amplitude.getInstance().logEvent('PROJECT_UPDATE')
        return updatedProjects
    }
}

// mutations
const mutations = {
    setProjects(state, projects) {
        state.projects = projects
    },
    addProject(state, newProject) {
        if (newProject.folder) {
            state.folders = state.folders.map((folder) => {
                if (folder._id == newProject.folder) {
                    if (folder.projects) folder.projects.unshift(newProject)
                    else folder.projects = [newProject]
                }
                return folder
            })
        } else {
            state.projects.unshift(newProject)
        }

        if (state.selectedFolder._id) {
            state.selectedFolder = state.folders.find((folder) => folder._id == state.selectedFolder._id)
            state.showingProjects = state.selectedFolder.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    updateProject(state, editedProject) {
        const folderIndex = state.folders.findIndex((folder) => folder._id == editedProject.folder)
        const currentFolderIndex = state.folders.findIndex((folder) =>
            folder.projects?.some((project) => project._id == editedProject._id)
        )

        if (folderIndex != -1 && currentFolderIndex != -1 && folderIndex == currentFolderIndex) {
            // Si esta dentro de la carpeta
            state.folders[folderIndex].projects = state.folders[folderIndex].projects.map((project) =>
                project._id === editedProject._id ? editedProject : project
            )
        } else if (folderIndex != -1 && currentFolderIndex != -1) {
            // Si esta en otra carpeta
            const projectIndex = state.folders[currentFolderIndex].projects.findIndex(
                (el) => el._id == editedProject._id
            )
            if (projectIndex != -1) state.folders[currentFolderIndex].projects.splice(projectIndex, 1)
            if (state.folders[folderIndex].projects) state.folders[folderIndex].projects.unshift(editedProject)
            else state.folders[folderIndex].projects = [editedProject]
        } else if (folderIndex != -1) {
            // Si no esta en la carpeta pero debería estarlo
            if (state.folders[folderIndex].projects) state.folders[folderIndex].projects.unshift(editedProject)
            else state.folders[folderIndex].projects = [editedProject]
            const projectIndex = state.projects.findIndex((el) => el._id == editedProject._id)
            if (projectIndex != -1) state.projects.splice(projectIndex, 1)
        } else if (currentFolderIndex != -1) {
            // Si esta en una carpeta pero no debería estar
            const projectIndex = state.folders[currentFolderIndex].projects.findIndex(
                (el) => el._id == editedProject._id
            )
            if (projectIndex != -1) state.folders[currentFolderIndex].projects.splice(projectIndex, 1)
            state.projects.unshift(editedProject)
        } else {
            // Si no esta en ninguna carpeta
            state.projects = state.projects.map((item) => (item._id == editedProject._id ? editedProject : item))
        }

        if (state.selectedFolder._id) {
            state.selectedFolder = state.folders.find((folder) => folder._id == state.selectedFolder._id)
            state.showingProjects = state.selectedFolder.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    deleteProject(state, deletedProject) {
        state.folders = state.folders.map((item) => {
            const projectIndex = item.projects.findIndex((el) => el._id == deletedProject._id)
            if (projectIndex != -1) item.projects.splice(projectIndex, 1)
            return item
        })
        const projectIndex = state.projects.findIndex((el) => el._id == deletedProject._id)
        if (projectIndex != -1) state.projects.splice(projectIndex, 1)

        if (state.selectedFolder._id) {
            state.selectedFolder = state.folders.find((folder) => folder._id == state.selectedFolder._id)
            state.showingProjects = state.selectedFolder.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setSelectedProject(state, project) {
        state.selectedProject = project
    },
    setTeams(state, teams) {
        state.teams = teams
    },
    updateTeamProjects(state, updatedTeam) {
        const teamIndex = state.teams.findIndex((team) => team._id == updatedTeam._id)
        state.teams[teamIndex].projects = updatedTeam.projects
        if (state.selectedTeam._id) {
            state.selectedTeam = state.teams.find((team) => team._id == state.selectedTeam._id)
            state.showingProjects = state.selectedTeam.projects || []
        } else {
            state.showingProjects = state.teams.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setSelectedTeam(state, selectedTeam) {
        state.selectedTeam = selectedTeam
        if (state.selectedTeam._id) {
            state.showingProjects = state.selectedTeam.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setProjectShared(state, projectShared) {
        for (let index = 0; index < state.folders.length; index++) {
            if(state.folders[index]._id == 'shared'){
                state.folders[index].projects = projectShared
                state.selectedFolder = state.folders[index]
                state.showingProjects = projectShared || []
            }
        }
    },
    setProjectDemos(state, projectDemos) {
        for (let index = 0; index < state.folders.length; index++) {
            if(state.folders[index]._id == 'demos'){
                state.folders[index].projects = projectDemos.projects
                state.selectedFolder = state.folders[index]
                state.showingProjects = projectDemos.projects || []
            }
        }
    },
    setFolders(state, folders) {
        state.folders = folders
    },
    addFolder(state, newFolder) {
        newFolder.projects = []
        state.folders.unshift(newFolder)
        if (!state.selectedFolder._id && !state.selectedTeam._id) {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    updateFolder(state, editedFolder) {
        state.folders = state.folders.map((folder) => (folder._id === editedFolder._id ? editedFolder : folder))
        if (!state.selectedFolder._id && !state.selectedTeam._id) {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    deleteFolder(state, deletedFolder) {
        const folderIndex = state.folders.findIndex((el) => el._id == deletedFolder._id)
        if (folderIndex != -1) state.folders.splice(folderIndex, 1)
        state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
    },
    setSelectedFolder(state, selectedFolder) {
        state.selectedFolder = selectedFolder
        if (state.selectedFolder._id) {
            state.showingProjects = state.selectedFolder.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setUsers(state, users) {
        state.users = users
    },
    updateUserProjects(state, updatedUser) {
        const userIndex = state.users.findIndex((user) => user._id == updatedUser._id)
        state.users[userIndex].projects = updatedUser.projects.map((project) => ({
            ...project,
            group: state.users[userIndex].group
        }))
        if (state.selectedUser._id) {
            state.selectedUser = state.users.find((team) => team._id == state.selectedUser._id)
            state.showingProjects = state.selectedUser.projects || []
        } else {
            state.showingProjects = state.teams.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setSelectedUser(state, selectedUser) {
        state.selectedUser = selectedUser
        if (state.selectedUser._id) {
            state.showingProjects = state.selectedUser.projects || []
        } else {
            state.showingProjects = state.folders.concat(state.teams).concat(state.users).concat(state.projects)
        }
    },
    setShowingProjects(state, showingProjects) {
        state.showingProjects = showingProjects
    },
    setProcesses(state, processes) {
        state.processes = processes
    },
    setPendingProcess(state, pendingProcess) {
        state.pendingProcess = pendingProcess
    },
    setCreateProject(state, createProject) {
        state.createProject = createProject
    },
    setSaveStatus(state, status) {
        state.saveStatus = status
    },
    clearData(state) {
        if (!Capacitor.isNative) {
            state.projects = []
            state.teams = []
            state.folders = []
            state.users = []
        }
        state.selectedProject = {}
        state.selectedTeam = {}
        state.selectedFolder = {}
        state.selectedUser = {}
        state.showingProjects = []
        state.processes = []
        state.pendingProcess = null
        state.saveStatus = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
