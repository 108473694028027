import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import localforage from 'localforage'
import user from '@/store/modules/user'
import images from '@/store/modules/images'
import projectMenu from '@/store/modules/projectMenu'
import stages from '@/store/modules/stages'
import notes from '@/store/modules/notes'
import offline from '@/store/modules/offline'
import flights from '@/store/modules/flights'
import grids from '@/store/modules/grids'
import mainMenu from '@/store/modules/mainMenu'
import checkout from '@/store/modules/checkout'
import projects from '@/store/modules/projects'
import prescriptions from '@/store/modules/prescriptions'
import imports from '@/store/modules/imports'
import seasons from '@/store/modules/seasons'
import subProjects from '@/store/modules/subProjects'
import aside from '@/store/modules/aside'
import weather from '@/store/modules/weather'
import forms from '@/store/modules/forms'
import tasks from '@/store/modules/tasks'
import laboratory from './modules/laboratory'
import fertilization from './modules/fertilization'
import tour from '@/store/modules/tour'
import stations from '@/store/modules/stations'
import drone from '@/store/modules/drone'
import imageAnalysis from './modules/imageAnalysis'
import teams from './modules/teams'
import admin from './modules/admin'
import irrigation from './modules/irrigation'
import constants from './modules/constants'
import yieldEstimation from './modules/yieldEstimation'
import imageAnalysisCluster from './modules/imageAnalysisCluster'

Vue.use(Vuex)

const requestIdleCallback = window.requestIdleCallback || ((callback) => setTimeout(callback, 1))

// Configuracion para guardar los stores en el localStorage
const vuexLocalStorage = new VuexPersist({
    key: 'store',
    asyncStorage: true,
    storage: localforage.createInstance({
        name: 'agrodreams'
    }),
    reducer: (state) => {
        let mobileState = {}
        if (Capacitor.isNative) {
            mobileState = {
                images: {
                    active: state.images.active,
                    stageA: state.images.stageA
                },
                projects: {
                    projects: state.projects.projects,
                    selectedProject: state.projects.selectedProject,
                    teams: state.projects.teams,
                    folders: state.projects.folders,
                    users: state.projects.users,
                    showingProjects: state.projects.showingProjects
                },
                subProjects: {
                    availables: state.subProjects.availables,
                    selected: state.subProjects.selected
                },
                flights: {
                    flights: state.flights.flights,
                    flying: state.flights.flying,
                    selected: state.flights.selected
                },
                notes: {
                    notes: state.notes.notes
                },
                stages: {
                    stages: state.stages.stages,
                    folders: state.stages.folders
                },
                drone: {
                    images: state.drone.images,
                    waypoints: state.drone.waypoints,
                    droneFlightPath: state.drone.droneFlightPath,
                    dronePosition: state.drone.dronePosition,
                    homePosition: state.drone.homePosition,
                    nextWaypointIndex: state.drone.nextWaypointIndex
                },
                forms: {
                    userTemplates: state.forms.userTemplates,
                    projectTemplates: state.forms.projectTemplates,
                    answers: state.forms.answers
                },
                tasks: {
                    tasks: state.tasks.tasks,
                    monitoringForms: state.tasks.monitoringForms,
                    templates: state.tasks.templates
                },
                offline: {
                    projectToSync: state.offline.projectToSync,
                    active: state.offline.active,
                    syncing: state.offline.syncing
                }
            }
        }
        return {
            user: state.user,
            tour: {
                toursComplete: state.tour.toursComplete
            },
            laboratory: {
                conditioning: state.laboratory.conditioning,
                calibration: state.laboratory.calibration,
                conditioningTimer: state.laboratory.conditioningTimer
            },
            checkout: {
                selectedPlan: state.checkout.selectedPlan,
                selectedHectares: state.checkout.selectedHectares
            },
            seasons: {
                seasons: state.seasons.seasons,
                selected: state.seasons.selected
            },
            teams: {
                teams: state.teams.teams,
                selected: state.teams.selected
            },
            constants: {
                crops: state.constants.crops,
                lastCropUpdate: state.constants.lastCropUpdate,
                irrigation: state.constants.irrigation,
                lastIrrigationUpdate: state.constants.lastIrrigationUpdate
            },
            ...mobileState
        }
    },
    saveState: (key, state, storage) => {
        requestIdleCallback(() => {
            storage.setItem(key, state)
        })
    },
    restoreState: (key, storage) => {
        return storage.getItem(key).then((data) => {
            Vue.http.headers.common['Authorization'] = data ? data.user.token : ''
            return data
        })
    }
})

export default new Vuex.Store({
    state: {},
    getters: {},
    actions: {
        clearUserData({ commit }) {
            commit('user/clearData')
            commit('projects/clearData')
            commit('seasons/clearData')
            commit('teams/clearData')
            commit('stations/clearData')
        },
        clearProjectData({ commit }) {
            commit('projects/setSelectedProject', {})
            commit('images/clearData')
            commit('projectMenu/clearSelected')
            commit('stages/clearData')
            commit('notes/clearData')
            commit('subProjects/clearData')
            commit('flights/clearData')
            commit('grids/clearData')
            commit('imports/clearData')
            commit('checkout/clearData')
            commit('forms/clearData')
            commit('tasks/clearData')
            commit('laboratory/clearData')
            commit('fertilization/clearData')
            commit('imageAnalysis/clearData')
            commit('irrigation/clearData')
            commit('yieldEstimation/clearData')
            commit('imageAnalysisCluster/clearData')
        }
    },
    mutations: {},
    modules: {
        user,
        images,
        projectMenu,
        stages,
        notes,
        flights,
        offline,
        grids,
        mainMenu,
        checkout,
        projects,
        prescriptions,
        imports,
        seasons,
        subProjects,
        aside,
        weather,
        forms,
        tasks,
        laboratory,
        fertilization,
        tour,
        stations,
        drone,
        imageAnalysis,
        teams,
        admin,
        irrigation,
        constants,
        yieldEstimation,
        imageAnalysisCluster
    },
    plugins: [vuexLocalStorage.plugin]
})
